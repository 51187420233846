import React from "react";
import Translate from "react-translate-component";
import { BiMenu, BiX } from "react-icons/bi";

const Header: React.FC<{ utils: any; path?: string }> = ({ utils, path }) => {
    return (
        <nav className="global-navbar">
            <button className="menu-button" onClick={() => document.querySelector(".global-navbar .menu-button")?.classList.toggle("opened")}>
                <div className="closed">
                    <BiMenu />
                </div>
                <div className="opened">
                    <BiX />
                </div>
            </button>
            <img className="logo full" src={`${process.env.PUBLIC_URL}/media/svg/logo03.svg`} alt="logo" onClick={() => utils.use.Navigate("/")} />
            <img className="logo icon" src={`${process.env.PUBLIC_URL}/media/svg/logo04.svg`} alt="logo" onClick={() => utils.use.Navigate("/")} />
            <nav>
                <ul className="nav-links">
                    <li className={path === "/servers/discovery" ? "active" : "disabled"} children={<Translate content="components.header.link1" />} onClick={() => (window.location.href = "/servers/discovery")} />
                    <li className={path === "/leaderboard" ? "active" : "disabled"} children={<Translate content="components.header.link2" />} onClick={() => (window.location.href = "/leaderboard")} />
                    <li className={path === "/commands" ? "active" : ""} children={<Translate content="components.header.link3" />} onClick={() => (window.location.href = "/commands")} />
                    <li className={path === "/contact" ? "active" : ""} children={<Translate content="components.header.link4" />} onClick={() => window.open("https://discord.com/invite/j5pkCEff8P")} />
                    <li className={`premium ${path === "/premium" ? "active" : ""}`} children={<Translate content="components.header.link5" />} onClick={() => (window.location.href = "/premium")} />
                </ul>
                <ul className="nav-close" onClick={() => document.querySelector(".global-navbar .menu-button")?.classList.remove("opened")}></ul>
            </nav>
        </nav>
    );
};

export default Header;
