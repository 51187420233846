import React from "react";
import { Line, Doughnut } from "react-chartjs-2";
import Moment from "moment";
import Translate from "react-translate-component";
import { abbreviateNumber as AbbreviateNumber } from "js-abbreviation-number";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    // Stats Data
    let timestamp = Date.now();
    let daySize = 1000 * 60 * 60 * 24;
    let statLabels: any[] = [];
    let statIds: string[] = [];
    for (let i = 6; i > -1; i--) {
        const date = Moment(timestamp - daySize * i)
            .locale(utils.locale)
            .format("MMMM Do");
        statLabels.push(date);
        statIds.push(Utility.CapitalizedCase(date));
    }
    let messageActivity: number[] = [],
        membersJoined: number[] = [],
        membersLeft: number[] = [],
        commandsRan: number[] = [],
        memberCount: number[] = [];
    if (config.data.previous) {
        for (let id of statIds) {
            let data = JSON.parse(JSON.stringify(config.data.previous))
                .data.stats.week.sort((a: any, b: any) => b.timestamp - a.timestamp)
                .find((stats: any) => stats.date === id.toUpperCase())?.value;
            if (!data) data = { members: guild.approximate_member_count, messages: 0, joins: 0, leaves: 0, commands: 0 };
            messageActivity.push(data.messages);
            membersJoined.push(data.joins);
            membersLeft.push(data.leaves);
            memberCount.push(data.members);
            commandsRan.push(data.commands);
        }
    }
    return (
        <section className="dashboard-section">
            <div className="container-default">
                <h1 className="text font-1 text-center text-[40px] break-all leading-[0] mt-5">{guild.name}</h1>
            </div>
            <div className="container-default">
                <Translate content="routes.dashboard.home.stats.name" component="h1" className="text title-6 text-center" />
                <Translate content="routes.dashboard.home.stats.description" component="p" className="text text-center" />
                <div className="guild-stats-card-grid">
                    <div className="stat-card">
                        <Translate content="routes.dashboard.home.stats.members" component="h1" className="text title text-center" />
                        <h1 className="text value text-center" style={{ color: "rgb(177, 144, 255)" }}>
                            {guild.approximate_member_count}
                        </h1>
                    </div>
                    <AppComponent.Tooltip
                        delay={[500, 200]}
                        content={config.data.previous ? (config.data.previous.data.stats.total.messages + messageActivity[6]).toLocaleString() : 0}
                        children={
                            <div className="stat-card">
                                <Translate content="routes.dashboard.home.stats.messages" component="h1" className="text title text-center" />
                                <h1 className="text value text-center" style={{ color: "rgb(255, 203, 71)" }}>
                                    {config.data.previous ? AbbreviateNumber(config.data.previous.data.stats.total.messages + messageActivity[6]) : 0}
                                </h1>
                                <h1 className="text today text-center">
                                    <Translate content="words.today" /> # {messageActivity[6] ? messageActivity[6].toLocaleString() : 0}
                                </h1>
                            </div>
                        }
                    />
                    <AppComponent.Tooltip
                        delay={[500, 200]}
                        content={(config.data.previous ? (config.data.previous.data.stats.total.joins + membersJoined[6]).toLocaleString() : 0) + " / " + (config.data.previous ? (config.data.previous.data.stats.total.leaves + membersLeft[6]).toLocaleString() : 0)}
                        children={
                            <div className="stat-card">
                                <Translate content="routes.dashboard.home.stats.join_leave" component="h1" className="text title text-center" />
                                <h1 className="text value text-center">
                                    <span style={{ color: "rgb(76, 201, 240)" }}>{config.data.previous ? AbbreviateNumber(config.data.previous.data.stats.total.joins + membersJoined[6]) : 0}</span> / <span style={{ color: "rgb(247, 37, 133)" }}>{config.data.previous ? AbbreviateNumber(config.data.previous.data.stats.total.leaves + membersLeft[6]) : 0}</span>
                                </h1>
                                <h1 className="text today text-center">
                                    <Translate content="words.today" /> # {membersJoined[6] ? membersJoined[6].toLocaleString() : 0} / {membersLeft[6] ? membersLeft[6].toLocaleString() : 0}
                                </h1>
                            </div>
                        }
                    />
                    <AppComponent.Tooltip
                        delay={[500, 200]}
                        content={config.data.previous ? (config.data.previous.data.stats.total.commands + commandsRan[6]).toLocaleString() : 0}
                        children={
                            <div className="stat-card">
                                <Translate content="routes.dashboard.home.stats.commands" component="h1" className="text title text-center" />
                                <h1 className="text value text-center" style={{ color: "rgb(220, 50, 100)" }}>
                                    {config.data.previous ? AbbreviateNumber(config.data.previous.data.stats.total.commands + commandsRan[6]) : 0}
                                </h1>
                                <h1 className="text today text-center">
                                    <Translate content="words.today" /> # {commandsRan[6] ? commandsRan[6].toLocaleString() : 0}
                                </h1>
                            </div>
                        }
                    />
                </div>
                <div className="guild-stats-graph-card-grid">
                    <div className="stat-card grid-col-span-2">
                        <Translate content="routes.dashboard.home.stats.message_activity" component="h1" className="text title" />
                        <div className="graph">
                            <AppComponent.Chart
                                chart={Line}
                                data={{
                                    labels: statLabels,
                                    datasets: [
                                        {
                                            label: utils.translation.routes.dashboard.home.stats.messages_sent,
                                            data: messageActivity,
                                            fill: false,
                                            borderColor: "rgba(255, 186, 8, 1)",
                                            tension: 0.3,
                                            pointRadius: 5,
                                            pointHoverRadius: 20,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                    <div className="stat-card">
                        <Translate content="routes.dashboard.home.stats.join_leave" component="h1" className="text title" />
                        <div className="graph">
                            <AppComponent.Chart
                                chart={Doughnut}
                                data={{
                                    labels: statLabels,
                                    datasets: [
                                        {
                                            label: utils.translation.routes.dashboard.home.stats.joins,
                                            data: membersJoined,
                                            backgroundColor: ["rgba(76, 201, 240, 0.6)", "rgba(72, 149, 239, 0.6)"],
                                            borderColor: ["rgba(76, 201, 240, 1)", "rgba(72, 149, 239, 1)"],
                                            borderWidth: 2,
                                            cutout: 80,
                                            borderRadius: 5,
                                            offset: 15,
                                            hoverOffset: 5,
                                            circumference: 180,
                                            rotation: 240,
                                        },
                                        {
                                            label: utils.translation.routes.dashboard.home.stats.leaves,
                                            data: membersLeft,
                                            backgroundColor: ["rgba(247, 37, 133, 0.6)", "rgba(181, 23, 158, 0.6)"],
                                            borderColor: ["rgba(247, 37, 133, 1)", "rgba(181, 23, 158, 1)"],
                                            borderWidth: 2,
                                            cutout: 80,
                                            borderRadius: 5,
                                            offset: 15,
                                            hoverOffset: 5,
                                            circumference: 180,
                                            rotation: 60,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                    <div className="stat-card">
                        <Translate content="routes.dashboard.home.stats.commands" component="h1" className="text title" />
                        <div className="graph">
                            <AppComponent.Chart
                                chart={Doughnut}
                                data={{
                                    labels: statLabels,
                                    datasets: [
                                        {
                                            label: utils.translation.routes.dashboard.home.stats.commands,
                                            data: commandsRan,
                                            backgroundColor: ["rgba(177, 144, 255, 0.6)", "rgba(178, 247, 239, 0.6)", "rgba(177, 144, 255, 0.6)", "rgba(178, 247, 239, 0.6)", "rgba(177, 144, 255, 0.6)", "rgba(178, 247, 239, 0.6)", "rgba(255, 186, 8, 0.6)"],
                                            borderColor: ["rgba(177, 144, 255, 1)", "rgba(178, 247, 239, 1)", "rgba(177, 144, 255, 1)", "rgba(178, 247, 239, 1)", " rgba(177, 144, 255, 1)", "rgba(178, 247, 239, 1)", "rgba(255, 186, 8, 1)"],
                                            borderWidth: 2,
                                            borderRadius: 5,
                                            offset: 15,
                                            hoverOffset: 5,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                    <div className="stat-card grid-col-span-2">
                        <Translate content="routes.dashboard.home.stats.member_count" component="h1" className="text title" />
                        <div className="graph">
                            <AppComponent.Chart
                                chart={Line}
                                data={{
                                    labels: statLabels,
                                    datasets: [
                                        {
                                            label: utils.translation.routes.dashboard.home.stats.members,
                                            data: memberCount,
                                            fill: true,
                                            borderColor: "rgb(177, 144, 255)",
                                            tension: 0.3,
                                            pointRadius: 5,
                                            pointHoverRadius: 20,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto" style={{ marginBottom: "5rem" }}>
                <Translate content="routes.dashboard.home.modules.name" component="h1" className="text title-6 text-center" />
                <Translate content="routes.dashboard.home.modules.description" component="p" className="text text-center" />
                <Translate content="routes.dashboard.sidebar.management" component="h1" className="text title-7" />
                <div className="module-list-grid">
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-moderation", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.moderation.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.moderation.description" component="p" className="text" />
                        </div>
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-automod", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.automod.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.automod.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.autoMod.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.autoMod.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-logging", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.logging.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.logging.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.logging.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.logging.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                </div>
                <Translate content="routes.dashboard.sidebar.entertainment" component="h1" className="text title-7" />
                <div className="module-list-grid">
                    {/* <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-chatbot", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.chatbot.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.chatbot.description" component="p" className="text" />
                        </div>
                        <AppComponent.FormInput
                                        type="toggle-switch"
                                        value={config.data.current.modules.chatBot.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.chatBot.active", data: value.target.checked })} 
                                        additionalProps={{ toggleColor: "background-dark-03" }}
                                        utils={utils}
                                    />
                    </div> */}
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-counting", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.counting.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.counting.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.counting.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.counting.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-cyclicquestions", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.cyclicquestions.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.cyclicquestions.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.cyclicQuestions.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                </div>
                <Translate content="routes.dashboard.sidebar.utility" component="h1" className="text title-7" />
                <div className="module-list-grid">
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-greeter", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.greeter.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.greeter.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.greeter.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.greeter.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-selfrole", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.selfrole.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.selfrole.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.selfRole.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.selfRole.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-leveling", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.leveling.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.leveling.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.leveling.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.leveling.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-autorole", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.autorole.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.autorole.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.autoRole.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.autoRole.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                    <div className="module">
                        <div className="details" onClick={() => extra.changeSection("dashboard-autopublish", utils.use.SearchParams[1])}>
                            <Translate content="routes.dashboard.autopublish.name" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.autopublish.description" component="p" className="text" />
                        </div>
                        <div className="switch" children={<AppComponent.FormInput type="toggle-switch" value={config.data.current.modules.autoPublish.active} onChange={(value) => config.dispatch({ type: "update", path: "modules.autoPublish.active", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03" }} utils={utils} />} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section;
