import React from "react";
import Select, { StylesConfig } from "react-select";
import Utility from "@utility@::@index";

const DiscordComponentPreviewer: React.FC<{ component: any; utils: any; onClick?: any }> = ({ component, utils, onClick }) => {
    const styles = ["primary", "secondary", "success", "danger", "link"];
    return (
        <>
            {component.type === 2 && (
                <div className={`discord-button-component ${styles[component.style - 1]} ${component.forColor ? "for-color" : ""}`} onClick={onClick}>
                    <div className="contents">
                        {component.emoji && <span dangerouslySetInnerHTML={{ __html: `<em-emoji set="twitter" class="emoji" id="${component.emoji.id || "0"}"></em-emoji>` }} />}
                        {component.label && <div className="label">{component.label}</div>}
                        {component.style === 5 && (
                            <svg className="link-icon" aria-hidden="true" role="img" width="16" height="16" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M10 5V3H5.375C4.06519 3 3 4.06519 3 5.375V18.625C3 19.936 4.06519 21 5.375 21H18.625C19.936 21 21 19.936 21 18.625V14H19V19H5V5H10Z"></path>
                                <path fill="currentColor" d="M21 2.99902H14V4.99902H17.586L9.29297 13.292L10.707 14.706L19 6.41302V9.99902H21V2.99902Z"></path>
                            </svg>
                        )}
                    </div>
                </div>
            )}
            {component.type === 3 && (
                <div className={`discord-select-menu-component w-full`}>
                    <Select
                        {...Utility.SelectMenuProps}
                        className="select-menu"
                        placeholder={component.placeholder}
                        closeMenuOnSelect
                        value={[]}
                        options={component.options.map((option: any, index: any) => ({
                            label: (
                                <div className="component-option" key={index}>
                                    <div className="option flex items-center relative">
                                        {option.emoji && <span dangerouslySetInnerHTML={{ __html: `<em-emoji set="twitter" class="emoji absolute text-[1.375em] top-[-40%]" id="${option.emoji.id || "0"}"></em-emoji>` }} />}
                                        <div className={`label-container ${option.emoji ? "ml-[30px]" : ""}`}>
                                            <strong className="label">{option.label}</strong>
                                        </div>
                                    </div>
                                </div>
                            ),
                            value: index,
                        }))}
                        isSearchable={false}
                        styles={DiscordSelectMenuStyle()}
                        onChange={(value: any) => {}}
                    />
                </div>
            )}
        </>
    );
};

const DiscordSelectMenuStyle = (data?: { menu?: any; option?: any; control?: any; singleValue?: any; multiValue?: any; multiValueLabel?: any; multiValueRemove?: any; input?: any }): StylesConfig => {
    const selectData: StylesConfig = {
        menu: (provided, state) => ({
            ...provided,
            border: "1px solid var(--discordSelectMenuBorder)",
            backgroundColor: "var(--discordEmbedBackground)",
            borderRadius: state.placement === "top" ? "4px 4px 0 0" : "0 0 4px 4px",
            margin: "0px",
            padding: "0px !important",
            overflow: "clip",
        }),
        option: (provided, state) => ({
            padding: "8px",
            margin: "0px",
            cursor: "pointer",
            color: "var(--textColor01)",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            WebkitBoxAlign: "center",
            alignItems: "center",
            boxSizing: "border-box",
            fontSize: "14px",
            fontFamily: `"gg sans", "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important`,
            ":hover": {
                backgroundColor: "var(--discordSelectMenuHover)",
            },
        }),
        control: (provided, state) => ({
            backgroundColor: "var(--discordSelectMenuBackground)",
            width: "90% !important",
            maxWidth: "400px",
            marginRight: "16px",
            padding: "8px !important",
            border: "1px solid transparent",
            cursor: "pointer",
            boxSizing: "border-box",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            WebkitBoxAlign: "center",
            alignItems: "center",
            borderRadius: "4px",
            transition: "border 0.2s ease !important",
            ":hover": {
                borderColor: "var(--discordSelectMenuBorder)",
            },
        }),
        placeholder: (provided, state) => ({
            color: "var(--discordTextMuted)",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        }),
        valueContainer: (provided, state) => ({
            display: "flex",
        }),
        indicatorSeparator: (provided, state) => ({}),
        dropdownIndicator: (provided, state) => ({
            color: "var(--textColor01)",
            display: "flex",
            WebkitBoxAlign: "center",
            alignItems: "center",
            gap: "4px",
            rotate: state.isFocused ? "180deg" : "0deg",
        }),
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 206,
        }),
        input: (provided, state) => ({
            ...provided,
            color: "var(--textColor01)",
            ...data?.input,
        }),
    };

    return selectData;
};

export default DiscordComponentPreviewer;
