import Axios from "axios";

import AppData from "@app@::@data";

const testUp = () => Axios.get(`${AppData.project.backendDomain}/api/up`);

const getUserData = () => Axios.get(`${AppData.project.backendDomain}/api/auth`, { withCredentials: true });

const getGuilds = () => Axios.get(`${AppData.project.backendDomain}/api/discord/guilds`, { withCredentials: true });

const getGuild = (id: Snowflake) => Axios.get(`${AppData.project.backendDomain}/api/discord/guilds/${id}`, { withCredentials: true });

const getGuildConfig = (id: Snowflake) => Axios.get(`${AppData.project.backendDomain}/api/discord/guilds/${id}/config`, { withCredentials: true });

const resetGuildConfig = (id: Snowflake) => Axios.delete(`${AppData.project.backendDomain}/api/discord/guilds/${id}/reset`, { withCredentials: true });

const postCustomer = (data: any, path: string) => Axios.post(`${AppData.project.backendDomain}/api/customer/${path}`, { ...data }, { withCredentials: true });

const list = { testUp, getUserData, getGuilds, getGuild, getGuildConfig, resetGuildConfig, postCustomer };

export default list;
