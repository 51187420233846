import React from "react";
import { Helmet } from "react-helmet";

import AppComponent from "@app@::@component";

import DocumentComponent from "@route@::@document:component";

const Path = "/terms";
const Options = { caseSensitive: false };

const Document: React.FC<PageProps> = ({ utils }) => {
    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body overflow-hidden">
                <Helmet children={<title>Titan-Bot – Terms</title>} />
                <AppComponent.Animation.ScreenCover />
                <AppComponent.Header utils={utils} />
                <DocumentComponent.TermsSection utils={utils} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: Document, Path, Options };

export default Page;
