import React from "react";

import AppComponent from "@app@::@component";

import HomeComponent from "@route@::@home:component";
import HomeHook from "@route@::@home:hook";

const Path = "/";
const Options = { caseSensitive: true };

const Home: React.FC<PageProps> = ({ utils }) => {
    const { loading, data } = HomeHook.Query();

    if (loading) return <AppComponent.Loading />; // Return loading component if loading is active
    else if (!data) return utils.use.Navigate("/"); // Redirects to the home page if theirs no data

    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body overflow-hidden">
                <AppComponent.Animation.ScreenCover />
                <AppComponent.Header utils={utils} />
                <AppComponent.ProfileMenu utils={utils} profile={data.profile} path={Path} />
                <HomeComponent.Section utils={utils} data={data} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: Home, Path, Options };

export default Page;
