import React from "react";
import { BiRocket } from "react-icons/bi";
import Translate from "react-translate-component";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

export const Savebar: React.FC<DashboardInterface.SavebarProps> = ({ revertChanges, saveChanges }) => {
    return (
        <div className="save-bar closed">
            <div className="bar">
                <Translate content="routes.dashboard.savebar.message" component="h1" className="save-bar-text ml-2" />
                <div className="button flex gap-6">
                    <button className="save-bar-button color-link max-h-[35px]" onClick={() => revertChanges()}>
                        <img src={`${process.env.PUBLIC_URL}/media/svg/loading02.svg`} alt="loading" />
                        <Translate content="routes.dashboard.savebar.reset" component="span" className="button-text" />
                    </button>
                    <button className="save-bar-button hover02 color-success max-h-[35px]" onClick={() => saveChanges()}>
                        <img src={`${process.env.PUBLIC_URL}/media/svg/loading02.svg`} alt="loading" />
                        <Translate content="routes.dashboard.savebar.save" component="span" className="button-text" />
                        <span className="button-icon">
                            <BiRocket />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};
