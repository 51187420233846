import React, { useRef } from "react";

const EmojiMenu: React.FC<{ element?: any; picker: any; active: boolean; onClose?: any; utils: any }> = ({ element, picker, active, onClose, utils }) => {
    const ref = useRef<any>();

    utils.use.ClickOutside(onClose, ref);

    if (!active) return element || <></>;
    return (
        <span ref={ref}>
            {element}
            <div style={{ zIndex: 2, position: "absolute", top: "50px" }}>{picker}</div>
        </span>
    );
};

export default EmojiMenu;
