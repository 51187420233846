import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Counterpart from "counterpart";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import "react-toastify/dist/ReactToastify.css";

import AppData from "@app@::@data";
import AppUtil from "@app@::@util";

import Loading from "@components@::/page/loading.component";
import Header from "@components@::/page/header.component";
import Footer from "@components@::/page/footer.component";
import ProfileMenu from "@components@::/page/profilemenu.component";
import ScrollReveal from "@components@::/page/scrollreveal.component";
import Chart from "@components@::/page/chart.component";
import Animation from "@components@::/page/animation.component";
import PopUp from "@components@::/page/popup.component";
import Pagination from "@components@::/page/pagination.component";
import Tooltip from "@components@::/page/tooltip.component";
import BackgroundObjects from "@components@::/page/backgroundobjects.component";
import Login from "@components@::/page/login.component";
import Payment from "@components@::/page/payment.component";
import Incrementor from "@components@::/page/incrementor.component";
import TimePicker from "@components@::/page/timepicker.component";
import DiscordMessagePreviewer from "@components@::/page/discordmessagepreviewer.component";
import DiscordEmbedBuilder from "@components@::/page/discordembedbuilder.component";
import DiscordEmbedPreviewer from "@components@::/page/discordembedpreviewer.component";
import DiscordComponentPreviewer from "@components@::/page/discordcomponentpreviewer.component";
import ColorPicker from "@components@::/page/colorpicker.component";
import EmojiMenu from "@components@::/page/emojimenu";
import FormInput from "@components@::/page/forminput.component";

import NotFoundRoute from "@route@::@notfound";
import TermsRoute from "@route@::@document:terms";
import PrivacyRoute from "@route@::@document:privacy";
import HomeRoute from "@route@::@home";
import CommandsRoute from "@route@::@commands";
// import PremiumRoute from "@route@::@premium";
import DashboardRoute from "@route@::@dashboard";
import DashboardIdRoute from "@route@::@dashboard:id";
import LeaderboardIdRoute from "@route@::@leaderboard:id";
// import ProfileRoute from "@route@::@profile";

for (let [key, value] of AppData.translations.entries()) Counterpart.registerTranslations(key, value);

Counterpart.setLocale("en");

const Pages: React.FC = () => {
    const locale = Cookies.get("language") || "en";
    const translation = AppData.translations.get(locale);
    const utils = { use: AppUtil.UseCases(), locale, translation, activePopups: {} };
    return (
        <>
            <AppUtil.ApiDown utils={utils} />
            <ToastContainer theme="dark" />
            <Helmet>
                <title>Titan-Bot</title>
                <meta name="description" content="Titan Bot is a multifunctional Discord Bot that has a plethora of commands as well as amusing, interesting, and helpful modules. Everything you need is contained within a single bot, and we are constantly open to new ideas and comments!" />
            </Helmet>
            <Routes>
                <Route element={<NotFoundRoute.Page utils={utils} />} path={NotFoundRoute.Path} />

                <Route element={<TermsRoute.Page utils={utils} />} path={TermsRoute.Path} caseSensitive={TermsRoute.Options.caseSensitive} />
                <Route element={<PrivacyRoute.Page utils={utils} />} path={PrivacyRoute.Path} caseSensitive={PrivacyRoute.Options.caseSensitive} />

                <Route element={<HomeRoute.Page utils={utils} />} path={HomeRoute.Path} caseSensitive={HomeRoute.Options.caseSensitive} />
                <Route element={<CommandsRoute.Page utils={utils} />} path={CommandsRoute.Path} caseSensitive={CommandsRoute.Options.caseSensitive} />
                {/* <Route element={<PremiumRoute.Page utils={utils} />} path={PremiumRoute.Path} caseSensitive={PremiumRoute.Options.caseSensitive} /> */}

                <Route element={<DashboardRoute.Page utils={utils} />} path={DashboardRoute.Path} caseSensitive={DashboardRoute.Options.caseSensitive} />
                <Route element={<DashboardIdRoute.Page utils={utils} />} path={DashboardIdRoute.Path} caseSensitive={DashboardIdRoute.Options.caseSensitive} />
                <Route element={<LeaderboardIdRoute.Page utils={utils} />} path={LeaderboardIdRoute.Path} caseSensitive={LeaderboardIdRoute.Options.caseSensitive} />
                {/* <Route element={<ProfileRoute.Page utils={utils} />} path={ProfileRoute.Path} caseSensitive={ProfileRoute.Options.caseSensitive} /> */}
            </Routes>
        </>
    );
};

const Index = { Pages, Loading, Header, Footer, ProfileMenu, ScrollReveal, Chart, Animation, PopUp, Pagination, Tooltip, BackgroundObjects, Login, Payment, Incrementor, TimePicker, DiscordMessagePreviewer, DiscordEmbedBuilder, DiscordEmbedPreviewer, DiscordComponentPreviewer, ColorPicker, EmojiMenu, FormInput };

export default Index;
