import React from "react";
import Translate from "react-translate-component";
import Dot from "dot-object";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, AddressElement } from "@stripe/react-stripe-js";
import { BiCreditCard, BiCalendar, BiTrash, BiBone, BiPlus, BiCreditCardFront } from "react-icons/bi";
import { FaStripeS, FaExchangeAlt } from "react-icons/fa";

import AppComponent from "@app@::@component";

//#region Data
const CardElementOptions = {
    style: {
        base: {
            color: "#ffffff",
            fontSize: "18px",
            "::placeholder": {
                color: "rgba(255, 255, 255, 0.5)",
            },
        },
        invalid: {
            color: "#fa755a",
        },
    },
};

const MethodReady = (data: any, card: any, ready: any, error: any) => {
    Dot.set(data.type.toString(), data.complete, card[0]);
    card[1](card[0]);
    if (data.error) error[1](data.error.code);
    else {
        error[1]("");
        ready[1](card[0][0] && card[0][1] && card[0][2] ? true : false);
    }
};

const updateDefaultPaymentMethod = (update: Function, refetchData: Function, profileId: Snowflake, paymentMethodId: string) => {
    const buttons = document.querySelectorAll(".payment-method-card-list-buttons");
    buttons.forEach((a) => {
        a.firstElementChild?.classList.add("disabled");
        a.lastElementChild?.classList.add("disabled");
    });
    update({ variables: { id: profileId, data: JSON.stringify({ type: "UPDATE_DEFAULT_PAYMENT_METHOD", payment_method: paymentMethodId }) } }).then(async () => {
        await refetchData();
        buttons.forEach((a) => {
            a.firstElementChild?.classList.remove("disabled");
            a.lastElementChild?.classList.remove("disabled");
        });
    });
};

const createPaymentMethod = async (stripe: Stripe | null, stripeElements: StripeElements | null, data: any, update: Function, refetchData: Function): Promise<{ success: boolean; error: string }> => {
    if (!stripe || !stripeElements) return { success: false, error: "" };

    const paymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: stripeElements.getElement(CardNumberElement) as any,
        billing_details: {
            name: data.name,
            email: data.email,
        },
    });

    if (paymentMethodResult.error) return { success: false, error: paymentMethodResult.error.code as string };
    else {
        const body = document.querySelector("body");
        body?.classList.add("load");
        await update({ variables: { id: data.id, data: JSON.stringify({ type: "UPDATE_PAYMENT_METHOD", payment_method: paymentMethodResult.paymentMethod.id }) } });
        await refetchData();
        body?.classList.remove("load");
        return { success: true, error: "" };
    }
};

const deletePaymentMethod = (update: Function, refetchData: Function, profileId: Snowflake, paymentMethodId: string) => {
    const buttons = document.querySelectorAll(".payment-method-card-list-buttons");
    buttons.forEach((a) => {
        a.firstElementChild?.classList.add("disabled");
        a.lastElementChild?.classList.add("disabled");
    });
    update({ variables: { id: profileId, data: JSON.stringify({ type: "DELETE_PAYMENT_METHOD", payment_method: paymentMethodId }) } }).then(async () => {
        await refetchData();
        buttons.forEach((a) => {
            a.firstElementChild?.classList.remove("disabled");
            a.lastElementChild?.classList.remove("disabled");
        });
    });
};
//#endregion

const ConfirmPayment: React.FC<{ profile: IProfile; items: [any[], any]; changeMethod: any; currentPlan?: any }> = ({ profile, items, changeMethod, currentPlan }) => {
    const defaultPaymentMethod = profile.customer.payment_methods.data.find((method: any) => method.id === profile.customer.invoice_settings.default_payment_method);
    const total = items[0].map((item: any) => profile.customer.plans.find((plan: any) => plan.index_id === item.id).amount * item.quantity).reduce((partialSum: any, a: any) => partialSum + a, 0);
    // const profilePremium = items[0].find((a) => [0, 1].includes(a.id));
    return (
        <div className="confirm_payment">
            <div className="payment_list">
                <div className="card-container">
                    <Translate content="components.payment.confirm_payment.method" component="h1" className="text title-7" />
                    {defaultPaymentMethod ? (
                        <div className="card flex justify-between items-center">
                            <div className="main">
                                <div className="primary">
                                    <h1 className="text brand">{defaultPaymentMethod.card.brand}</h1>
                                    <h1 className="text card-end">•••• {defaultPaymentMethod.card.last4}</h1>
                                </div>
                                <div className="secondary flex gap-2">
                                    <h1 className="text name">{defaultPaymentMethod.billing_details.name}</h1>
                                </div>
                            </div>
                            <div>
                                <div className="button flex justify-center screen gap-3 payment-method-card-list-buttons">
                                    <button className="hover02 color-success " onClick={changeMethod}>
                                        <span className="button-icon both">
                                            <FaExchangeAlt />
                                        </span>
                                        <Translate content="words.change" component="span" className="button-text" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card flex justify-center items-center flex-col">
                            <div className="button flex justify-center screen gap-3 payment-method-card-list-buttons">
                                <button className="hover02 color-success " onClick={changeMethod}>
                                    <span className="button-icon both">
                                        <BiBone />
                                    </span>
                                    <Translate content="components.payment.confirm_payment.select_method" component="span" className="button-text" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="card-container mt-4">
                <div className="payment_list">
                    {items[0].map((item: any, index: any) => (
                        <div key={index} className="card flex justify-between items-center product">
                            <div className="main">
                                <div className="primary">
                                    <h1 className="text product-name">
                                        <Translate content={`components.payment.products.p${item.id}.name`} />
                                        {items[0].filter((a) => [0, 1].includes(a.id)).length > 0 && [2, 3].includes(item.id) && " x2"}
                                    </h1>
                                </div>
                                <div className="secondary flex gap-2">
                                    <h1 className="text product-price">
                                        {item.quantity > 0 ? (
                                            <>
                                                ${(profile.customer.plans.find((plan: any) => plan.index_id === item.id).amount * item.quantity) / 100} / <Translate content={`components.payment.products.p${item.id}.duration`} component="span" />
                                            </>
                                        ) : (
                                            "$0.00"
                                        )}
                                    </h1>
                                </div>
                            </div>
                            {/* <div className="flex gap-3">
                                {[0, 1, 2, 3].includes(item.id) && item.quantity > 0 && (
                                    <div className="button flex justify-center screen gap-3 payment-method-card-list-buttons">
                                        <button className={`hover02 color-primary ${currentPlan === [1, 0][item.id] ? "disabled" : ""}`} onClick={() => items[1](items[0].map((obj) => (obj.id === item.id ? { ...item, id: [1, 0, 3, 2][item.id] } : obj)))}>
                                            <span className="button-icon both">
                                                <FaExchangeAlt />
                                            </span>
                                            <Translate content="components.payment.confirm_payment.switch_plan" component="span" className="button-text" />
                                        </button>
                                    </div>
                                )}
                                {![0, 1, 2, 3].includes(item.id) && (
                                    <AppComponent.Incrementor
                                        value={profilePremium ? item.quantity + 2 : item.quantity}
                                        min={profilePremium ? 2 : 1}
                                        max={100}
                                        target={(data: any) => {
                                            if (data === "-") item.quantity = item.quantity - 1;
                                            else if (data === "+") item.quantity = item.quantity + 1;
                                            else item.quantity = profilePremium ? data - 2 : data;
                                            items[1](items[0].map((obj) => (obj.id === item.id ? item : obj)));
                                            return item.quantity;
                                        }}
                                    />
                                )}
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
            <div className="payment_list mt-4">
                <div className="card-container">
                    <h1 className="text title-7">
                        <Translate content="words.total" component="span" /> ${total / 100}
                    </h1>
                </div>
            </div>
        </div>
    );
};

const ListPaymentMethod: React.FC<{ profile: IProfile; newMethod: any; refetchData: Function; update: Function; billing?: boolean }> = ({ profile, newMethod, update, refetchData, billing }) => {
    return (
        <div className="payment_list">
            {billing && (
                <div className="button w-full mt-1">
                    <button className={`hover03 color-success full`} onClick={newMethod}>
                        <Translate content="words.add_new" className="button-text both" />
                        <span className="button-icon">
                            <BiPlus />
                        </span>
                    </button>
                </div>
            )}
            {profile.customer.payment_methods.data.length < 1 && (
                <div className="card flex justify-center items-center">
                    <Translate content="components.payment.list_payment_methods.no_method" component="h1" className="text subtitle-4" />
                </div>
            )}
            {JSON.parse(JSON.stringify(profile.customer.payment_methods.data)).map((data: any, index: number) => (
                <div key={index} className="card flex justify-between items-center">
                    <div className="main">
                        <div className="primary">
                            <h1 className="text brand">{data.card.brand}</h1>
                            <h1 className="text card-end">•••• {data.card.last4}</h1>
                        </div>
                        <div className="secondary flex gap-2">
                            <h1 className="text name">{data.billing_details.name}</h1>
                            {profile.customer.invoice_settings.default_payment_method === data.id && <Translate content="words.default" component="div" className="text-white bg-[#2f3a4e] w-fit px-3 rounded-md" />}
                        </div>
                    </div>
                    <div>
                        <div className="button flex justify-center screen gap-3 payment-method-card-list-buttons">
                            {profile.customer.invoice_settings.default_payment_method !== data.id && (
                                <button className="hover01 color-primary" onClick={() => updateDefaultPaymentMethod(update, refetchData, profile._id, data.id)}>
                                    <Translate content="components.payment.list_payment_methods.set_default" component="span" className="button-text" />
                                </button>
                            )}
                            <button className="hover02 color-danger " onClick={() => deletePaymentMethod(update, refetchData, profile._id, data.id)}>
                                <span className="button-icon">
                                    <BiTrash />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            {!billing && (
                <div className="button w-full mt-1">
                    <button className={`hover03 color-success full`} onClick={newMethod}>
                        <Translate content="words.add_new" className="button-text both" />
                        <span className="button-icon">
                            <BiPlus />
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
};

const AddPaymentMethod: React.FC<{ profile: IProfile; trigger: any; cancel: any; refetchData: Function; update: Function; utils?: any }> = ({ profile, trigger, cancel, refetchData, update, utils }) => {
    const paymentMethod = React.useState<string>("stripe");
    const fullName = React.useState<string>("");
    const methodReady = React.useState<boolean>(false);
    const cardReady = React.useState<any>({ "0": false, "1": false, "2": false });
    const error = React.useState<string>("");
    const stripe = useStripe();
    const stripeElements = useElements();

    return (
        <AppComponent.PopUp id="AddPaymentMethodPopup" trigger={trigger} close closeFull onClose={cancel} utils={utils}>
            <Translate content="components.payment.create_payment_method.title" component="h1" className="text title-7 text-center" />
            {/* <div className="button flex justify-center gap-2 screen mb-3">
                <button className={`hover01 color-primary ${paymentMethod[0] !== "stripe" ? "" : "disabled"}`} onClick={() => paymentMethod[1]("stripe")}>
                    <span className="button-icon both">
                        <FaStripeS />
                    </span>
                    <span className="button-text">Stripe</span>
                </button>
                <button className={`hover01 color-primary ${paymentMethod[0] !== "soon" ? "" : "disabled"}`} onClick={() => paymentMethod[1]("soon")}>
                     <span className="button-icon both">
                            <FaPaypal />
                        </span> 
                    <span className="button-text">...</span>
                </button>
            </div> */}
            {error[0].length > 1 && (
                <div className="px-3 py-1 bg-[#ff858530] border-2 border-[#ff8585] rounded-lg">
                    <Translate content={`errors.stripe.${error[0]}`} component="p" className="text-lg text error" />
                </div>
            )}
            {paymentMethod[0] === "stripe" && (
                <>
                    <div className="card-input">
                        <Translate content="components.payment.create_payment_method.card_details" component="h1" className="text title-8" />
                        <div className="text-form rounded-[4px]">
                            <Translate component="input" type="text" id="card-holder-name" className="form-input plane" maxLength={70} required attributes={{ placeholder: "components.payment.create_payment_method.card_holder" }} onChange={(value: any) => fullName[1](value.target.value)} />
                        </div>
                        <div className="card-number flex gap-2 mt-4">
                            <span className="icon">
                                <BiCreditCardFront />
                            </span>
                            <CardNumberElement className="w-full" options={CardElementOptions} onChange={(data) => MethodReady({ type: 0, ...data }, cardReady, methodReady, error)} />
                        </div>
                        <div className="flex gap-4 mt-4">
                            <div className="card-expiry flex gap-2 w-full">
                                <span className="icon">
                                    <BiCalendar />
                                </span>
                                <CardExpiryElement className="w-full" options={CardElementOptions} onChange={(data) => MethodReady({ type: 1, ...data }, cardReady, methodReady, error)} />
                            </div>
                            <div className="card-cvc flex gap-2 w-full">
                                <span className="icon">
                                    <BiCreditCard />
                                </span>
                                <CardCvcElement className="w-full" options={{ placeholder: "CVC / CVV", ...CardElementOptions }} onChange={(data) => MethodReady({ type: 2, ...data }, cardReady, methodReady, error)} />
                            </div>
                        </div>
                        <AddressElement options={{ mode: "billing" }} />
                    </div>
                </>
            )}
            {paymentMethod[0] === "soon" && <Translate content="components.payment.create_payment_method.coming_soon" component="h1" className="text title-8 text-center" />}
            <div className="button flex justify-between screen mt-5">
                <button className="hover02 color-secondary" onClick={cancel}>
                    <Translate content="words.cancel" component="span" className="button-text" />
                </button>
                <button
                    className={`hover02 color-primary min-w-[100px] justify-center ${paymentMethod[0] === "stripe" && methodReady[0] && fullName[0].length > 1 ? "" : "disabled"}`}
                    onClick={async () => {
                        const data = await createPaymentMethod(stripe, stripeElements, { id: profile._id, email: profile.settings.user.email, name: fullName[0] }, update, refetchData);
                        if (!data.success) error[1](data.error);
                        else {
                            cancel();
                            methodReady[1](false);
                            cardReady[1]({ "0": false, "1": false, "2": false });
                            fullName[1]("");
                        }
                    }}>
                    <Translate content="words.next" component="span" className="button-text" />
                </button>
            </div>
        </AppComponent.PopUp>
    );
};

const Data = { ConfirmPayment, ListPaymentMethod, AddPaymentMethod };

export default Data;
