const List = {
    auditLogMessagesList: {
        "1::0": '<span class="change-name">Prefix</span> updated to <span class="change-value">{0}</span>.',
        "2::0": '<span class="change-name">Nickname</span> updated to <span class="change-value">{0}</span>.',
        "3::0": '<span class="change-name">Language</span> updated to <span class="change-value">{0}</span>.',
        "4::0": '<span class="change-index">{0}</span> <span class="change-name">{name}</span> {1} {2}.<br/>⨽ <span class="change-index">Channel</span> - <span class="change-value">{3}</span>.',
        "4::1": 'All <span class="change-name">Ignore Channels</span> were removed.',
        "5::0": '<span class="change-index">{0}</span> <span class="change-name">{name}</span> {1} {2}.<br/>⨽ <span class="change-index">Role</span> - <span class="change-value">{3}</span>.',
        "5::1": 'All <span class="change-name">Ignore Roles</span> were removed.',
        "6::0": '<span class="change-name">{0}</span> Module : <span class="change-value">{1}</span>.',
        "6::1": '<span class="change-name">{0}</span> Module : <span class="change-value">{1}</span>.<br/>{2}',
        "6::2": '<span class="change-name">{0}</span> <span class="change-value">{1}</span>.<br/>{2}',
        "7::0": 'A <span class="change-name">Command</span> was configured.',
        "8::0": '<span class="change-name">{0}</span> Module : <span class="change-index">Max Level</span> changed to <span class="change-value">{1}</span>.',
        "8::1": '<span class="change-name">{0}</span> Module : <span class="change-index">XP Rate</span> changed to <span class="change-value">{1}x</span>.',
        "8::2": '<span class="change-name">{0}</span> Module : <span class="change-index">Level Up Message Type</span> changed to <span class="change-value">{1}</span>.',
        "8::3": '<span class="change-name">{0}</span> Module : <span class="change-index">Level Up Channel</span> changed to <span class="change-value">{1}</span>.',
        "8::4": '<span class="change-name">{0}</span> Module : <span class="change-index">Level Up Message</span> changed to <span class="change-value">{1}</span>.',
        "8::5": '<span class="change-name">{0}</span> Module : <span class="change-index">{1}</span> was <span class="change-value">{2}</span>.',
        "8::6": '<span class="change-name">{0}</span> Module : <span class="change-index">{1}</span> updated <span class="change-value">{2}</span>.',
        "8::7": '<span class="change-name">{0}</span> Module : <span class="change-index">Reward Roles</span> updated.',
        "9::0": '<span class="change-name">{0}</span> Module : <span class="change-index">{1}</span> {2}.',
        "9::1": '<span class="change-name">{0}</span> Module : <span class="change-index">{1}</span> updated to <span class="change-value">{2}</span>.',
        "9::2": '<span class="change-name">{0}</span> Module : <span class="change-index">{1}</span> removed.',
    },
    embedStructure: { author: { icon_url: "", name: "", url: "" }, description: "", footer: { icon_url: "", text: "" }, image: { url: "" }, thumbnail: { url: "" }, title: "", url: "", fields: [], color: null, timestamp: null },
    regex: {
        discordDomainsPattern: /(discord(?:app)?\.(com|net|new)|discord\.gifts?|discord\.media|discord\.gg|discord\.co|discord\.app|dis\.gd)/i,
    },
};

export default List;
