import React from "react";
import { Helmet } from "react-helmet";

import AppComponent from "@app@::@component";

import ServersComponent from "@route@::@dashboard:component";
import ServersHook from "@route@::@dashboard:hook";

const Path = "/dashboard";
const Options = { caseSensitive: true };

const Dashboard: React.FC<PageProps> = ({ utils }) => {
    const { loading, data } = ServersHook.Query();

    if (loading) return <AppComponent.Loading />; // Return loading component if loading is active
    else if (!data) return utils.use.Navigate("/"); // Redirects to the home page if theirs no data

    const guildId = utils.use.SearchParams[0].get("guild_id");
    if (guildId) return utils.use.Navigate(`/dashboard/${guildId}`);

    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body overflow-hidden">
                <Helmet children={<title>Dashboard – Server Select</title>} />
                <AppComponent.Animation.ScreenCover />
                <AppComponent.Header utils={utils} />
                <AppComponent.ProfileMenu utils={utils} profile={data.profile} path={Path} />
                {data.profile ? <ServersComponent.Section utils={utils} data={data} /> : <AppComponent.Login />}
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: Dashboard, Path, Options };

export default Page;
