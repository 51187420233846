import { useState, useEffect } from "react";
import { useNavigate, useMatch, useSearchParams } from "react-router-dom";
import { Stripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import Translate from "react-translate-component";
import ScrollReveal from "scrollreveal";
import Cookies from "js-cookie";
import Counterpart from "counterpart";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import HttpApi from "@http@::@api";
import Utility from "@utility@::@index";

import UseHooks from "./hooks";

const InitializeBody = () => {
    // for (let blockedEvent of ["contextmenu", "dragstart"]) {
    //     document.addEventListener(blockedEvent, function (event) {
    //         event.preventDefault();
    //         return false;
    //     });
    // }

    const Theme = Cookies.get("theme");
    Theme && ["light-theme", "dark-theme"].includes(Theme) ? (document.body.className = Theme) : (document.body.className = "dark-theme") && Cookies.set("theme", "dark-theme");

    const Language = Cookies.get("language");
    Counterpart.setLocale(Language || "en");

    ScrollReveal({ reset: true, distance: "60px", duration: 2000, delay: 400 });
};

const UseCases = () => {
    const Navigate = useNavigate();
    const SearchParams = useSearchParams();
    const Match = useMatch;

    return { Navigate, Match, SearchParams, ...UseHooks };
};

const ApiDown: React.FC<{ utils: any }> = ({ utils }) => {
    const apiDown = useState<boolean>(false);
    useEffect(() => {
        HttpApi.testUp().catch(() => apiDown[1](true));
    }, [apiDown]);
    return (
        <AppComponent.PopUp id="ApiDownPopup" trigger={apiDown} highIndex styleBox={{ border: "2px solid rgb(255, 123, 123)" }} utils={utils}>
            <h1 className="text title-4 center-text" style={{ color: "rgb(255, 123, 123)" }}>
                ⚠️
            </h1>
            <h1 className="text title-6 center-text">Oh No! {Utility.RandomElement(["（＞人＜；）", "(┬┬﹏┬┬)", "(⊙_⊙;)", "つ﹏⊂"])}</h1>
            <p className="text center-text ma t1">
                Something is wrong; Titan-Bot API appears to be unavailable and is not responding at the moment; please try again later.
                <br />
                <br />
                If you have any questions you can join our <a href={AppData.project.serverInvite}>Discord server</a> for support
            </p>
        </AppComponent.PopUp>
    );
};

const CreateSubmitSubscriptionStripe = async (stripe: Stripe | null, data: any) => {
    if (!stripe) return { success: false, error: { code: "unknown_error" } };

    const response = await HttpApi.postCustomer({ type: 1, items: data.items }, "subscribe");
    const result = await stripe.confirmCardPayment(response.data.clientSecret);

    if (result.error) return { success: false, error: result.error };
    return { success: true, error: null };
};

export const ToastDiscordEmbedValidity = (embed: any): boolean => {
    let value = true;
    if ((embed?.author?.url || embed?.author?.icon_url) && !embed?.author?.name) toast(<Translate content="components.embed.embed_author_url_empty" />, { type: "warning", autoClose: 10000 });
    if (embed?.url && !embed?.title) toast(<Translate content="components.embed.embed_url_empty" />, { type: "warning", autoClose: 10000 });
    if (embed?.footer?.icon_url && !embed?.footer?.text) toast(<Translate content="components.embed.embed_footer_url_empty" />, { type: "warning", autoClose: 10000 });
    if (embed?.fields?.filter((a: any) => !a.name || !a.value)?.length > 0) {
        toast(<Translate content="components.embed.embed_field_empty" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (embed?.author?.url && !Utility.LinkParser(embed?.author?.url)) {
        toast(<Translate content="components.embed.embed_author_url_invalid" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (embed?.author?.icon_url && !Utility.LinkParser(embed?.author?.icon_url)) {
        toast(<Translate content="components.embed.embed_author_icon_url_invalid" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (embed?.url && !Utility.LinkParser(embed?.url)) {
        toast(<Translate content="components.embed.embed_url_invalid" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (embed?.thumbnail?.url && !Utility.LinkParser(embed?.thumbnail?.url)) {
        toast(<Translate content="components.embed.embed_thumbnail_url_invalid" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (embed?.image?.url && !Utility.LinkParser(embed?.image?.url)) {
        toast(<Translate content="components.embed.embed_image_url_invalid" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (embed?.footer?.icon_url && !Utility.LinkParser(embed?.footer?.icon_url)) {
        toast(<Translate content="components.embed.embed_footer_icon_url_invalid" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    if (Utility.GetDiscordEmbedSize(embed) > 6000) {
        toast(<Translate content="components.embed.embed_too_large" />, { type: "error", autoClose: 10000 });
        value = false;
    }
    return value;
};

const Utils = { InitializeBody, UseCases, ApiDown, CreateSubmitSubscriptionStripe, ToastDiscordEmbedValidity };

export default Utils;
