import React from "react";
import Dot from "dot-object";
import Translate from "react-translate-component";

import AppComponent from "@app@::@component";

import DashboardComponent from "./index";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const additionalProps = { toggleColor: "background-dark-03", togglePlacement: "right", toggleSpace: true, labelClass: "opacity-100" };

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "logging";
    const sectionPath = "modules.logging";
    const sectionData = Dot.pick(sectionPath, config.data.current);

    const events = sectionData.settings.events;
    const eventsPath = "modules.logging.settings.events";

    return (
        <section className="dashboard-section">
            <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
            <div className="container mx-auto mb-20 form">
                {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                {sectionData.active && (
                    <>
                        <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 mb-5">
                            <Translate content="routes.dashboard.logging.channels" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.logging.channels_i" component="h1" className="text subtitle-4" />
                            <AppComponent.FormInput type="select" htmlFor="logging-default-channel" label={utils.translation.routes.dashboard.logging.default_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.default], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.default", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                            <div className="grid-col-2 gap-select">
                                <AppComponent.FormInput type="select" htmlFor="logging-server-channel" label={utils.translation.routes.dashboard.logging.server_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.server], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.server", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                <AppComponent.FormInput type="select" htmlFor="logging-punishment-channel" label={utils.translation.routes.dashboard.logging.punishment_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.punishment], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.punishment", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                <AppComponent.FormInput type="select" htmlFor="logging-integration-channel" label={utils.translation.routes.dashboard.logging.integration_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.integration], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.integration", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                <AppComponent.FormInput type="select" htmlFor="logging-member-channel" label={utils.translation.routes.dashboard.logging.member_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.member], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.member", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                <AppComponent.FormInput type="select" htmlFor="logging-message-channel" label={utils.translation.routes.dashboard.logging.message_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.message], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.message", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                <AppComponent.FormInput type="select" htmlFor="logging-voice-channel" label={utils.translation.routes.dashboard.logging.voice_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channels.voice], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.logging.settings.channels.voice", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                            </div>
                        </div>
                        <div className="logging-grid grid-flow-dense">
                            <div className="background-02 h-fit w-full rounded-[8px] px-4 pt-2 pb-4 grid gap-3">
                                <Translate content="routes.dashboard.logging.server_events" component="h1" className="text title-8" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="guildUpdate" label={utils.translation.routes.dashboard.logging.events.guildUpdate} value={events.guildUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".guildUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="autoModerationRuleCreate" label={utils.translation.routes.dashboard.logging.events.autoModerationRuleCreate} value={events.autoModerationRuleCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".autoModerationRuleCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="autoModerationRuleDelete" label={utils.translation.routes.dashboard.logging.events.autoModerationRuleDelete} value={events.autoModerationRuleDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".autoModerationRuleDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="autoModerationRuleUpdate" label={utils.translation.routes.dashboard.logging.events.autoModerationRuleUpdate} value={events.autoModerationRuleUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".autoModerationRuleUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="channelCreate" label={utils.translation.routes.dashboard.logging.events.channelCreate} value={events.channelCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".channelCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="channelDelete" label={utils.translation.routes.dashboard.logging.events.channelDelete} value={events.channelDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".channelDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="channelUpdate" label={utils.translation.routes.dashboard.logging.events.channelUpdate} value={events.channelUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".channelUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="channelOverwriteCreate" label={utils.translation.routes.dashboard.logging.events.channelOverwriteCreate} value={events.channelOverwriteCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".channelOverwriteCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="channelOverwriteDelete" label={utils.translation.routes.dashboard.logging.events.channelOverwriteDelete} value={events.channelOverwriteDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".channelOverwriteDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="channelOverwriteUpdate" label={utils.translation.routes.dashboard.logging.events.channelOverwriteUpdate} value={events.channelOverwriteUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".channelOverwriteUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="threadCreate" label={utils.translation.routes.dashboard.logging.events.threadCreate} value={events.threadCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".threadCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="threadDelete" label={utils.translation.routes.dashboard.logging.events.threadDelete} value={events.threadDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".threadDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="threadUpdate" label={utils.translation.routes.dashboard.logging.events.threadUpdate} value={events.threadUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".threadUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="roleCreate" label={utils.translation.routes.dashboard.logging.events.roleCreate} value={events.roleCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".roleCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="roleDelete" label={utils.translation.routes.dashboard.logging.events.roleDelete} value={events.roleDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".roleDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="roleUpdate" label={utils.translation.routes.dashboard.logging.events.roleUpdate} value={events.roleUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".roleUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="guildScheduledEventCreate" label={utils.translation.routes.dashboard.logging.events.guildScheduledEventCreate} value={events.guildScheduledEventCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".guildScheduledEventCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="guildScheduledEventDelete" label={utils.translation.routes.dashboard.logging.events.guildScheduledEventDelete} value={events.guildScheduledEventDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".guildScheduledEventDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="guildScheduledEventUpdate" label={utils.translation.routes.dashboard.logging.events.guildScheduledEventUpdate} value={events.guildScheduledEventUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".guildScheduledEventUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="emojiCreate" label={utils.translation.routes.dashboard.logging.events.emojiCreate} value={events.emojiCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".emojiCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="emojiDelete" label={utils.translation.routes.dashboard.logging.events.emojiDelete} value={events.emojiDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".emojiDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="emojiUpdate" label={utils.translation.routes.dashboard.logging.events.emojiUpdate} value={events.emojiUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".emojiUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="stickerCreate" label={utils.translation.routes.dashboard.logging.events.stickerCreate} value={events.stickerCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".stickerCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="stickerDelete" label={utils.translation.routes.dashboard.logging.events.stickerDelete} value={events.stickerDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".stickerDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="stickerUpdate" label={utils.translation.routes.dashboard.logging.events.stickerUpdate} value={events.stickerUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".stickerUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="inviteCreate" label={utils.translation.routes.dashboard.logging.events.inviteCreate} value={events.inviteCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".inviteCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="inviteDelete" label={utils.translation.routes.dashboard.logging.events.inviteDelete} value={events.inviteDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".inviteDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="inviteUpdate" label={utils.translation.routes.dashboard.logging.events.inviteUpdate} value={events.inviteUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".inviteUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                            </div>

                            <div className="background-02 h-fit w-full rounded-[8px] px-4 pt-2 pb-4 grid gap-3">
                                <Translate content="routes.dashboard.logging.punishment_events" component="h1" className="text title-8" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberPrune" label={utils.translation.routes.dashboard.logging.events.memberPrune} value={events.memberPrune} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberPrune", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberKick" label={utils.translation.routes.dashboard.logging.events.memberKick} value={events.memberKick} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberKick", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberBanAdd" label={utils.translation.routes.dashboard.logging.events.memberBanAdd} value={events.memberBanAdd} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberBanAdd", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberBanRemove" label={utils.translation.routes.dashboard.logging.events.memberBanRemove} value={events.memberBanRemove} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberBanRemove", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberTimeout" label={utils.translation.routes.dashboard.logging.events.memberTimeout} value={events.memberTimeout} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberTimeout", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberTimeoutRemove" label={utils.translation.routes.dashboard.logging.events.memberTimeoutRemove} value={events.memberTimeoutRemove} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberTimeoutRemove", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="autoModerationBlockMessage" label={utils.translation.routes.dashboard.logging.events.autoModerationBlockMessage} value={events.autoModerationBlockMessage} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".autoModerationBlockMessage", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="autoModerationFlagToChannel" label={utils.translation.routes.dashboard.logging.events.autoModerationFlagToChannel} value={events.autoModerationFlagToChannel} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".autoModerationFlagToChannel", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="autoModerationUserCommunicationDisabled" label={utils.translation.routes.dashboard.logging.events.autoModerationUserCommunicationDisabled} value={events.autoModerationUserCommunicationDisabled} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".autoModerationUserCommunicationDisabled", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                            </div>

                            <div className="background-02 h-fit w-full rounded-[8px] px-4 pt-2 pb-4 grid gap-3">
                                <Translate content="routes.dashboard.logging.member_events" component="h1" className="text title-8" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberJoin" label={utils.translation.routes.dashboard.logging.events.memberJoin} value={events.memberJoin} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberJoin", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberLeave" label={utils.translation.routes.dashboard.logging.events.memberLeave} value={events.memberLeave} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberLeave", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberUpdate" label={utils.translation.routes.dashboard.logging.events.memberUpdate} value={events.memberUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberRoleUpdate" label={utils.translation.routes.dashboard.logging.events.memberRoleUpdate} value={events.memberRoleUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberRoleUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                            </div>

                            <div className="background-02 h-fit w-full rounded-[8px] px-4 pt-2 pb-4 grid gap-3">
                                <Translate content="routes.dashboard.logging.integration_events" component="h1" className="text title-8" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="botAdd" label={utils.translation.routes.dashboard.logging.events.botAdd} value={events.botAdd} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".botAdd", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="applicationCommandPermissionUpdate" label={utils.translation.routes.dashboard.logging.events.applicationCommandPermissionUpdate} value={events.applicationCommandPermissionUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".applicationCommandPermissionUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="integrationCreate" label={utils.translation.routes.dashboard.logging.events.integrationCreate} value={events.integrationCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".integrationCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="integrationDelete" label={utils.translation.routes.dashboard.logging.events.integrationDelete} value={events.integrationDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".integrationDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="integrationUpdate" label={utils.translation.routes.dashboard.logging.events.integrationUpdate} value={events.integrationUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".integrationUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="webhookCreate" label={utils.translation.routes.dashboard.logging.events.webhookCreate} value={events.webhookCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".webhookCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="webhookDelete" label={utils.translation.routes.dashboard.logging.events.webhookDelete} value={events.webhookDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".webhookDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="webhookUpdate" label={utils.translation.routes.dashboard.logging.events.webhookUpdate} value={events.webhookUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".webhookUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                            </div>

                            <div className="background-02 h-fit w-full rounded-[8px] px-4 pt-2 pb-4 grid gap-3">
                                <Translate content="routes.dashboard.logging.message_events" component="h1" className="text title-8" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="messageEdit" label={utils.translation.routes.dashboard.logging.events.messageEdit} value={events.messageEdit} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".messageEdit", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="messageDelete" label={utils.translation.routes.dashboard.logging.events.messageDelete} value={events.messageDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".messageDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="messageBulkDelete" label={utils.translation.routes.dashboard.logging.events.messageBulkDelete} value={events.messageBulkDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".messageBulkDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="messagePin" label={utils.translation.routes.dashboard.logging.events.messagePin} value={events.messagePin} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".messagePin", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="messageUnpin" label={utils.translation.routes.dashboard.logging.events.messageUnpin} value={events.messageUnpin} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".messageUnpin", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                            </div>

                            <div className="background-02 h-fit w-full rounded-[8px] px-4 pt-2 pb-4 grid gap-3">
                                <Translate content="routes.dashboard.logging.voice_events" component="h1" className="text title-8" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberDisconnect" label={utils.translation.routes.dashboard.logging.events.memberDisconnect} value={events.memberDisconnect} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberDisconnect", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="memberMove" label={utils.translation.routes.dashboard.logging.events.memberMove} value={events.memberMove} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".memberMove", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <div className="background-01 w-[95%] h-[2px] mx-auto my-2" />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="stageInstanceCreate" label={utils.translation.routes.dashboard.logging.events.stageInstanceCreate} value={events.stageInstanceCreate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".stageInstanceCreate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="stageInstanceDelete" label={utils.translation.routes.dashboard.logging.events.stageInstanceDelete} value={events.stageInstanceDelete} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".stageInstanceDelete", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                                <AppComponent.FormInput type="toggle-checkbox" htmlFor="stageInstanceUpdate" label={utils.translation.routes.dashboard.logging.events.stageInstanceUpdate} value={events.stageInstanceUpdate} onChange={(event) => config.dispatch({ type: "update", path: eventsPath + ".stageInstanceUpdate", data: event.target.checked })} additionalProps={additionalProps} utils={utils} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default Section;
