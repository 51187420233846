import React from "react";
import ReactMoment from "react-moment";
import Utility from "@utility@::@index";

const DiscordEmbedPreviewer: React.FC<{ guild: any; embed: any; utils: any }> = ({ guild, embed, utils }) => (
    <div className="discord-embed" style={{ borderColor: `${"#" + embed?.color?.toString(16)?.padStart(6, "0")}` }}>
        <div className="embed-wrapper">
            {embed?.author && (
                <div className="author">
                    {embed?.author.icon_url && <img src={embed?.author.icon_url} alt="" className="icon" />}
                    {embed?.author.name && embed?.author.url && (
                        <a href={embed?.author.url} rel="noopener noreferrer nofollow ugc" target="_blank" className="name">
                            {embed?.author.name}
                        </a>
                    )}
                    {embed?.author.name && !embed?.author.url && <span className="name">{embed?.author.name}</span>}
                </div>
            )}
            {embed?.title && embed?.url && (
                <a href={embed?.url} rel="noopener noreferrer nofollow ugc" target="_blank" className="title-wrapper">
                    <div className="title">{embed?.title}</div>
                </a>
            )}
            {embed?.title && !embed?.url && (
                <div className="title-wrapper">
                    <div className="title">{embed?.title}</div>
                </div>
            )}
            {embed?.description && (
                <div className="description-wrapper">
                    <div className="description" dangerouslySetInnerHTML={{ __html: Utility.FormatDiscordText(embed?.description, guild) }} />
                </div>
            )}
            {embed?.fields?.length > 0 && (
                <div className="fields">
                    {embed?.fields?.map((field: any, index: any) => (
                        <div key={index} className="field">
                            <div className="name-wrapper">
                                <div className="name">{field?.name}</div>
                            </div>
                            <div className="value-wrapper">
                                <div className="value" dangerouslySetInnerHTML={{ __html: Utility.FormatDiscordText(field?.value, guild) }} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {embed?.image?.url && <img src={embed?.image.url} alt="" className="image" />}
            {(embed?.footer || embed?.timestamp) && (
                <div className="footer-wrapper">
                    {embed?.footer?.icon_url && <img src={embed?.footer.icon_url} alt="" className="icon" />}
                    <div className="text">
                        {embed?.footer?.text}
                        {embed?.timestamp && embed?.footer?.text && <span className="divider">•</span>}
                        {embed?.timestamp && (
                            <ReactMoment locale={utils.locale} calendar>
                                {new Date(embed?.timestamp)}
                            </ReactMoment>
                        )}
                    </div>
                </div>
            )}
            {embed?.thumbnail?.url && (
                <div className="thumbnail-wrapper">
                    <img src={embed?.thumbnail?.url} alt="" className="thumbnail" />
                </div>
            )}
        </div>
    </div>
);

export default DiscordEmbedPreviewer;
