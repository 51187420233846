import React from "react";
import ReactDom from "react-dom";

const portal = document.getElementById("portal");

const Loading: React.FC<{ active?: boolean; dim?: boolean; classname?: string }> = ({ active, dim, classname }) => {
    if (!portal) return <></>;
    if (active === undefined) active = true;
    return ReactDom.createPortal(
        <div className={`loading ${classname} ${active ? "active" : ""} ${dim ? "dim" : ""}`} style={{ zIndex: 1000 }}>
            <img src={`${process.env.PUBLIC_URL}/media/svg/loading01.svg`} alt="loading" />
        </div>,
        portal
    );
};

export default Loading;
