import en from "./en.translation.json";
import es from "./es.translation.json";
import ja from "./ja.translation.json";
import de from "./de.translation.json";
import hu from "./hu.translation.json";

const Translations = new Map([
    ["en", en],
    ["es", es],
    ["ja", ja],
    ["de", de],
    ["hu", hu],
]);

export default Translations;
