import React from "react";
import Dot from "dot-object";
import Translate from "react-translate-component";
import { BiPlus } from "react-icons/bi";

import DashboardComponent from "./index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "chatbot";
    const sectionPath = "modules.chatBot";
    const sectionData = Dot.pick(sectionPath, config.data.current);

    return (
        <section className="dashboard-section">
            <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
            <div className="container mx-auto mb-20">
                {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                {sectionData.active && (
                    <div className="dashboard-multi-add-list">
                        <div className="list-bar">
                            <div className="button flex">
                                <button className="hover01 color-success" onClick={() => {}}>
                                    <Translate content="words.create_new" className="button-text both" />
                                    <span className="button-icon">
                                        <BiPlus />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Section;
