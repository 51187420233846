import { ApolloClient, InMemoryCache } from "@apollo/client";

import AppData from "@app@::@data";

const client = new ApolloClient({
    uri: `${AppData.project.backendDomain}/g`,
    credentials: "include",
    cache: new InMemoryCache({
        dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : ""),
    }),
});

export default client;
