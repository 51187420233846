import React from "react";
import Translate from "react-translate-component";
import { abbreviateNumber as AbbreviateNumber } from "js-abbreviation-number";
import { BiCategory, BiRocket } from "react-icons/bi";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

const Section: React.FC<{ data: any; extra: any; utils: any }> = ({ data, utils }) => {
    const leaderboardRenderSize = React.useState<number>(100);

    const members = data.guild.members.map((member: any) => ({ ...member, dbUser: data.config.data.user.find((user: any) => user.id === member.user.id) }));
    const filteredMembers = members.filter((member: any) => member.dbUser !== undefined).sort((a: any, b: any) => b.dbUser.level.xp - a.dbUser.level.xp);
    const leaderboardList = Utility.TrimArray(filteredMembers, leaderboardRenderSize[0], null);

    const leaderboardRenderObserver = React.useRef<any>();
    const leaderboardRenderLastElementRef = React.useCallback(
        (node: any) => {
            if (leaderboardRenderObserver.current) leaderboardRenderObserver.current.disconnect();
            leaderboardRenderObserver.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && filteredMembers.length !== leaderboardList.length) leaderboardRenderSize[1](leaderboardRenderSize[0] + 100);
            });
            if (node) leaderboardRenderObserver.current.observe(node);
        },
        [leaderboardRenderSize] // eslint-disable-line
    );

    return (
        <section className="content-area overflow-hidden">
            <AppComponent.BackgroundObjects totalObjects={leaderboardList.length > 100 ? 50 : Math.round(leaderboardList.length / 2)} positionLimit={[-10, 105]} sizeLimit={[10, 800]} />
            <div className="server-leaderboard relative container mx-auto mb-20 drop-shadow-xl">
                {data.config.settings.leaderboard ? (
                    <>
                        <img className="server-icon mx-auto mt-5 drop-shadow-lg" src={data.guild.icon ? Utility.GetGuildIconUrl(data.guild.id, data.guild.icon) + "?size=512" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                        <h1 className="server-name text font-1 text-center mt-1">
                            {data.guild.name} <Translate content="routes.dashboard.leaderboard.name" />
                        </h1>
                        <div className="leaderboard-list background-02 mt-5 mx-auto">
                            <div className="leaderboard-list-item background-03">
                                <div className="left-info">
                                    <h1 className="text font-1 text-[13px] text-center w-[50px]">#</h1>
                                    <h1 className="text font-1 text-[13px] text-center min-w-[50px]">
                                        <Translate content="words.member" />
                                    </h1>
                                </div>
                                <div className="right-info">
                                    <h1 className="text font-1 text-[13px] text-center w-[50px]">xp</h1>
                                    <h1 className="text font-1 text-[13px] text-center min-w-[50px]">
                                        <Translate content="words.level" />
                                    </h1>
                                </div>
                            </div>
                            {leaderboardList.map((member: any, index: any) => (
                                <AppComponent.Tooltip
                                    key={index}
                                    content={`${member.user.username}#${member.user.discriminator}`}
                                    children={
                                        <div ref={index + 1 === leaderboardList.length ? leaderboardRenderLastElementRef : undefined} className="leaderboard-list-item lb-item background-03">
                                            <div className="left-info">
                                                <div className="leaderboard-position background-01">
                                                    <h1 className="text font-1 text-[25px] text-center">{index + 1}</h1>
                                                </div>
                                                <img className="leaderboard-avatar" src={member?.avatar ? Utility.GetMemberAvatarUrl(data.guild.id, member.user.id, member.avatar) + "?size=128" : member.user?.avatar ? Utility.GetUserAvatarUrl(member.user.id, member.user.avatar) + "?size=128" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                                                <h1 className="leaderboard-name text font-2 text-center">
                                                    {Utility.TrimString(member.user.username, 18)}#{member.user.discriminator}
                                                </h1>
                                            </div>
                                            <div className="right-info">
                                                <h1 className="leaderboard-xp text font-1 text-center">{AbbreviateNumber(member.dbUser.level.xp)} XP</h1>
                                                <div className="leaderboard-level background-01">
                                                    <h1 className="text font-1 text-[25px] text-center">{Utility.GetServerLevelFromXP(member.dbUser.level.xp) > data.config.modules.leveling.settings.maxLevel ? data.config.modules.leveling.settings.maxLevel : Utility.GetServerLevelFromXP(member.dbUser.level.xp)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="background-02 w-fit mx-auto my-20 p-5 rounded-lg">
                            <h1 className="text font-2 text-[35px] text-center mt-1">
                                <Translate content="words.sorry" /> (´･ω･`)
                            </h1>
                            <Translate content="routes.dashboard.leaderboard.disabled" className="text subtitle-4 text-center mt-20" component="h1" />
                            <div className="button flex justify-center mt-6 gap-2">
                                <button className="hover01 color-success" onClick={() => utils.use.Navigate("/")}>
                                    <span className="button-icon both">
                                        <BiRocket />
                                    </span>
                                    <Translate content="routes.notfound.return" className="button-text" />
                                </button>
                                {data.profile && (
                                    <button className="hover01 color-blurple drop-shadow-lg" onClick={() => utils.use.Navigate("/dashboard")}>
                                        <span className="button-icon both">
                                            <BiCategory />
                                        </span>
                                        <Translate content="routes.home.buttons.b3" component="span" className="button-text" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

const Components = { Section };

export default Components;
