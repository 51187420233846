import React, { useEffect, useReducer } from "react";
import Lodash from "lodash";
import { Helmet } from "react-helmet";

import AppComponent from "@app@::@component";

import DashboardComponent from "@route@::@dashboard:id:component";
import DashboardModule from "@route@::@dashboard:id:module";
import DashboardHook from "@route@::@dashboard:id:hook";

const Path = "/dashboard/:id";
const Options = { caseSensitive: true };
let hasChanges = false;

const Home: React.FC<PageProps> = ({ utils }) => {
    const [ConfigData, ConfigDataDispatch] = useReducer(DashboardModule.ConfigDataReducer, {});

    let guildId = utils.use.Match(Path).params.id; // Guild id
    let activeSection = utils.use.SearchParams[0].get("section"); // Active page section

    const { loading, data, refetch } = DashboardHook.Query(guildId, "main", utils.locale);
    const update = DashboardHook.Mutation();

    // When section has been updated
    useEffect(() => {
        if (!["home", "leaderboard", "settings", "core", "moderation", "automod", "logging", "chatbot", "counting", "cyclicquestions", "selfrole", "leveling", "autorole", "autopublish", "greeter"].includes(activeSection)) utils.use.SearchParams[1]({ section: "home" }); // Makes sure the section selected is correct
    }, [activeSection, utils.use.SearchParams]);

    // When loading is complete
    useEffect(() => {
        if (loading) return; // Returns if loading is still active
        ConfigDataDispatch({ type: "initilize", data: data?.config });
        DashboardModule.InitializeSidebar(activeSection); // Initializes the sidebar
    }, [loading]); // eslint-disable-line

    // When data has been configured
    useEffect(() => {
        hasChanges = DashboardModule.CheckConfigData(ConfigData, hasChanges); // Checks if the config data
        if (ConfigData.current) DashboardModule.UpdateSidebarModules(ConfigData); // Updates the modules on the sidebar if theirs currentConfigData
    }, [ConfigData]);

    // When the data has been updated
    useEffect(() => {
        if (Lodash.isEqual(data?.config, ConfigData.previous)) return; // Checks if config and static config is equal, then returns if true
        ConfigDataDispatch({ type: "initilize", data: data?.config }); // Updates config
    }, [data]); // eslint-disable-line

    if (loading) return <AppComponent.Loading />; // Return loading component if loading is active
    else if (!data) return utils.use.Navigate("/"); // Redirects to the home page if theirs no data
    else if (!data.profile || !data.guild) return utils.use.Navigate("/dashboard"); // Redirects user to the dashboard page if theirs no profile/guild and the page isn't loading

    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body overflow-hidden">
                <Helmet children={<title>Dashboard – {data.guild.name}</title>} />
                <AppComponent.Loading active={ConfigData.loading} dim classname="dashboard-loading" />
                <AppComponent.Animation.ScreenCover />
                <DashboardComponent.Sidebar utils={utils} data={data} changeSection={DashboardModule.ChangeSection} activeSection={utils.use.SearchParams[1]} activeSectionName={activeSection} />
                <AppComponent.ProfileMenu utils={utils} profile={data.profile} path={Path} />
                <DashboardComponent.Savebar revertChanges={() => ConfigDataDispatch({ type: "reset" })} saveChanges={() => ConfigDataDispatch({ type: "save", data: { refetch, update, guild: data.guild } })} />
                <DashboardComponent.Section utils={utils} data={data} config={{ data: ConfigData, dispatch: ConfigDataDispatch }} extra={{ activeSection, changeSection: DashboardModule.ChangeSection, update, refetch }} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

window.addEventListener("beforeunload", function (e) {
    if (!hasChanges) return;

    var confirmationMessage = "You have unsaved changes. If you leave before saving, your changes will be lost.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
});

const Page = { Page: Home, Path, Options };

export default Page;
