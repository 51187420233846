import React from "react";
import { Helmet } from "react-helmet";

import AppComponent from "@app@::@component";

import NotFoundComponent from "@route@::@notfound:component";
import NotFoundHook from "@route@::@notfound:hook";

const Path = "*";
const Options = {};

const NotFound: React.FC<PageProps> = ({ utils }) => {
    const { loading, data } = NotFoundHook.Query();

    if (loading) return <AppComponent.Loading />; // Return loading component if loading is active
    else if (!data) return utils.use.Navigate("/"); // Redirects to the home page if theirs no data

    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body overflow-hidden">
                <Helmet children={<title>Titan-Bot – 404</title>} />
                <AppComponent.Animation.ScreenCover />
                <AppComponent.Header utils={utils} />
                <AppComponent.ProfileMenu utils={utils} profile={data.profile} path={Path} />
                <NotFoundComponent.Section utils={utils} data={data} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: NotFound, Path, Options };

export default Page;
