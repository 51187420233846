import React from "react";
import { BiInfoCircle } from "react-icons/bi";
import Translate from "react-translate-component";

import AppComponent from "@app@::@component";

export const ModuleTitle: React.FC<{ config: any; sectionName: string; sectionData: any; sectionPath: string; extra: any; new?: boolean; update?: boolean; utils: any }> = (props) => (
    <>
        <div className="module-title">
            <div>
                <Translate content={`routes.dashboard.${props.sectionName}.name`} component="span" className="text title-6-5" />
                {(props.new || props.update) && <span className="bg-[#8c82ce] px-3 pt-3 pb-1 rounded-md ml-2 hover:px-5 cursor-pointer transition-[100ms]" children={<Translate content={`words.${props.new ? "new" : "updated"}`} className="text font-1 text-[25px] white-lock" />} />}
            </div>
            <AppComponent.FormInput type="toggle-switch" value={props.sectionData.active} onChange={(value) => props.config.dispatch({ type: "update", path: `${props.sectionPath}.active`, data: value.target.checked })} additionalProps={{ toggleLarge: true, toggleColor: "background-dark-03" }} utils={props.utils} />
        </div>
        <Translate content={`routes.dashboard.${props.sectionName}.description`} component="p" className="text subtitle-3" />
    </>
);

export const ModuleDisabled: React.FC = () => (
    <div className="module-disabled p-5">
        <h1 className="text icon">
            <BiInfoCircle />
        </h1>
        <Translate content="routes.dashboard.module_disabled" component="p" className="text subtitle-3" />
    </div>
);
