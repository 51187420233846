import React from "react";
import { BiMinus, BiPlus } from "react-icons/bi";

const Incrementor: React.FC<{ className?: string; value: number; min: number; max: number; target: Function }> = ({ value, min, max, target, className }) => {
    const increment = React.useState(value);
    React.useEffect(() => increment[1](value), [value]); // eslint-disable-line
    return (
        <div className={`incrementor ${className}`}>
            <button
                className={`left ${increment[0] <= min ? "disabled" : ""}`}
                onClick={(value: any) => {
                    if (increment[0] <= min) return;
                    target("-");
                    value.target.parentElement.getElementsByClassName("number-input")[0].value = increment[0] - 1;
                    increment[1](increment[0] - 1);
                }}>
                <BiMinus className="icon" />
            </button>
            <input
                type="number"
                className="text-center number-input"
                value={value}
                onChange={(value) => {
                    const number = parseInt(value.target.value);
                    if (number > max) value.target.value = max.toString();
                    else if (number < min) value.target.value = min.toString();
                    else if (!number) value.target.value = min.toString();
                    target(parseInt(value.target.value));
                    increment[1](parseInt(value.target.value));
                }}
            />
            <button
                className={`right ${increment[0] >= max ? "disabled" : ""}`}
                onClick={(value: any) => {
                    if (increment[0] >= max) return;
                    target("+");
                    value.target.parentElement.getElementsByClassName("number-input")[0].value = increment[0] + 1;
                    increment[1](increment[0] + 1);
                }}>
                <BiPlus className="icon" />
            </button>
        </div>
    );
};

export default Incrementor;
