import React from "react";
import Translate from "react-translate-component";
import { BiRocket } from "react-icons/bi";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import { DataType } from "@route@::@notfound:hook";

const Section: React.FC<{ data: DataType; utils: any }> = ({ utils }) => {
    return (
        <section className="content-area">
            <AppComponent.BackgroundObjects totalObjects={10} positionLimit={[-10, 110]} sizeLimit={[10, 800]} />
            <div className="relative container mx-auto mb-20">
                <h1 className="text title-404 text-center mt-20 text-glitch drop-shadow-lg" children="404" data-text="404" />
                <h1 className="relative text title-5 text-center italic drop-shadow-lg" children={<Translate content="routes.notfound.title" />} />
                <p className="text subtitle-3 text-center font-thin italic drop-shadow-md" children={<Translate content={`routes.notfound.message${Utility.RandomInt(0, 4)}`} />} />
                <div className="button flex justify-center mt-6">
                    <button className="hover01 color-success" onClick={() => utils.use.Navigate("/")}>
                        <span className="button-icon both">
                            <BiRocket />
                        </span>
                        <Translate content="routes.notfound.return" className="button-text" />
                    </button>
                </div>
            </div>
        </section>
    );
};

const Components = { Section };

export default Components;
