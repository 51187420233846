import React from "react";
import Dot from "dot-object";
import Translate from "react-translate-component";
import { BiCheck, BiCrown, BiEdit, BiInfoCircle, BiPlus, BiReset, BiSave, BiX } from "react-icons/bi";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Select from "react-select";

import AppData from "@app@::@data";
import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import DashboardComponent from "./index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "cyclicquestions";
    const sectionPath = "modules.cyclicQuestions";
    const sectionData = Dot.pick(sectionPath, config.data.current);

    const questionPopup = React.useState<boolean>(false);
    const getPremiumPopup = React.useState<boolean>(false);
    const getPremiumPopupElement = React.useState<any>();
    const questionData = React.useState<any>();
    const ee = React.useState<any[]>();

    return (
        <>
            <section className="dashboard-section">
                <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
                <div className="container mx-auto mb-20 form">
                    {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                    {sectionData.active && (
                        <>
                            <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 mb-5">
                                <Translate content="words.settings" component="h1" className="text title-8" />
                                <div className="form-inputs">
                                    <AppComponent.FormInput type="select" htmlFor="channel" label={utils.translation.words.channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.questionChannel], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.questionChannel", data: value ? value.value : null })} tooltip={utils.translation.routes.dashboard.cyclicquestions.channel_i} additionalProps={{ isClearable: true }} utils={utils} />
                                    <AppComponent.FormInput type="select" htmlFor="reminder-channel" label={utils.translation.words.reminder_channel} placeholder={utils.translation.words.select_channel} tooltip={utils.translation.routes.dashboard.cyclicquestions.reminder_channel_i} value={Utility.GuildDataFilterIgnore([sectionData.settings.reminderChannel], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.reminderChannel", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                    <AppComponent.FormInput type="select" htmlFor="ping-role" label={utils.translation.words.ping_role} placeholder={utils.translation.words.select_role} tooltip={utils.translation.routes.dashboard.cyclicquestions.ping_role_i} value={Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore([sectionData.settings.pingRole], guild.roles, true))} options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_BOTS"])))} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.pingRole", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                    <AppComponent.FormInput type="toggle-checkbox" htmlFor="use-embeds" label={utils.translation.routes.dashboard.cyclicquestions.use_embeds} value={sectionData.settings.useEmbeds} onChange={(value) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.useEmbeds", data: value.target.checked })} additionalProps={{ toggleColor: "background-dark-03", togglePlacement: "left" }} utils={utils} />
                                    <div className="flex justify-between flex-wrap gap-y-2 gap-x-4">
                                        <AppComponent.FormInput
                                            className="w-fit"
                                            type="button-select"
                                            label={utils.translation.words.mode}
                                            value={sectionData.settings.mode}
                                            options={[
                                                { label: utils.translation.words.normal, value: 0 },
                                                { label: utils.translation.words.threads, value: 1 },
                                            ]}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.mode", data: value.value })}
                                            additionalProps={{ checkPlacement: "left" }}
                                            utils={utils}
                                        />
                                        <AppComponent.FormInput
                                            className="w-fit"
                                            type="button-select"
                                            label={utils.translation.words.order}
                                            value={sectionData.settings.order}
                                            options={[
                                                { label: utils.translation.words.random, value: 0 },
                                                { label: utils.translation.words.latest, value: 1 },
                                                { label: utils.translation.words.oldest, value: 2 },
                                            ]}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.order", data: value.value })}
                                            additionalProps={{ checkPlacement: "left" }}
                                            utils={utils}
                                        />
                                        <AppComponent.FormInput
                                            className="w-fit"
                                            type="button-select"
                                            label={utils.translation.words.frequency}
                                            value={sectionData.settings.frequency}
                                            options={[
                                                { label: utils.translation.words.semi_daily, value: 0 },
                                                { label: utils.translation.words.daily, value: 1 },
                                                { label: utils.translation.words.weekly, value: 2 },
                                            ]}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.frequency", data: value.value })}
                                            additionalProps={{ checkPlacement: "left" }}
                                            utils={utils}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="background-02 button w-full rounded-[8px] p-2 mb-5">
                                <button
                                    className={`hover03 ${sectionData.data.questions.length >= 10 && !config.data.previous.settings.premium.status ? "color-gold" : "color-success"} full ${sectionData.data.questions.length >= 300 ? "disabled" : ""}`}
                                    onClick={() => {
                                        if (sectionData.data.questions.length >= 300) return;
                                        else if (sectionData.data.questions.length >= 10 && !config.data.previous.settings.premium.status) {
                                            getPremiumPopup[1](true);
                                            getPremiumPopupElement[1](<Translate content="routes.dashboard.cyclicquestions.max_questions_premium" className="text subtitle-3 center white-lock" />);
                                            return;
                                        }
                                        questionData[1]({ content: "", attachments: [] });
                                        questionPopup[1](true);
                                    }}>
                                    <Translate content="words.add_new" className="button-text both" />
                                    <span className="button-icon">{sectionData.data.questions.length >= 10 && !config.data.previous.settings.premium.status ? <BiCrown /> : <BiPlus />}</span>
                                </button>
                            </div>
                            <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 mb-5">
                                <div className="flex justify-between items-center flex-small-screen">
                                    <Translate content="routes.dashboard.cyclicquestions.queued" component="h1" className="text title-8" />
                                    <div className="flex gap-1">
                                        {sectionData.settings.questionChannel && sectionData.data.questions.length > 0 && (
                                            <>
                                                <Translate content="routes.dashboard.cyclicquestions.next" component="div" className="background-01 text px-2 py-1 rounded-[5px]" />
                                                <div className="background-01 text px-2 py-1 rounded-[5px]">{sectionData.settings.nextQuestionTimestamp > Date.now() ? Utility.FormatDuration(sectionData.settings.nextQuestionTimestamp - Date.now(), 2) : <Translate content="words.please_reset" />}</div>
                                                <div className="btn-danger text rounded-[5px] text-[25px] flex justify-center items-center w-[32px] h-[32px]" onClick={() => config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.nextQuestionTimestamp", data: Utility.GetNext12OClockTimestamp() })}>
                                                    <BiReset />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <DragDropContext
                                    onDragEnd={(result) => {
                                        if (!result.destination) return;
                                        const list = [...sectionData.data.questions];
                                        const [recorded] = list.splice(result.source.index, 1);
                                        list.splice(result.destination.index, 0, recorded);
                                        config.dispatch({ type: "update", path: "modules.cyclicQuestions.data.questions", data: list });
                                    }}>
                                    <Droppable droppableId="questions" direction={"vertical"}>
                                        {(provided) => (
                                            <div className="grid gap-3 mt-3" {...provided.droppableProps} ref={provided.innerRef}>
                                                {sectionData.data.questions.length > 0 ? (
                                                    [...sectionData.data.questions].map((question: any, index: any) => (
                                                        <Draggable key={index} draggableId={`question-${index}`} index={index}>
                                                            {(provided) => (
                                                                <div key={index} className="cyclicquestions-question background-03 w-full rounded-[8px] p-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <span className="text subtitle-4 break-all">{Utility.TrimString(question.content, 100)}</span>
                                                                    <div className="buttons">
                                                                        <span
                                                                            className="edit"
                                                                            onClick={() => {
                                                                                questionData[1]({ ...question, index });
                                                                                questionPopup[1](true);
                                                                            }}>
                                                                            <BiEdit />
                                                                        </span>
                                                                        <span
                                                                            className="delete"
                                                                            onClick={() => {
                                                                                const list = [...sectionData.data.questions];
                                                                                list.splice(index, 1);
                                                                                config.dispatch({ type: "update", path: "modules.cyclicQuestions.data.questions", data: list });
                                                                            }}>
                                                                            <BiX />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                ) : (
                                                    <div className="background-03 w-full rounded-[8px] p-2">
                                                        <Translate content="routes.dashboard.cyclicquestions.queue_empty" component="h1" className="text subtitle-4 text-center" />
                                                    </div>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </>
                    )}
                </div>
            </section>
            {questionData[0] && (
                <AppComponent.PopUp id="QuestionPopupPopup" trigger={questionPopup} closeFull close utils={utils}>
                    <Translate content="words.question" component="h1" className="text title-8" />
                    <div className="form mb-2">
                        <AppComponent.FormInput type="textarea" placeholder={utils.translation.routes.dashboard.cyclicquestions.placeholder} label={utils.translation.words.message} htmlFor="question" value={questionData[0].content} onChange={(value) => questionData[1]({ ...questionData[0], content: value.target.value })} additionalProps={{ maxLength: 1900 }} utils={utils} />
                    </div>
                    <div className="discord-bg rounded-[4px]">
                        <AppComponent.DiscordMessagePreviewer guild={guild} message={{ author: { username: "Titan-Bot", bot: true, botVerified: true, avatarUrl: AppData.project.client.botAvatar }, ...(sectionData.settings.useEmbeds ? { content: questionData[0].content ? (sectionData.settings.pingRole ? `<@&${sectionData.settings.pingRole}>` : "") : `\` ${utils.translation.routes.dashboard.cyclicquestions.start_typing}... \``, embeds: questionData[0].content ? [{ description: questionData[0].content }] : [] } : { content: questionData[0].content ? (sectionData.settings.pingRole ? `<@&${sectionData.settings.pingRole}>\n\n` : "") + questionData[0].content : `\` ${utils.translation.routes.dashboard.cyclicquestions.start_typing}... \`` }) }} utils={utils} />
                    </div>
                    <div className="button flex justify-between mt-4">
                        <button className="hover02 color-secondary" onClick={() => questionPopup[1](false)}>
                            <Translate content="words.cancel" className="button-text" />
                        </button>
                        <button
                            className={`hover02 color-success ${questionData[0].content ? "" : "disabled"}`}
                            onClick={() => {
                                if (!questionData[0].content) return;
                                if (sectionData.data.questions.find((a: any) => a.content.toLowerCase() === questionData[0].content.toLowerCase())) return toast(<Translate content="routes.dashboard.cyclicquestions.already_added" />, { type: "error", autoClose: 3000 });
                                if (sectionData.data.questions.length >= 300) {
                                    questionPopup[1](false);
                                    return;
                                } else if (sectionData.data.questions.length >= 10 && !config.data.previous.settings.premium.status) {
                                    questionPopup[1](false);
                                    getPremiumPopup[1](true);
                                    getPremiumPopupElement[1](<Translate content="routes.dashboard.cyclicquestions.max_questions_premium" className="text subtitle-3 center white-lock" />);
                                    return;
                                }
                                const list = [...sectionData.data.questions];
                                if (questionData[0].index !== undefined) {
                                    list.splice(questionData[0].index, 1, { content: questionData[0].content, attachments: questionData[0].attachments });
                                    questionData[1]({ content: "", attachments: [] });
                                    questionPopup[1](false);
                                } else {
                                    list.splice(0, 0, { content: questionData[0].content, attachments: questionData[0].attachments });
                                    questionData[1]({ content: "", attachments: [] });
                                    toast(<Translate content="routes.dashboard.cyclicquestions.added" />, { type: "success", autoClose: 3000 });
                                }
                                config.dispatch({ type: "update", path: "modules.cyclicQuestions.data.questions", data: list });
                                if (sectionData.settings.nextQuestionTimestamp === 0) config.dispatch({ type: "update", path: "modules.cyclicQuestions.settings.nextQuestionTimestamp", data: Utility.GetNext12OClockTimestamp() });
                            }}>
                            {questionData[0].index !== undefined ? (
                                <>
                                    <Translate content="words.edit" className="button-text both" />
                                    <span className="button-icon">
                                        <BiSave />
                                    </span>
                                </>
                            ) : (
                                <>
                                    <Translate content="words.add" className="button-text both" />
                                    <span className="button-icon">
                                        <BiPlus />
                                    </span>
                                </>
                            )}
                        </button>
                    </div>
                </AppComponent.PopUp>
            )}
            <AppComponent.PopUp background="premium-bg" id="AddNewSelfRolePopup" trigger={getPremiumPopup} closeFull close utils={utils}>
                <span className="noselect w-full">
                    <Translate content="words.server_premium_feature" component="h1" className="text title-6 text-center white-lock" />
                    {getPremiumPopupElement[0]}
                    <div className="button flex justify-center mt-6">
                        <button className="hover02 color-secondary shadow bg-gradient-to-r from-[#d397fa] to-[#8364e8] disabled" onClick={() => {}}>
                            <Translate content="routes.profile.subscription.get_server_premium" className="button-text" />
                        </button>
                    </div>
                </span>
            </AppComponent.PopUp>
        </>
    );
};

export default Section;
