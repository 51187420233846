// const List = {
// ver: "1.2.0",
//     devMode: false,
//     backendDomain: "https://api.titanbot.net",
//     serverInvite: "https://discord.com/invite/j5pkCEff8P",
//     stripe: {
//         key: "pk_test_51L82ANFXcvUbiSMLdB2GLcRz0yCQCFE0w2U0uqSOyB7Es1PV3GLGM0bfZsgReGH7oj2sViRloLViI0MW6IQsKdFU003UUtZScV",
//     },
//     client: {
//         id: "745849628825747458",
//         invite: "https://discord.com/oauth2/authorize?scope=bot+applications.commands&response_type=code&approval_prompt=auto&disable_guild_select=true&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdashboard&permissions=1945627743&client_id={clientId}&guild_id={guildId}",
//         invite2: "https://discord.com/oauth2/authorize?scope=bot+applications.commands&response_type=code&approval_prompt=auto&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdashboard&permissions=1945627743&client_id={clientId}",
//         guildIcon: "https://cdn.discordapp.com/icons/{id}/{icon}.webp",
//         userAvatar: "https://cdn.discordapp.com/avatars/{id}/{avatar}.webp",
//         memberAvatar: "https://cdn.discordapp.com/guilds/{guildId}/users/{userId}/avatars/{avatar}.webp",
//         emojiImage: "https://cdn.discordapp.com/emojis",
//         botAvatar: "https://media.discordapp.net/attachments/845764947928416297/1075293694791524412/Titan_Beans.png",
//     },
// };

const devMode = false;

const List = {
    ver: `1.2.5${devMode ? "-dev" : ""}`,
    devMode,
    backendDomain: devMode ? "http://localhost:3001" : "https://api.titanbot.net",
    serverInvite: "https://discord.com/invite/j5pkCEff8P",
    stripe: {
        key: "pk_test_51PJ2SJC1R82HZaYWwn6FPlBWViDMqD24n5mgJx0unWllLQpbZNJRz0kDF5I33Cnf5bFZPsxo0KzColAhLlb774iv00Uq72ZXTJ",
        prices: {
            reoccurring: {
                price_1MmskjFXcvUbiSMLdugGedZH: {
                    price: "10",
                    type: "month",
                    oppositePlan: "price_1MmskjFXcvUbiSMLbxftxu3E",
                },
                price_1MmskjFXcvUbiSMLbxftxu3E: {
                    price: "99.60",
                    type: "year",
                    oppositePlan: "price_1MmskjFXcvUbiSMLdugGedZH",
                },
                price_1L9MH5FXcvUbiSMLg9dCMgUx: {
                    price: "5",
                    type: "month",
                    oppositePlan: "price_1L9MH5FXcvUbiSMLZFKbcEZV",
                },
                price_1L9MH5FXcvUbiSMLZFKbcEZV: {
                    price: "49.80",
                    type: "year",
                    oppositePlan: "price_1L9MH5FXcvUbiSMLg9dCMgUx",
                },
            },
            onetime: {},
        },
    },
    client: {
        id: devMode ? "745123015762640896" : "745849628825747458",
        invite: `https://discord.com/oauth2/authorize?scope=bot+applications.commands&response_type=code&approval_prompt=auto&disable_guild_select=true&redirect_uri=${devMode ? "http%3A%2F%2Flocalhost%3A3000%2Fdashboard" : "https%3A%2F%2Fwww.titanbot.net%2Fdashboard"}&permissions=1945627743&client_id={clientId}&guild_id={guildId}`,
        invite2: `https://discord.com/oauth2/authorize?scope=bot+applications.commands&response_type=code&approval_prompt=auto&response_type=code&redirect_uri=${devMode ? "http%3A%2F%2Flocalhost%3A3000%2Fdashboard" : "https%3A%2F%2Fwww.titanbot.net%2Fdashboard"}&permissions=1945627743&client_id={clientId}`,
        emojiImage: "https://cdn.discordapp.com/emojis",
        botAvatar: "https://media.discordapp.net/attachments/845764947928416297/1075293694791524412/Titan_Beans.png",
    },
};

export default List;
