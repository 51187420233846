import * as ArrayUtility from "@utility@::/utils/array.utility";
import * as NumberUtility from "@utility@::/utils/number.utility";
import * as StringUtility from "@utility@::/utils/string.utility";
import * as FormUtility from "@utility@::/utils/form.utility";

const List = {
    ...ArrayUtility,
    ...NumberUtility,
    ...StringUtility,
    ...FormUtility,
};

export default List;
