import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import AppComponent from "@app@::@component";

import CommandsComponent from "@route@::@commands:component";
import CommandsHook from "@route@::@commands:hook";

import Utility from "@utility@::@index";

const Path = "/commands";
const Options = { caseSensitive: true };

const Commands: React.FC<PageProps> = ({ utils }) => {
    let activeSection = utils.use.SearchParams[0].get("section") as string; // Active page section

    const locale = Cookies.get("language") || "en";
    const { loading, data } = CommandsHook.Query(locale);

    // When section has been updated
    useEffect(() => {
        if (!["actions", "fun", "music", "moderation", "profile", "economy", "utility", "config", "bot"].includes(activeSection)) Utility.Wait(500).then(() => utils.use.SearchParams[1]({ section: "actions" }));
    }, [activeSection, utils.use.SearchParams]);

    if (loading) return <AppComponent.Loading />; // Return loading component if loading is active
    else if (!data) return utils.use.Navigate("/"); // Redirects to the home page if theirs no data

    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body">
                <Helmet>
                    <title>Titan-Bot – Commands</title>
                    <meta name="description" content="View all of Titan-Bot's cool and fun commands, as well as their details and how to use them!" />
                </Helmet>
                <AppComponent.Animation.ScreenCover />
                <AppComponent.Header utils={utils} path={Path} />
                <AppComponent.ProfileMenu utils={utils} profile={data.profile} path={Path} />
                <CommandsComponent.Section utils={utils} activeSection={activeSection} data={data} locale={locale} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: Commands, Path, Options };

export default Page;
