import React, { useRef } from "react";
import { ChromePicker } from "react-color";

const ColoPicker: React.FC<{ element?: any; active: boolean; color?: string; onClose?: any; onChange?: any; utils: any }> = ({ element, active, color, onClose, onChange, utils }) => {
    const ref = useRef<any>();

    utils.use.ClickOutside(onClose, ref);

    if (!active) return element || <></>;
    return (
        <span ref={ref}>
            {element}
            <div
                style={{
                    position: "relative",
                    zIndex: "2",
                }}>
                <ChromePicker
                    disableAlpha
                    color={color}
                    onChange={onChange}
                    styles={{
                        default: {
                            picker: { position: "absolute", top: "10px", width: "100%", fontSize: "1rem", background: "#171c26", color: "#fff !important" },
                            hue: {
                                height: "20px",
                            },
                            color: {
                                display: "none",
                            },
                        },
                    }}
                />
            </div>
        </span>
    );
};

export default ColoPicker;
