import React from "react";
import Translate from "react-translate-component";
import { BiLogInCircle } from "react-icons/bi";

import AppData from "@app@::@data";

const Login: React.FC = () => {
    return (
        <div className="container-default mx-auto my-20 flex flex-col justify-center align-middle h-full">
            <h1 className="text title-5 text-center">ヾ(•ω•`)o</h1>
            <Translate content="components.login.desc" component="p" className="text subtitle-3 text-center" />
            <div className="button flex justify-center">
                <button className="hover01 color-success mt-5" onClick={() => (window.location.href = `${AppData.project.backendDomain}/api/auth/login`)}>
                    <span className="button-icon both">
                        <BiLogInCircle />
                    </span>
                    <Translate content="components.login.b" component="span" className="button-text" />
                </button>
            </div>
        </div>
    );
};

export default Login;
