import { Section } from "./section";
import { Sidebar } from "./sidebar";
import { Savebar } from "./savebar";

import HomeSection from "./home";
import LeaderboardSection from "./leaderboard";
import SettingsSection from "./settings";
import CoreSection from "./core";

import ModerationSection from "./moderation";
import AutoModSection from "./automod";
import LoggingSection from "./logging";

import ChatbotSection from "./chatbot";
import CountingSection from "./counting";
import CyclicQuestionsSection from "./cyclicquestions";

import GreeterSection from "./greeter";
import SelfRoleSection from "./selfrole";
import LevelingSection from "./leveling";
import AutoRoleSection from "./autorole";
import AutoPublishSection from "./autopublish";

import * as Other from "./other";

const components = {
    Section,
    Sidebar,
    Savebar,
    HomeSection,
    LeaderboardSection,
    SettingsSection,
    CoreSection,
    ModerationSection,
    AutoModSection,
    LoggingSection,
    ChatbotSection,
    CountingSection,
    CyclicQuestionsSection,
    GreeterSection,
    SelfRoleSection,
    LevelingSection,
    AutoRoleSection,
    AutoPublishSection,
    ...Other,
};

export default components;
