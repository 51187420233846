import React from "react";
import Translate from "react-translate-component";
import Select from "react-select";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const managerRoles = Utility.DiscordRolesToDropdownOptions(extra.roleOptions((role: any) => !role.managed && ((role.permissions & 0x8) === 0x8 || (role.permissions & 0x20) === 0x20))).map((a) => ({ ...a, isFixed: true }));
    return (
        <section className="dashboard-section">
            <div className="container mx-auto mb-[0.35rem] mt-[.30rem]">
                <Translate content="routes.dashboard.moderation.name" component="span" className="text title-6-5" />
                <Translate content="routes.dashboard.moderation.description" component="p" className="text subtitle-3" />
            </div>
            <div className="container mx-auto mb-20">
                <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 mb-5 form">
                    <Translate content="words.settings" component="h1" className="text title-8" />
                    <Translate content="routes.dashboard.moderation.perm_settings_i" component="h1" className="text subtitle-4" />
                    <AppComponent.FormInput type="select" disabled htmlFor="manager-roles" label={utils.translation.routes.dashboard.moderation.manager_roles} value={managerRoles} placeholder={utils.translation.words.select_roles} additionalProps={{ isMulti: true, closeMenuOnSelect: false, isClearable: managerRoles.some((v) => !v.isFixed) }} utils={utils} />
                </div>
            </div>
        </section>
    );
};

export default Section;
