import Lodash from "lodash";
import Dot from "dot-object";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

// Config data reducer
const ConfigDataReducer = (state: DashboardInterface.ConfigData, action: { type: string; path?: string; data?: any }): any => {
    let config = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "initilize": {
            if (!action.data) break;
            Dot.set("id", action.data._id, config);
            Dot.set("previous", action.data, config);
            Dot.set("current", action.data, config);
            Dot.set("loading", false, config);
            break;
        }
        case "reset": {
            Dot.set("current", state.previous, config);
            break;
        }
        case "update": {
            Dot.set("current." + action.path, action.data, config);
            break;
        }
        case "delete": {
            Dot.delete("current." + action.path, config);
            break;
        }
        case "save": {
            Dot.set("loading", true, config);
            Dot.keepArray = true;

            if (Lodash.isEqual(config.previous, config.current)) break; // Checks if any data has been changed

            // Main vars
            const buttons = document.querySelectorAll(".save-bar-button");
            const previousConfigData = JSON.parse(JSON.stringify(config.previous));
            const currentConfigData = JSON.parse(JSON.stringify(config.current));
            const changesConfigData = {};
            const paths = Object.keys(Dot.dot(config.current)).filter((key) => !key.includes("__typename"));

            buttons.forEach((button) => button.classList.toggle("loading-button")); // Disables save/cancel buttons

            for (let path of paths) if (!Lodash.isEqual(Dot.pick(path, currentConfigData), Dot.pick(path, previousConfigData))) Dot.copy(path, path, currentConfigData, changesConfigData);

            action.data.update({ variables: { id: config.id, config: JSON.stringify({ changes: changesConfigData, guild: action.data.guild }), type: "default" } }).then(() =>
                action.data.refetch().then(() => {
                    Dot.set("loading", false, config);
                    buttons.forEach((button) => button.classList.toggle("loading-button"));
                })
            ); // Updates changes
            break;
        }
        case "format": {
            Dot.set("loading", true, config);
            action.data.update({ variables: { id: config.id, config: "{}", type: "format" } }).then(() => action.data.refetch().then(() => Dot.set("loading", false, config))); // Updates changes
            break;
        }
        case "loading": {
            Dot.set("loading", action.data, config);
            break;
        }
        default:
            break;
    }
    return config;
};

// Inits the  sidebar
const InitializeSidebar = (activeSection: string) => {
    const body = document.querySelector("body") as HTMLBodyElement,
        sidebar = body.querySelector(".sidebar"),
        toggle = body.querySelector(".sidebar-toggle");

    sidebar?.querySelector(".nav-button#dashboard-" + activeSection)?.classList.add("active");

    toggle?.addEventListener("click", (event) => {
        sidebar?.classList.toggle("closed");
    });

    if (window.screen.width > 1000) sidebar?.classList.remove("closed");
};

// Updates the modules on the sidebar
const UpdateSidebarModules = (data: any) => {
    const navLinks = document.querySelectorAll(".nav-link");
    navLinks.forEach((element) => {
        element.classList.remove("active");
        if (element.firstElementChild?.id === "dashboard-moderation" && false) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-automod" && data.current.modules.autoMod.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-logging" && data.current.modules.logging.active) element.classList.add("active");

        if (element.firstElementChild?.id === "dashboard-chatbot" && data.current.modules.chatBot.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-counting" && data.current.modules.counting.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-cyclicquestions" && data.current.modules.cyclicQuestions.active) element.classList.add("active");

        if (element.firstElementChild?.id === "dashboard-greeter" && data.current.modules.greeter.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-selfrole" && data.current.modules.selfRole.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-leveling" && data.current.modules.leveling.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-autorole" && data.current.modules.autoRole.active) element.classList.add("active");
        if (element.firstElementChild?.id === "dashboard-autopublish" && data.current.modules.autoPublish.active) element.classList.add("active");
    });
};

// Changes the current active section
const ChangeSection = (id: string, activeSection: React.Dispatch<any>) => {
    document.querySelectorAll(".nav-button.active").forEach((a) => a.classList.remove("active"));
    activeSection({ section: id.split("-")[1] });
    document.querySelector(".nav-button#" + id)?.classList.add("active");
    if (window.screen.width <= 1000) document.querySelector(".sidebar")?.classList.add("closed");
};

// Checks if any data has been changed
const CheckConfigData = (ConfigData: any, hasChanges: boolean) => {
    const saveBar = document.querySelector(".save-bar");
    if (Lodash.isEqual(ConfigData.previous, ConfigData.current)) {
        saveBar?.classList.add("closed");
        return false;
    }
    saveBar?.classList.remove("closed");
    return true;
};

const Functions = { ConfigDataReducer, InitializeSidebar, UpdateSidebarModules, ChangeSection, CheckConfigData };

export default Functions;
