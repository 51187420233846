import { gql as GraphQL, useQuery, ApolloError } from "@apollo/client";

export interface DataType {
    profile: IProfile;
    commands: IClientCommand[];
}

const QueryGraphQL = GraphQL`
    query getServerPageQuery($locale: String!) {
        getProfile {
            _id
            settings {
                user {
                    username
                    discriminator
                    avatar
                }
            }
        }

        getCommand(locale: $locale) {
            id
            slashType
            type
            name
            description
            group
            settings {
                aliases
                permissions
                clientPermissions
                active
                guild
                premium
                nsfw
                usage
                usageExample
                options
            }
        }
    }
`;

const Query = (locale: string): { loading: boolean; error: ApolloError | undefined; data: DataType | null; refetch: Function } => {
    const { loading, error, data, refetch } = useQuery(QueryGraphQL, { variables: { locale } });

    if (error) console.log(error);

    if (loading || error) return { loading: true, error, data: null, refetch };

    return { loading: false, error, data: { profile: data.getProfile, commands: data.getCommand }, refetch };
};

const Hook = { Query };

export default Hook;
