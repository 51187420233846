import { ApolloError, gql as GraphQL, useQuery } from "@apollo/client";

export interface DataType {
    client: any;
    profile: IProfile;
}

const QueryGraphQL = GraphQL`
    query getServerPageQuery {
        getClient {
            data {
                totalServers
                totalUsers
                totalCommandsExecuted
            }
        }
        getProfile {
            _id
            settings {
                user {
                    username
                    discriminator
                    avatar
                }
            }
        }
    }
`;

const Query = (): { loading: boolean; error: ApolloError | undefined; data: DataType | null; refetch: Function } => {
    const { loading, error, data, refetch } = useQuery(QueryGraphQL);

    if (error) console.log(error);

    if (loading || error) return { loading: true, error, data: null, refetch };

    return { loading: false, error, data: { client: data.getClient, profile: data.getProfile }, refetch };
};
const Hook = { Query };

export default Hook;
