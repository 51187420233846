export const RandomFloat = (min: number, max: number): number => (max >= min ? Math.random() * (max - min) + min : RandomFloat(max, min));

export const RandomInt = (min: number, max: number): number => Math.round(RandomFloat(min, max));

export const RandomIntTwo = (x: number, a: number) => Math.round(Math.pow(Math.random() * Math.pow(x, a), 1 / a));

export const Wait = (ms: number): Promise<void> => new Promise<void>((resolve) => setTimeout(resolve, ms));

export const Range = (val: number, min: number, max: number): number => Math.min(Math.max(val, min), max);

export const FormatNumber = (number: number): string => number + (["th", "th", "th"][number - 11] || ["st", "nd", "rd"][((number > 0 ? number : -number) % 10) - 1] || "th");

export const AbbreviateNumber = (value: number): string => {
    let newValue: any = value;
    if (value >= 1000) {
        let suffixes = ["", "k", "m", "b", "t"];
        let suffixNum = Math.floor(("" + value).length / 3);
        let shortValue: any = "";
        for (let precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
            let dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
};

export const LeadingZero = (num: number, size: number): string => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
};

export const ConvertMsToTime = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    return `${LeadingZero(hours, 2)}:${LeadingZero(minutes, 2)}:${LeadingZero(seconds, 2)}`;
};

export function ConvertColor(hexa: any) {
    let chunks = [];
    let tmp, i;
    hexa = hexa.substr(1); // remove the pound
    if (hexa.length === 3) {
        tmp = hexa.split("");
        for (i = 0; i < 3; i++) {
            chunks.push(parseInt(tmp[i] + "" + tmp[i], 16));
        }
    } else if (hexa.length === 6) {
        tmp = hexa.match(/.{2}/g);
        for (i = 0; i < 3; i++) {
            chunks.push(parseInt(tmp[i], 16));
        }
    } else {
        throw new Error("'" + hexa + "' is not a valid hex format");
    }

    return chunks;
}

const MAX_LEVEL = 8196;

const serverLevelXPRequirements = new Uint32Array(Array.from({ length: MAX_LEVEL + 1 }, (_, i) => 100 * Math.floor(Math.pow(i, 1.93753063169586))));
export const GetServerLevelXPRequirement = (level: number): number => serverLevelXPRequirements[level];

export const GetServerLevelFromXP = (xp: number): number => {
    let left = 0,
        right = MAX_LEVEL,
        middle,
        middleXPRequirement,
        nextToMiddleXPRequirement;
    do {
        middle = Math.floor((left + right) / 2);
        middleXPRequirement = serverLevelXPRequirements[middle];
        if (xp < middleXPRequirement) {
            right = middle;
            continue;
        }
        nextToMiddleXPRequirement = serverLevelXPRequirements[middle + 1];
        if (xp < nextToMiddleXPRequirement) return middle;
        left = middle;
    } while (left < right);
    return middle;
};

const globalLevelXPRequirements = new Uint32Array(Array.from({ length: MAX_LEVEL + 1 }, (_, i) => 100 * Math.floor(Math.pow(i, 2.08804562953))));
export const GetGlobalLevelXPRequirement = (level: number): number => globalLevelXPRequirements[level];

export const GetGlobalLevelFromXP = (xp: number): number => {
    let left = 0,
        right = MAX_LEVEL,
        middle,
        middleXPRequirement,
        nextToMiddleXPRequirement;
    do {
        middle = Math.floor((left + right) / 2);
        middleXPRequirement = globalLevelXPRequirements[middle];
        if (xp < middleXPRequirement) {
            right = middle;
            continue;
        }
        nextToMiddleXPRequirement = globalLevelXPRequirements[middle + 1];
        if (xp < nextToMiddleXPRequirement) return middle;
        left = middle;
    } while (left < right);
    return middle;
};

export const CalculateExpectedTimeToReachXP = (targetXp: number, minXp: number, maxXp: number, xpRate: number): number => {
    const XP_PER_MINUTE_EXPECTED = ((minXp + maxXp) * xpRate) / 2;
    const MS_PER_MINUTE = 60000; // 1 minute = 60,000 ms
    const expectedTimeInMinutes = targetXp / XP_PER_MINUTE_EXPECTED;
    const expectedTimeInMs = expectedTimeInMinutes * MS_PER_MINUTE;
    return expectedTimeInMs;
};

export const FormatDuration = (durationInMs: number, stopAt: number = 7): string => {
    const MS_PER_SECOND = 1000;
    const MS_PER_MINUTE = MS_PER_SECOND * 60;
    const MS_PER_HOUR = MS_PER_MINUTE * 60;
    const MS_PER_DAY = MS_PER_HOUR * 24;
    const MS_PER_WEEK = MS_PER_DAY * 7;
    const MS_PER_MONTH = MS_PER_DAY * 30; // assuming 30 days per month
    const MS_PER_YEAR = MS_PER_DAY * 365; // assuming 365 days per year

    const years = Math.floor(durationInMs / MS_PER_YEAR);
    durationInMs = durationInMs % MS_PER_YEAR;

    const months = Math.floor(durationInMs / MS_PER_MONTH);
    durationInMs = durationInMs % MS_PER_MONTH;

    const weeks = Math.floor(durationInMs / MS_PER_WEEK);
    durationInMs = durationInMs % MS_PER_WEEK;

    const days = Math.floor(durationInMs / MS_PER_DAY);
    durationInMs = durationInMs % MS_PER_DAY;

    const hours = Math.floor(durationInMs / MS_PER_HOUR);
    durationInMs = durationInMs % MS_PER_HOUR;

    const minutes = Math.floor(durationInMs / MS_PER_MINUTE);
    durationInMs = durationInMs % MS_PER_MINUTE;

    const seconds = Math.floor(durationInMs / MS_PER_SECOND);

    let durationString = "";
    let i = 1;
    if (years > 0 && i <= stopAt) {
        durationString += `${years} year${years > 1 ? "s" : ""} `;
        i++;
    }
    if (months > 0 && i <= stopAt) {
        durationString += `${months} month${months > 1 ? "s" : ""} `;
        i++;
    }
    if (weeks > 0 && i <= stopAt) {
        durationString += `${weeks} week${weeks > 1 ? "s" : ""} `;
        i++;
    }
    if (days > 0 && i <= stopAt) {
        durationString += `${days} day${days > 1 ? "s" : ""} `;
        i++;
    }
    if (hours > 0 && i <= stopAt) {
        durationString += `${hours} hour${hours > 1 ? "s" : ""} `;
        i++;
    }
    if (minutes > 0 && i <= stopAt) {
        durationString += `${minutes} minute${minutes > 1 ? "s" : ""} `;
        i++;
    }
    if (seconds > 0 && i <= stopAt) {
        durationString += `${seconds} second${seconds > 1 ? "s" : ""} `;
        i++;
    }
    if (durationString === "") {
        durationString = "0 seconds";
    }
    return durationString;
};

export const GetNext12OClockTimestamp = (): number => {
    const now = new Date(); // Get current date and time
    const next12OClock = new Date(); // Create new date object for next 12 o'clock

    // Set next 12 o'clock time values
    next12OClock.setUTCHours(12);
    next12OClock.setUTCMinutes(0);
    next12OClock.setUTCSeconds(0);
    next12OClock.setUTCMilliseconds(0);

    // If the current time is after 12 o'clock, set next 12 o'clock to tomorrow
    if (now.getUTCHours() >= 12) {
        next12OClock.setUTCDate(next12OClock.getUTCDate() + 1);
    }

    // Calculate the difference between the current time and next 12 o'clock
    const diff = next12OClock.getTime() - now.getTime();

    // Return the timestamp in milliseconds for next 12 o'clock
    return now.getTime() + diff;
};
