import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import AppApollo from "@app@::@apollo";
import AppComponent from "@app@::@component";
import AppData from "@app@::@data";
import AppUtil from "@app@::@util";

import "@app@::@style";

const StripePromise = loadStripe(AppData.project.stripe.key);

const App: React.FC = () => {
    AppUtil.InitializeBody();
    return (
        <ApolloProvider client={AppApollo}>
            <Elements stripe={StripePromise}>
                <Router>
                    <AppComponent.Pages />
                </Router>
            </Elements>
        </ApolloProvider>
    );
};

export default App;
