import React from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";

import Utility from "@utility@::@index";

const TimePicker: React.FC<{ startMs: number; callback: Function }> = ({ startMs, callback }) => {
    const picker = [React.useState<number>(0), React.useState<number>(0), React.useState<number>(0)];
    const max = [24, 60, 60];
    const min = [0, 0, 0];
    React.useEffect(() => {
        const time = Utility.ConvertMsToTime(startMs).split(":");
        picker[0][1](parseInt(time[0]));
        picker[1][1](parseInt(time[1]));
        picker[2][1](parseInt(time[2]));
        (document.getElementsByClassName("input input-hour")[0] as any).value = time[0];
        (document.getElementsByClassName("input input-min")[0] as any).value = time[1];
        (document.getElementsByClassName("input input-sec")[0] as any).value = time[2];
    }, []); // eslint-disable-line
    React.useEffect(() => {
        if (picker[0][0] >= max[0]) {
            picker[1][1](min[1]);
            picker[2][1](min[2]);
            (document.getElementsByClassName("input input-min")[0] as any).value = Utility.LeadingZero(min[1], 2);
            (document.getElementsByClassName("input input-sec")[0] as any).value = Utility.LeadingZero(min[2], 2);
        }
        if (picker[1][0] >= max[1] && picker[0][0] + 1 <= 24) {
            picker[0][1](picker[0][0] + 1);
            (document.getElementsByClassName("input input-hour")[0] as any).value = Utility.LeadingZero(picker[0][0] + 1, 2);
            (document.getElementsByClassName("input input-min")[0] as any).value = Utility.LeadingZero(min[1], 2);
            picker[1][1](min[1]);
        }
        if (picker[2][0] >= max[2] && picker[0][0] + 1 <= 24) {
            picker[1][1](picker[1][0] + 1);
            (document.getElementsByClassName("input input-min")[0] as any).value = Utility.LeadingZero(picker[1][0] + 1, 2);
            (document.getElementsByClassName("input input-sec")[0] as any).value = Utility.LeadingZero(min[2], 2);
            picker[2][1](min[2]);
        }
        callback(picker[0][0] * 60 * 60 * 1000 + picker[1][0] * 60 * 1000 + picker[2][0] * 1000);
    }, [picker[0][0], picker[1][0], picker[2][0]]); // eslint-disable-line
    return (
        <div className="time-picker">
            <div className="box">
                <div
                    className="top"
                    onClick={(element: any) => {
                        const value = picker[0][0] < max[0] ? picker[0][0] + 1 : min[0];
                        (document.getElementsByClassName("input input-hour")[0] as any).value = Utility.LeadingZero(value, 2);
                        picker[0][1](value);
                    }}>
                    <BiChevronUp />
                </div>
                <div className="middle">
                    <input
                        type="number"
                        id="time-picker-input"
                        className="input input-hour"
                        autoComplete="off"
                        placeholder="00"
                        onBlur={(value) => {
                            const number = parseInt(value.target.value);
                            if (number > max[0]) value.target.value = max[0].toString();
                            else if (number < min[0]) value.target.value = min[0].toString();
                            else if (!number) value.target.value = min[0].toString();
                            picker[0][1](parseInt(value.target.value));
                            value.target.value = Utility.LeadingZero(parseInt(value.target.value), 2);
                        }}
                    />
                </div>
                <div
                    className="bottom"
                    onClick={(element: any) => {
                        const value = picker[0][0] > min[0] ? picker[0][0] - 1 : max[0];
                        (document.getElementsByClassName("input input-hour")[0] as any).value = Utility.LeadingZero(value, 2);
                        picker[0][1](value);
                    }}>
                    <BiChevronDown />
                </div>
            </div>
            <div className="box">
                <div
                    className="top"
                    onClick={(element: any) => {
                        const value = picker[1][0] < max[1] ? picker[1][0] + 1 : min[1];
                        (document.getElementsByClassName("input input-min")[0] as any).value = Utility.LeadingZero(value, 2);
                        picker[1][1](value);
                    }}>
                    <BiChevronUp />
                </div>
                <div className="middle">
                    <input
                        type="number"
                        id="time-picker-input"
                        className="input input-min"
                        autoComplete="off"
                        placeholder="00"
                        onBlur={(value) => {
                            const number = parseInt(value.target.value);
                            if (number > max[1]) value.target.value = max[1].toString();
                            else if (number < min[1]) value.target.value = min[1].toString();
                            else if (!number) value.target.value = min[1].toString();
                            picker[1][1](parseInt(value.target.value));
                            value.target.value = Utility.LeadingZero(parseInt(value.target.value), 2);
                        }}
                    />
                </div>
                <div
                    className="bottom"
                    onClick={(element: any) => {
                        const value = picker[1][0] > min[1] ? picker[1][0] - 1 : max[1];
                        (document.getElementsByClassName("input input-min")[0] as any).value = Utility.LeadingZero(value, 2);
                        picker[1][1](value);
                    }}>
                    <BiChevronDown />
                </div>
            </div>
            <div className="box">
                <div
                    className="top"
                    onClick={(element: any) => {
                        const value = picker[2][0] < max[2] ? picker[2][0] + 1 : min[2];
                        (document.getElementsByClassName("input input-sec")[0] as any).value = Utility.LeadingZero(value, 2);
                        picker[2][1](value);
                    }}>
                    <BiChevronUp />
                </div>
                <div className="middle">
                    <input
                        type="number"
                        id="time-picker-input"
                        className="input input-sec"
                        autoComplete="off"
                        placeholder="00"
                        onBlur={(value) => {
                            const number = parseInt(value.target.value);
                            if (number > max[2]) value.target.value = max[2].toString();
                            else if (number < min[2]) value.target.value = min[2].toString();
                            else if (!number) value.target.value = min[2].toString();
                            picker[2][1](parseInt(value.target.value));
                            value.target.value = Utility.LeadingZero(parseInt(value.target.value), 2);
                        }}
                    />
                </div>
                <div
                    className="bottom"
                    onClick={(element: any) => {
                        const value = picker[2][0] > min[2] ? picker[2][0] - 1 : max[2];
                        (document.getElementsByClassName("input input-sec")[0] as any).value = Utility.LeadingZero(value, 2);
                        picker[2][1](value);
                    }}>
                    <BiChevronDown />
                </div>
            </div>
        </div>
    );
};

export default TimePicker;
