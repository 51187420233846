import React from "react";
import { motion } from "framer-motion";

const ScreenCover: React.FC = () => <div className="screen-cover" />;

const PageFade: React.FC<{ children: React.ReactNode }> = (props) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        {props.children}
    </motion.div>
);

const Animation = { ScreenCover, PageFade };

export default Animation;
