import React, { useState } from "react";
import Translate from "react-translate-component";
import { BiAt, BiBulb, BiCheck, BiCheckShield, BiChip, BiCog, BiDollar, BiHappy, BiMusic, BiUser, BiX, BiCircle, BiSquare, BiCrown, BiRightArrowAlt, BiLeftArrowAlt } from "react-icons/bi";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import { DataType } from "@route@::@commands:hook";

const Section: React.FC<{ data: DataType; activeSection: string; utils: any; locale: string }> = ({ data, activeSection, utils, locale }) => {
    const activePopUp = useState<string>("");
    const triggerPopUp = useState<boolean>(false);
    const examplePage = useState<number>(0);
    return (
        <section className="content-area">
            <div className="container mx-auto">
                <div className="text title-4 center-text" children={<Translate content="routes.commands.commands" component="span" />} />
            </div>
            <div className="container mx-auto mb-7">
                <header className="tab-bar">
                    <input className="slider-input" type="radio" name="slider" id="command-actions" checked={activeSection === "actions" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "actions" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-fun" checked={activeSection === "fun" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "fun" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-music" checked={activeSection === "music" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "music" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-moderation" checked={activeSection === "moderation" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "moderation" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-profile" checked={activeSection === "profile" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "profile" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-economy" checked={activeSection === "economy" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "economy" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-utility" checked={activeSection === "utility" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "utility" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-config" checked={activeSection === "config" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "config" })} />
                    <input className="slider-input" type="radio" name="slider" id="command-bot" checked={activeSection === "bot" ? true : false} onChange={() => utils.use.SearchParams[1]({ section: "bot" })} />
                    <nav>
                        <label htmlFor="command-actions" className="command-actions">
                            <span className="button-icon">
                                <BiAt />
                            </span>
                            <Translate content="routes.commands.tab.actions" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-fun" className="command-fun">
                            <span className="button-icon">
                                <BiHappy />
                            </span>
                            <Translate content="routes.commands.tab.fun" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-music" className="command-music">
                            <span className="button-icon">
                                <BiMusic />
                            </span>
                            <Translate content="routes.commands.tab.music" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-moderation" className="command-moderation">
                            <span className="button-icon">
                                <BiCheckShield />
                            </span>
                            <Translate content="routes.commands.tab.moderation" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-profile" className="command-profile">
                            <span className="button-icon">
                                <BiUser />
                            </span>
                            <Translate content="routes.commands.tab.profile" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-economy" className="command-economy">
                            <span className="button-icon">
                                <BiDollar />
                            </span>
                            <Translate content="routes.commands.tab.economy" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-utility" className="command-utility">
                            <span className="button-icon">
                                <BiBulb />
                            </span>
                            <Translate content="routes.commands.tab.utility" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-config" className="command-config">
                            <span className="button-icon">
                                <BiCog />
                            </span>
                            <Translate content="routes.commands.tab.config" component="span" className="button-text" />
                        </label>
                        <label htmlFor="command-bot" className="command-bot">
                            <span className="button-icon">
                                <BiChip />
                            </span>
                            <Translate content="routes.commands.tab.bot" component="span" className="button-text" />
                        </label>
                        <div className="slider"></div>
                    </nav>
                </header>
            </div>
            <div className="sized-container">
                {activeSection === "actions" && <ActionsSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("02:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "fun" && <FunSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("03:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "music" && <MusicSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("04:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "moderation" && <ModerationSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("05:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "profile" && <ProfileSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("06:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "economy" && <EconomySection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("07:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "utility" && <UtilitySection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("08:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "config" && <ConfigSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("09:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
                {activeSection === "bot" && <BotSection locale={locale} utils={utils} commands={data.commands.filter((command) => command.id?.includes("10:")).sort((a, b) => a.name.localeCompare(b.name))} data={data} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />}
            </div>
        </section>
    );
};

const ActionsSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div>
        </section>
    );
};

const FunSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div>
        </section>
    );
};

const MusicSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div>
        </section>
    );
};

const ModerationSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div>
        </section>
    );
};

const ProfileSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div>
        </section>
    );
};

const EconomySection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <h1 className="text subtitle-1 center-text"> Coming Soon!</h1>
            {/* <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div> */}
        </section>
    );
};

const UtilitySection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <h1 className="text subtitle-1 center-text"> Coming Soon!</h1>
            {/* <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div> */}
        </section>
    );
};

const ConfigSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <h1 className="text subtitle-1 center-text"> Coming Soon!</h1>
            {/* <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div> */}
        </section>
    );
};

const BotSection: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => {
    return (
        <section className="commands-section">
            <div className="container mx-auto">
                <div className="text command-counter">{commands.length}</div>
                <h1 className="text title-6 center-text">
                    <Translate content={`routes.commands.tab.${activeSection}`} component="span" /> <Translate content="routes.commands.commands" component="span" />
                </h1>
            </div>
            <div className="container mx-auto">
                <GenerateCommands data={data} locale={locale} utils={utils} commands={commands} activeSection={activeSection} activePopUp={activePopUp} triggerPopUp={triggerPopUp} examplePage={examplePage} />
            </div>
        </section>
    );
};

const GenerateCommands: React.FC<{ data: DataType; locale: string; utils: any; commands: IClientCommand[]; activeSection: string; activePopUp: [string, React.Dispatch<string>]; triggerPopUp: [boolean, React.Dispatch<boolean>]; examplePage: [number, React.Dispatch<number>] }> = ({ data, locale, utils, commands, activeSection, activePopUp, triggerPopUp, examplePage }) => (
    <div className="grid-commands mt-4 mb-20">
        {commands.map((command, index) => {
            const sanitizeCommandName = Utility.SanitizeHtml(command.name);
            return (
                <div key={index}>
                    <div
                        className="command-card"
                        onClick={() => {
                            activePopUp[1](command.id);
                            triggerPopUp[1](true);
                            examplePage[1](0);
                        }}>
                        <h1 className="name">{sanitizeCommandName}</h1>
                        <p className="description">{command.description}</p>
                    </div>
                    {activePopUp[0] === command.id && (
                        <AppComponent.PopUp id="CommandInformationPopup" trigger={triggerPopUp} closeFull close styleBox={{ outline: command.settings.premium ? "2px solid rgb(255, 230, 86)" : "none", boxShadow: command.settings.premium ? "0 0 20px rgb(255, 237, 135)" : "none" }} utils={utils}>
                            <div className="command-details">
                                <div className="main-details">
                                    <h1 className="command-name text title-6">
                                        {command.settings.premium && <BiCrown style={{ color: "rgb(255, 230, 86)" }} />}
                                        <Translate content="routes.commands.command" component="span" unsafe with={{ command: sanitizeCommandName }} />
                                    </h1>
                                    <p className="command-description text subtitle-2">{command.description}</p>
                                </div>
                                <div className="command-type mt-3">
                                    <Translate content="routes.commands.type.name" component="h1" className="command-name text title-8" />
                                    <Translate content="routes.commands.type.desc" component="p" className="text subtitle-4" />
                                    <AppComponent.Tooltip
                                        delay={[300, 100]}
                                        content={<Translate content="routes.commands.type.slash_i" component="p" unsafe with={{ command: Utility.SanitizeHtml([command.name, `${activeSection} ${command.name}`, `${activeSection} ${command.group} ${command.name}`][command.slashType ?? 0]).toLowerCase() }} />}
                                        children={
                                            <div className={`tag text ${command.type.includes(2) ? "active" : ""}`}>
                                                <span className="icon">
                                                    <BiCheck className="yes" />
                                                    <BiX className="no" />
                                                </span>
                                                <Translate content="routes.commands.type.slash" component="span" className="name" />
                                            </div>
                                        }
                                    />
                                    <AppComponent.Tooltip
                                        delay={[300, 100]}
                                        content={<Translate content="routes.commands.type.message_i" component="p" unsafe with={{ command: sanitizeCommandName }} />}
                                        children={
                                            <div className={`tag text ${command.type.includes(1) && locale === "en" ? "active" : ""}`}>
                                                <span className="icon">
                                                    <BiCheck className="yes" />
                                                    <BiX className="no" />
                                                </span>
                                                <Translate content="routes.commands.type.message" component="span" className="name" />
                                            </div>
                                        }
                                    />
                                    <AppComponent.Tooltip
                                        delay={[300, 100]}
                                        content={<Translate content="routes.commands.type.user_c_i" component="p" unsafe with={{ command: sanitizeCommandName }} />}
                                        children={
                                            <div className={`tag text ${command.type.includes(3) ? "active" : ""}`}>
                                                <span className="icon">
                                                    <BiCheck className="yes" />
                                                    <BiX className="no" />
                                                </span>
                                                <Translate content="routes.commands.type.user_c" component="span" className="name" />
                                            </div>
                                        }
                                    />
                                    <AppComponent.Tooltip
                                        delay={[300, 100]}
                                        content={<Translate content="routes.commands.type.message_c_i" component="p" unsafe with={{ command: sanitizeCommandName }} />}
                                        children={
                                            <div className={`tag text ${command.type.includes(4) ? "active" : ""}`}>
                                                <span className="icon">
                                                    <BiCheck className="yes" />
                                                    <BiX className="no" />
                                                </span>
                                                <Translate content="routes.commands.type.message_c" component="span" className="name" />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="command-permissions mt-3">
                                    <Translate content="routes.commands.perm.name" component="h1" className="command-name text title-8" />
                                    <Translate content="routes.commands.perm.desc" component="p" className="text subtitle-4" />
                                    <div>
                                        <AppComponent.Tooltip
                                            delay={[300, 100]}
                                            content={<Translate content="routes.commands.perm.bot_need" component="p" />}
                                            children={
                                                <div className="tag text">
                                                    <span className="icon">
                                                        <BiChip />
                                                    </span>
                                                </div>
                                            }
                                        />
                                        {command.settings.clientPermissions.length > 0 ? (
                                            command.settings.clientPermissions.map((permission, index) => (
                                                <div key={index} className="tag text">
                                                    <span className="icon">
                                                        <BiCircle />
                                                    </span>
                                                    <Translate content={`discord.permissions.p${permission}`} className="name" />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="tag text">
                                                <span className="icon">
                                                    <BiSquare />
                                                </span>
                                                <Translate content="routes.commands.perm.non_specific" component="span" className="name" />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <AppComponent.Tooltip
                                            delay={[300, 100]}
                                            content={<Translate content="routes.commands.perm.user_need" component="p" />}
                                            children={
                                                <div className="tag text">
                                                    <span className="icon">
                                                        <BiUser />
                                                    </span>
                                                </div>
                                            }
                                        />
                                        {command.settings.permissions.length > 0 ? (
                                            command.settings.permissions.map((permission, index) => (
                                                <div key={index} className="tag text">
                                                    <span className="icon">
                                                        <BiCircle />
                                                    </span>
                                                    <Translate content={`discord.permissions.p${permission}`} className="name" />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="tag text">
                                                <span className="icon">
                                                    <BiSquare />
                                                </span>
                                                <Translate content="routes.commands.perm.non_specific" component="span" className="name" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {command.type.includes(1) && (
                                    <div className="command-usage mt-3">
                                        <Translate content="routes.commands.usage.name" component="h1" className="command-name text title-8" />
                                        <Translate content="routes.commands.usage.desc" component="p" className="text subtitle-4" />
                                        <p className="text subtitle-4"></p>
                                        <div style={{ margin: "15px" }} />
                                        <code className="text subtitle-4">{`t!${command.settings.aliases[0]} ${command.settings.usage}`}</code>
                                        <div className="discord-bg mt-2 rounded-md relative">
                                            <AppComponent.DiscordMessagePreviewer guild={null} message={{ author: data.profile ? { username: data.profile.settings.user.username, avatarUrl: data.profile.settings.user.avatar ? Utility.GetUserAvatarUrl(data.profile._id, data.profile.settings.user.avatar) : `${process.env.PUBLIC_URL}/media/png/discord.png` } : { username: "Titan", avatarUrl: `${process.env.PUBLIC_URL}/media/png/TitanAvatar.png` }, content: `t!${command.settings.aliases[0]} ${command.settings.usageExample[examplePage[0]]}` }} utils={utils} />
                                            {command.settings.usageExample.length > 1 && (
                                                <div className="button absolute flex gap-2 top-[-12px] right-3">
                                                    <button className="hover02 color-primary h-[25px]" onClick={() => examplePage[1](examplePage[0] - 1 < 0 ? command.settings.usageExample.length - 1 : examplePage[0] - 1)}>
                                                        <span className="button-icon both both-1 mx-2">
                                                            <BiLeftArrowAlt />
                                                        </span>
                                                    </button>
                                                    <button className="hover02 color-primary h-[25px]" onClick={() => examplePage[1](examplePage[0] + 1 >= command.settings.usageExample.length ? 0 : examplePage[0] + 1)}>
                                                        <span className="button-icon both both-1 mx-2">
                                                            <BiRightArrowAlt />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="command-other mt-6">
                                    <Translate content="routes.commands.other.name" component="h1" className="command-name text title-8" />
                                    <Translate content="routes.commands.other.desc" component="p" className="text subtitle-4" />
                                    <div>
                                        <AppComponent.Tooltip
                                            delay={[300, 100]}
                                            content={<Translate content="routes.commands.other.active_i" component="p" />}
                                            children={
                                                <div className={`tag text ${command.settings.active ? "active" : ""}`}>
                                                    <span className="icon">
                                                        <BiCheck className="yes" />
                                                        <BiX className="no" />
                                                    </span>
                                                    <Translate content="routes.commands.other.active" component="span" className="name" />
                                                </div>
                                            }
                                        />
                                        <div className={`tag text ${command.settings.premium ? "active" : ""}`}>
                                            <span className="icon">
                                                <BiCheck className="yes" />
                                                <BiX className="no" />
                                            </span>
                                            <Translate content="routes.commands.other.premium" component="span" className="name" />
                                        </div>
                                        {command.settings.guild && (
                                            <AppComponent.Tooltip
                                                delay={[300, 100]}
                                                content={<Translate content="routes.commands.other.guild_i" component="p" />}
                                                children={
                                                    <div className="tag text active">
                                                        <span className="icon">
                                                            <BiCheck className="yes" />
                                                            <BiX className="no" />
                                                        </span>
                                                        <Translate content="routes.commands.other.guild" component="span" className="name" />
                                                    </div>
                                                }
                                            />
                                        )}
                                        {command.settings.nsfw && (
                                            <div className="tag text active">
                                                <span className="icon">
                                                    <BiCheck className="yes" />
                                                    <BiX className="no" />
                                                </span>
                                                <Translate content="routes.commands.other.nsfw" component="span" className="name" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </AppComponent.PopUp>
                    )}
                </div>
            );
        })}
    </div>
);

const Components = { Section };

export default Components;
