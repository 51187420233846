import React from "react";
import { BiCategory, BiCode, BiLogInCircle } from "react-icons/bi";
import Translate from "react-translate-component";
import { abbreviateNumber as AbbreviateNumber } from "js-abbreviation-number";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import { DataType } from "@route@::@home:hook";

const Section: React.FC<{ data: DataType; utils: any }> = ({ data: { client, profile }, utils }) => (
    <section className="content-area">
        <div className="relative w-[100vw] overflow-hidden">
            <AppComponent.BackgroundObjects totalObjects={10} positionLimit={[-10, 105]} sizeLimit={[10, 800]} />
            <div className="relative container mx-auto mb-24">
                <div className="relative container p-4">
                    <h1 className="text bot-title-1 text-center mt-10 drop-shadow-xl" children="Titan-Bot" />
                    <Translate content="routes.home.description" component="p" className="text bot-subtitle-1 bg-fade text-center mt-3 drop-shadow-md mx-auto" unsafe />
                </div>
                <div className="relative container p-4">
                    <div className="button flex justify-center mt-6 gap-2 screen">
                        <button className="hover01 color-success drop-shadow-lg" onClick={() => utils.use.Navigate("/commands?section=actions")}>
                            <span className="button-icon both">
                                <BiCode />
                            </span>
                            <Translate content="routes.home.buttons.b2" component="span" className="button-text" />
                        </button>
                        {profile ? (
                            <button className="hover01 color-blurple drop-shadow-lg" onClick={() => utils.use.Navigate("/dashboard")}>
                                <span className="button-icon both">
                                    <BiCategory />
                                </span>
                                <Translate content="routes.home.buttons.b3" component="span" className="button-text" />
                            </button>
                        ) : (
                            <button className="hover01 color-blurple drop-shadow-lg" onClick={() => (window.location.href = `${AppData.project.backendDomain}/api/auth/login`)}>
                                <span className="button-icon both">
                                    <BiLogInCircle />
                                </span>
                                <Translate content="routes.home.buttons.b1" component="span" className="button-text" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>

        <div className="relative full-divider flex place-items-center justify-center gap-4">
            <AppComponent.Tooltip
                content={client.data.totalServers.toLocaleString()}
                children={
                    <div className="background-02 drop-shadow-lg min-w-[250px] p-2 rounded-lg hide-mobile-01">
                        <div className="text font-1 text-[35px] text-center leading-none pink-text">{AbbreviateNumber(client.data.totalServers)}</div>
                        <div className="text font-2 text-[18px] text-center">
                            <Translate content="words.servers" />
                        </div>
                    </div>
                }
            />
            <AppComponent.Tooltip
                content={client.data.totalCommandsExecuted.toLocaleString()}
                children={
                    <div className="background-02 drop-shadow-lg min-w-[250px] p-2 rounded-lg hide-mobile-01">
                        <div className="text font-1 text-[35px] text-center leading-none pink-text">{AbbreviateNumber(client.data.totalCommandsExecuted)}</div>
                        <div className="text font-2 text-[18px] text-center">
                            <Translate content="words.commands_executed" />
                        </div>
                    </div>
                }
            />
            {/* <AppComponent.Tooltip
                content={client.data.totalUsers.toLocaleString()}
                children={
                    <div className="background-02 drop-shadow-lg min-w-[250px] p-2 rounded-lg hide-mobile-01">
                        <div className="text font-1 text-[35px] text-center leading-none pink-text">{AbbreviateNumber(client.data.totalUsers)}</div>
                        <div className="text font-2 text-[18px] text-center">
                            <Translate content="words.users" />
                        </div>
                    </div>
                }
            /> */}
        </div>

        <div className="relative container mx-auto min-h-[1000px]">
            <div className="home-info-panel main h-[200px] relative">
                <div className="about absolute top-[40px] right-0">
                    <div className="title drop-shadow-lg">
                        <Translate content="words.management" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.home.management_sum" unsafe />
                    </div>
                    <div className="icons mx-auto w-fit">
                        <img src={`${process.env.PUBLIC_URL}/media/png/electronics.png`} alt="avatar" className="absolute top-[70%] left-[-13%] float-2-1" />
                        <img src={`${process.env.PUBLIC_URL}/media/png/project-management.png`} alt="avatar" className="absolute top-[5%] right-[10%] float-2-2" />
                        <img src={`${process.env.PUBLIC_URL}/media/png/autopilot.png`} alt="avatar" className="absolute bottom-[-10%] right-0 float-2-3" />
                    </div>
                </div>
                <div className="images relative"></div>
            </div>
            <div className="home-info-panel main h-[200px] relative">
                <div className="about absolute top-[40px] left-0">
                    <div className="title drop-shadow-lg text-right">
                        <Translate content="words.entertainment" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.home.entertainment_sum" unsafe />
                    </div>
                    <div className="icons mx-auto w-fit">
                        <img src={`${process.env.PUBLIC_URL}/media/png/controller.png`} alt="avatar" className="absolute top-[30%] left-[-13%] float-2-2" />
                        <img src={`${process.env.PUBLIC_URL}/media/png/popcorn.png`} alt="avatar" className="absolute top-[15%] right-[-10%] float-2-3" />
                        <img src={`${process.env.PUBLIC_URL}/media/png/technology-lifestyle.png`} alt="avatar" className="absolute bottom-[-15%] right-[30%] float-2-1" />
                    </div>
                </div>
                <div className="images relative"></div>
            </div>
            <div className="home-info-panel main h-[200px] relative">
                <div className="about absolute top-[40px] right-0">
                    <div className="title drop-shadow-lg">
                        <Translate content="words.utility" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.home.utility_sum" unsafe />
                    </div>
                    <div className="icons mx-auto w-fit">
                        <img src={`${process.env.PUBLIC_URL}/media/png/switchboard.png`} alt="avatar" className="absolute top-[-5%] left-[40%] float-2-3" />
                        <img src={`${process.env.PUBLIC_URL}/media/png/prize.png`} alt="avatar" className="absolute top-[40%] right-[-10%] float-2-1" />
                        <img src={`${process.env.PUBLIC_URL}/media/png/speaker.png`} alt="avatar" className="absolute bottom-[-20%] right-[60%] float-2-2" />
                    </div>
                </div>
                <div className="images relative"></div>
            </div>
            {/* <div className="home-info-panel h-[800px] self-role relative">
                <div className="about absolute top-[40px] right-0">
                    <div className="title drop-shadow-lg">
                        <Translate content="routes.dashboard.selfrole.name" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.dashboard.selfrole.description01" unsafe />
                    </div>
                </div>
                <div className="images relative">
                    <img className="img-01 absolute w-[700px] top-[200px] rounded-[8px] drop-shadow-lg float-2-2 hover" src={`${process.env.PUBLIC_URL}/media/png/Selfrole01.png`} alt="" />
                    <img className="img-02 absolute w-[500px] top-[35px] left-[400px] rounded-[8px] drop-shadow-lg float-2-1 hover" src={`${process.env.PUBLIC_URL}/media/png/Selfrole02.png`} alt="" />
                    <img className="img-03 absolute w-[700px] top-[410px] left-[150px] rounded-[8px] drop-shadow-lg float-2-3 hover" src={`${process.env.PUBLIC_URL}/media/png/Selfrole03.png`} alt="" />
                </div>
            </div>
            <div className="home-info-panel h-[500px] leveling relative">
                <div className="about absolute top-[40px] left-0">
                    <div className="title drop-shadow-lg text-right">
                        <Translate content="routes.dashboard.leveling.name" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.dashboard.leveling.description" />
                    </div>
                </div>
                <div className="images relative">
                    <img className="img-01 absolute w-[600px] top-[35px] right-[200px] rounded-[8px] drop-shadow-lg float-2-2 hover z-10" src={`${process.env.PUBLIC_URL}/media/png/Leveling01.png`} alt="" />
                    <img className="img-02 absolute w-[600px] top-[200px] right-0 rounded-[8px] drop-shadow-lg float-2-2 hover" src={`${process.env.PUBLIC_URL}/media/png/Leveling02.png`} alt="" />
                </div>
            </div>
            <div className="home-info-panel h-[300px] auto-role relative">
                <div className="about absolute top-[40px] right-0">
                    <div className="title drop-shadow-lg">
                        <Translate content="routes.dashboard.autorole.name" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.dashboard.autorole.description" />
                    </div>
                </div>
                <div className="images relative"></div>
            </div>
            <div className="home-info-panel h-[300px] auto-publish relative">
                <div className="about absolute top-[40px] left-0">
                    <div className="title drop-shadow-lg text-right">
                        <Translate content="routes.dashboard.autopublish.name" />
                    </div>
                    <div className="description drop-shadow-lg">
                        <Translate content="routes.dashboard.autopublish.description" />
                    </div>
                </div>
                <div className="images relative"></div>
            </div> */}
        </div>
    </section>
);

const Components = { Section };

export default Components;
