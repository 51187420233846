import React from "react";
import Dot from "dot-object";
import Translate from "react-translate-component";
import Select from "react-select";
import { BiInfoCircle } from "react-icons/bi";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import DashboardComponent from "./index";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "automod";
    const sectionPath = "modules.autoMod";
    const sectionData = Dot.pick(sectionPath, config.data.current);

    const actionList = [
        { label: <Translate content="routes.dashboard.automod.actions.delete_message" />, value: 0, search: utils.translation.routes.dashboard.automod.actions.delete_message },
        { label: <Translate content="routes.dashboard.automod.actions.warn_member" />, value: 1, search: utils.translation.routes.dashboard.automod.actions.warn_member },
        { label: <Translate content="routes.dashboard.automod.actions.timeout_member" />, value: 2, search: utils.translation.routes.dashboard.automod.actions.timeout_member },
        { label: <Translate content="routes.dashboard.automod.actions.kick_member" />, value: 3, search: utils.translation.routes.dashboard.automod.actions.kick_member },
        { label: <Translate content="routes.dashboard.automod.actions.ban_member" />, value: 4, search: utils.translation.routes.dashboard.automod.actions.ban_member },
        { label: <Translate content="routes.dashboard.automod.actions.temp_ban_member" />, value: 5, search: utils.translation.routes.dashboard.automod.actions.temp_ban_member },
        { label: <Translate content="routes.dashboard.automod.actions.send_message_to_offender" />, value: 6, search: utils.translation.routes.dashboard.automod.actions.send_message_to_offender },
        { label: <Translate content="routes.dashboard.automod.actions.send_message_in_channel" />, value: 7, search: utils.translation.routes.dashboard.automod.actions.send_message_in_channel },
        { label: <Translate content="routes.dashboard.automod.actions.send_quick_action_menu" />, value: 8, search: utils.translation.routes.dashboard.automod.actions.send_quick_action_menu },
    ];

    const modelist = [
        { label: <Translate content="words.blacklist" />, value: 0 },
        { label: <Translate content="words.whitelist" />, value: 1 },
    ];

    return (
        <section className="dashboard-section">
            <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
            <div className="container mx-auto mb-20">
                {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                {sectionData.active && (
                    <>
                        <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 mb-5 form">
                            <Translate content="words.settings" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.automod.settings_i" component="h1" className="text subtitle-4" />
                            <div className="form-inputs">
                                <AppComponent.FormInput type="select" htmlFor="automod-default-channel" label={utils.translation.routes.dashboard.automod.default_channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.loggingChannel], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.loggingChannel", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                                <AppComponent.FormInput disabled={!sectionData.settings.loggingChannel} type="toggle-checkbox" htmlFor="automod-action-menu" label={utils.translation.routes.dashboard.automod.action_menu} value={sectionData.settings.sendActionMenu} onChange={(value) => config.dispatch({ type: "update", path: "modules.autoMod.settings.sendActionMenu", data: value.target.checked })} additionalProps={{ togglePlacement: "left" }} utils={utils} />
                                <div className="grid-col-2 gap-2 gap-x-4">
                                    <AppComponent.FormInput className="w-full" type="select" htmlFor="blacklisted-channels" label={utils.translation.words.blacklisted_channels} placeholder={utils.translation.words.select_channels} value={Utility.GuildDataFilterIgnore(sectionData.settings.blacklistedChannels, guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.blacklistedChannels", data: value ? value.map((v: any) => v.value) : [] })} additionalProps={{ isMulti: true, closeMenuOnSelect: false }} utils={utils} />
                                    <AppComponent.FormInput className="w-full" type="select" htmlFor="blacklisted-roles" label={utils.translation.words.blacklisted_roles} placeholder={utils.translation.words.select_roles} value={Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(sectionData.settings.blacklistedRoles, guild.roles, true))} options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS", "NO_ADMIN", "NO_MANAGER"])))} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.blacklistedRoles", data: value ? value.map((v: any) => v.value) : [] })} additionalProps={{ isMulti: true, closeMenuOnSelect: false }} utils={utils} />
                                </div>
                            </div>
                        </div>
                        <div className="greeter-grid">
                            <div className="greeter-grid-in">
                                <span>
                                    <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 relative form">
                                        <div className="flex justify-between items-center">
                                            <Translate content="routes.dashboard.automod.invites" component="h1" className="text title-8" />
                                            <AppComponent.FormInput
                                                type="toggle-switch"
                                                value={sectionData.settings.flaggedInvites.active}
                                                onChange={(value) => {
                                                    config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedInvites.active", data: value.target.checked });
                                                }}
                                                additionalProps={{ toggleColor: "background-dark-03" }}
                                                utils={utils}
                                            />
                                        </div>
                                        <Translate content="routes.dashboard.automod.invites_i" component="h1" className="text subtitle-4" />
                                        <div className="form-inputs">
                                            <AppComponent.FormInput
                                                type="select"
                                                disabled={!sectionData.settings.flaggedInvites.active}
                                                htmlFor="invites-actions"
                                                label={utils.translation.words.actions}
                                                placeholder={utils.translation.words.select_actions}
                                                tooltip={utils.translation.routes.dashboard.automod.action_i}
                                                value={actionList.filter((a) => sectionData.settings.flaggedInvites.actions.includes(a.value))}
                                                options={actionList.filter((a) => {
                                                    let value = true;
                                                    if ((sectionData.settings.flaggedInvites.actions.includes(2) || sectionData.settings.flaggedInvites.actions.includes(3) || sectionData.settings.flaggedInvites.actions.includes(4) || sectionData.settings.flaggedInvites.actions.includes(5)) && [2, 3, 4, 5].includes(a.value)) value = false;
                                                    return value;
                                                })}
                                                onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedInvites.actions", data: value ? value.map((v: any) => v.value) : [] })}
                                                additionalProps={{ isMulti: true, closeMenuOnSelect: false }}
                                                utils={utils}
                                            />
                                            {sectionData.settings.flaggedInvites.actions.includes(2) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.flaggedInvites.active}
                                                    type="number"
                                                    htmlFor="invites-duration"
                                                    label={utils.translation.routes.dashboard.automod.duration_mins}
                                                    value={sectionData.settings.flaggedInvites.timeoutDuration}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedInvites.timeoutDuration", data: num ? (num < 1 ? 1 : num > 40319 ? 40319 : num) : 1 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                            {sectionData.settings.flaggedInvites.actions.includes(5) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.flaggedInvites.active}
                                                    type="number"
                                                    htmlFor="invites-duration"
                                                    label={utils.translation.routes.dashboard.automod.duration_days}
                                                    value={sectionData.settings.flaggedInvites.banDuration}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedInvites.banDuration", data: num ? (num < 1 ? 1 : num > 5000 ? 5000 : num) : 1 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                            {(sectionData.settings.flaggedInvites.actions.includes(4) || sectionData.settings.flaggedInvites.actions.includes(5)) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.flaggedInvites.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.delete_days}
                                                    htmlFor="invites-delete-days"
                                                    value={sectionData.settings.flaggedInvites.deleteDays}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedInvites.deleteDays", data: num ? (num < 0 ? 0 : num > 7 ? 7 : num) : 0 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </span>
                                <span>
                                    <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 relative form">
                                        <div className="flex justify-between items-center">
                                            <Translate content="routes.dashboard.automod.warning" component="h1" className="text title-8" />
                                            <AppComponent.FormInput
                                                type="toggle-switch"
                                                value={sectionData.settings.warningThreshold.active}
                                                onChange={(value) => {
                                                    config.dispatch({ type: "update", path: "modules.autoMod.settings.warningThreshold.active", data: value.target.checked });
                                                }}
                                                additionalProps={{ toggleColor: "background-dark-03" }}
                                                utils={utils}
                                            />
                                        </div>
                                        <Translate content="routes.dashboard.automod.warning_i" component="h1" className="text subtitle-4" />
                                        <div className="form-inputs">
                                            <AppComponent.FormInput
                                                disabled={!sectionData.settings.warningThreshold.active}
                                                type="number"
                                                label={utils.translation.routes.dashboard.automod.warning_mins}
                                                htmlFor="warning-minimum"
                                                value={sectionData.settings.warningThreshold.minimum}
                                                onChange={(value) => {
                                                    let num = parseInt(value.target.value);
                                                    config.dispatch({ type: "update", path: "modules.autoMod.settings.warningThreshold.minimum", data: num ? (num < 0 ? 0 : num > 1000 ? 1000 : num) : 0 });
                                                }}
                                                additionalProps={{ required: true }}
                                                utils={utils}
                                            />
                                            <AppComponent.FormInput
                                                type="select"
                                                disabled={!sectionData.settings.warningThreshold.active}
                                                htmlFor="warning-actions"
                                                label={utils.translation.words.actions}
                                                placeholder={utils.translation.words.select_actions}
                                                tooltip={utils.translation.routes.dashboard.automod.action_i}
                                                value={actionList.filter((a) => sectionData.settings.warningThreshold.actions.includes(a.value))}
                                                options={actionList.filter((a) => {
                                                    let value = true;
                                                    if ([0, 1, 6, 7].includes(a.value) || ((sectionData.settings.warningThreshold.actions.includes(2) || sectionData.settings.warningThreshold.actions.includes(3) || sectionData.settings.warningThreshold.actions.includes(4) || sectionData.settings.warningThreshold.actions.includes(5)) && [2, 3, 4, 5].includes(a.value))) value = false;
                                                    return value;
                                                })}
                                                onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.warningThreshold.actions", data: value ? value.map((v: any) => v.value) : [] })}
                                                additionalProps={{ isMulti: true, closeMenuOnSelect: false }}
                                                utils={utils}
                                            />
                                            {sectionData.settings.warningThreshold.actions.includes(2) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.warningThreshold.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.duration_mins}
                                                    htmlFor="warning-duration"
                                                    value={sectionData.settings.warningThreshold.timeoutDuration}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.warningThreshold.timeoutDuration", data: num ? (num < 1 ? 1 : num > 40319 ? 40319 : num) : 1 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                            {sectionData.settings.warningThreshold.actions.includes(5) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.warningThreshold.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.duration_days}
                                                    htmlFor="warning-duration"
                                                    value={sectionData.settings.warningThreshold.banDuration}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.warningThreshold.banDuration", data: num ? (num < 1 ? 1 : num > 5000 ? 5000 : num) : 1 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                            {(sectionData.settings.warningThreshold.actions.includes(4) || sectionData.settings.warningThreshold.actions.includes(5)) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.warningThreshold.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.delete_days}
                                                    htmlFor="warning-delete-days"
                                                    value={sectionData.settings.warningThreshold.deleteDays}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.warningThreshold.deleteDays", data: num ? (num < 0 ? 0 : num > 7 ? 7 : num) : 0 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className="greeter-grid-in">
                                <span>
                                    <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 relative form">
                                        <div className="flex justify-between items-center">
                                            <Translate content="routes.dashboard.automod.links" component="h1" className="text title-8" />
                                            <AppComponent.FormInput
                                                type="toggle-switch"
                                                value={sectionData.settings.flaggedLinks.active}
                                                onChange={(value) => {
                                                    config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedLinks.active", data: value.target.checked });
                                                }}
                                                additionalProps={{ toggleColor: "background-dark-03" }}
                                                utils={utils}
                                            />
                                        </div>
                                        <Translate content="routes.dashboard.automod.links_i" component="h1" className="text subtitle-4" />
                                        <div className="form-inputs">
                                            <AppComponent.FormInput type="select" disabled={!sectionData.settings.flaggedLinks.active} htmlFor="links-mode" label={utils.translation.words.mode} value={modelist[sectionData.settings.flaggedLinks.mode]} options={modelist} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedLinks.mode", data: value.value })} utils={utils} />
                                            <AppComponent.FormInput
                                                type="combo-select"
                                                disabled={!sectionData.settings.flaggedLinks.active}
                                                htmlFor="links-list"
                                                label={utils.translation.routes.dashboard.automod.flagged_links}
                                                placeholder={utils.translation.routes.dashboard.automod.links_list}
                                                value={sectionData.data.flaggedLinks.map((link: any) => ({ label: link, value: link }))}
                                                onChange={(values: any) => {
                                                    const doamins: any[] = [];
                                                    for (let value of values) {
                                                        const url = Utility.SearchForURLs(value.value).map(Utility.ParseURL)[0];
                                                        const domain = url && url.domain ? /(?<a>[^.]+\.[^.]+)$/.exec(url.domain.toLowerCase())?.groups?.a : null;
                                                        if (domain && !AppData.other.regex.discordDomainsPattern.test(domain)) doamins.push(domain);
                                                    }
                                                    config.dispatch({ type: "update", path: "modules.autoMod.data.flaggedLinks", data: Utility.RemoveDuplicates(doamins) });
                                                }}
                                                additionalProps={{
                                                    isMulti: true,
                                                    closeMenuOnSelect: false,
                                                    noOptionsMessage: () => utils.translation.routes.dashboard.automod.links_list_adding,
                                                    formatCreateLabel: (v: any) => (
                                                        <>
                                                            {utils.translation.words.add} <code>{v}</code>
                                                        </>
                                                    ),
                                                }}
                                                utils={utils}
                                            />
                                            <AppComponent.FormInput
                                                type="select"
                                                disabled={!sectionData.settings.flaggedLinks.active}
                                                htmlFor="links-actions"
                                                label={utils.translation.words.actions}
                                                placeholder={utils.translation.words.select_actions}
                                                tooltip={utils.translation.routes.dashboard.automod.action_i}
                                                value={actionList.filter((a) => sectionData.settings.flaggedLinks.actions.includes(a.value))}
                                                options={actionList.filter((a) => {
                                                    let value = true;
                                                    if ((sectionData.settings.flaggedLinks.actions.includes(2) || sectionData.settings.flaggedLinks.actions.includes(3) || sectionData.settings.flaggedLinks.actions.includes(4) || sectionData.settings.flaggedLinks.actions.includes(5)) && [2, 3, 4, 5].includes(a.value)) value = false;
                                                    return value;
                                                })}
                                                onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedLinks.actions", data: value ? value.map((v: any) => v.value) : [] })}
                                                additionalProps={{ isMulti: true, closeMenuOnSelect: false }}
                                                utils={utils}
                                            />

                                            {sectionData.settings.flaggedLinks.actions.includes(2) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.flaggedLinks.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.duration_mins}
                                                    htmlFor="links-duration"
                                                    value={sectionData.settings.flaggedLinks.timeoutDuration}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedLinks.timeoutDuration", data: num ? (num < 1 ? 1 : num > 40319 ? 40319 : num) : 1 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                            {sectionData.settings.flaggedLinks.actions.includes(5) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.flaggedLinks.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.duration_days}
                                                    htmlFor="links-duration"
                                                    value={sectionData.settings.flaggedLinks.banDuration}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedLinks.banDuration", data: num ? (num < 1 ? 1 : num > 5000 ? 5000 : num) : 1 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                            {(sectionData.settings.flaggedLinks.actions.includes(4) || sectionData.settings.flaggedLinks.actions.includes(5)) && (
                                                <AppComponent.FormInput
                                                    disabled={!sectionData.settings.flaggedLinks.active}
                                                    type="number"
                                                    label={utils.translation.routes.dashboard.automod.delete_days}
                                                    htmlFor="links-delete-days"
                                                    value={sectionData.settings.flaggedLinks.deleteDays}
                                                    onChange={(value) => {
                                                        let num = parseInt(value.target.value);
                                                        config.dispatch({ type: "update", path: "modules.autoMod.settings.flaggedLinks.deleteDays", data: num ? (num < 0 ? 0 : num > 7 ? 7 : num) : 0 });
                                                    }}
                                                    additionalProps={{ required: true }}
                                                    utils={utils}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default Section;
