import React from "react";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, RadialLinearScale } from "chart.js";

ChartJS.register(ArcElement);
ChartJS.register(CategoryScale);
ChartJS.register(LinearScale);
ChartJS.register(PointElement);
ChartJS.register(LineElement);
ChartJS.register(Tooltip);
ChartJS.register(RadialLinearScale);

const Chart: React.FC<{ chart: any; data: any; options?: any }> = (props) => (
    <props.chart
        data={props.data}
        options={{
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            plugins: {
                tooltip: {
                    enabled: true,
                },
                legend: {
                    display: true,
                },
            },
            ...props.options,
        }}
    />
);

export default Chart;
