import React, { useState } from "react";
import { BiCog, BiTrash, BiDownArrow, BiInfoCircle, BiSave } from "react-icons/bi";
import Select from "react-select";
import ReactMoment from "react-moment";
import Dot from "dot-object";
import Lodash from "lodash";
import Translate from "react-translate-component";
import DOMPurify from "dompurify";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const formatPopup = useState<boolean>(false);
    const currentLogPage = useState<number>(1);
    const logPerPage = useState<number>(10);
    const commandSearchFilter = useState<string>("");
    const commandPopup = useState<boolean>(false);
    const commandConfig = useState<{ p: any; c: any }>({ p: {}, c: {} });

    const generateChangeMessage = (change: any) => {
        let message = AppData.other.auditLogMessagesList[change.id as keyof typeof AppData.other.auditLogMessagesList];
        for (let other of change.other) message = message?.replace(other.key, Utility.EncodeHtmlEntities(other.value));
        return message;
    };
    const clientLanguageOptions = [
        {
            label: (
                <div className="flex gap-2 items-center">
                    <img className="max-h-[25px] rounded-[6px]" src={`${process.env.PUBLIC_URL}/media/png/en.png`} alt="" />
                    {utils.translation.other_name.en}
                </div>
            ),
            search: utils.translation.other_name.en,
            value: "en",
        },
        {
            label: (
                <div className="flex gap-2 items-center">
                    <img className="max-h-[25px] rounded-[6px]" src={`${process.env.PUBLIC_URL}/media/png/es.png`} alt="" />
                    {utils.translation.other_name.es}
                </div>
            ),
            search: utils.translation.other_name.es,
            value: "es",
        },
        {
            label: (
                <div className="flex gap-2 items-center">
                    <img className="max-h-[25px] rounded-[6px]" src={`${process.env.PUBLIC_URL}/media/png/ja.png`} alt="" />
                    {utils.translation.other_name.ja}
                </div>
            ),
            search: utils.translation.other_name.ja,
            value: "ja",
        },
        {
            label: (
                <div className="flex gap-2 items-center">
                    <img className="max-h-[25px] rounded-[6px]" src={`${process.env.PUBLIC_URL}/media/png/de.png`} alt="" />
                    {utils.translation.other_name.de}
                </div>
            ),
            search: utils.translation.other_name.de,
            value: "de",
        },
        {
            label: (
                <div className="flex gap-2 items-center">
                    <img className="max-h-[25px] rounded-[6px]" src={`${process.env.PUBLIC_URL}/media/png/hu.png`} alt="" />
                    {utils.translation.other_name.hu}
                </div>
            ),
            search: utils.translation.other_name.hu,
            value: "hu",
        },
    ];

    // Log
    const auditLog = config.data.current ? JSON.parse(JSON.stringify(config.data.current.data.auditLog)).sort((a: any, b: any) => parseInt(b.timestamp) - parseInt(a.timestamp)) : [];
    const indexOfLastLog = currentLogPage[0] * logPerPage[0];
    const indexOfFirstLog = indexOfLastLog - logPerPage[0];
    const currentLogs = auditLog.slice(indexOfFirstLog, indexOfLastLog);
    const changeLogPage = (pageNumber: number) => currentLogPage[1](pageNumber);

    // Command Config
    const checkActive = (id: any) => {
        const cmd = config.data.current.settings.commands.find((cmd) => cmd.id === id);
        return cmd ? cmd.active : true;
    };

    return (
        <>
            <section className="dashboard-section">
                <div className="container mx-auto">
                    <Translate content="routes.dashboard.settings.name" component="h1" className="text title-6-5" />
                </div>
                <div className="container mx-auto">
                    <div className="guild-setting-grid">
                        <div className="grid01">
                            <div className="grid02">
                                <div className="card form">
                                    <h1 className="text title-7">Titan-Bot</h1>
                                    <div className="form-inputs">
                                        <AppComponent.FormInput type="text" label={utils.translation.routes.dashboard.settings.prefix} placeholder="t!" htmlFor="prefix" value={config.data.current.prefix} onChange={(value) => config.dispatch({ type: "update", path: "prefix", data: value.target.value })} additionalProps={{ maxLength: 3, required: true }} utils={utils} />
                                        <AppComponent.FormInput type="text" label={utils.translation.routes.dashboard.settings.nickname} placeholder="Titan-Bot" htmlFor="nickname" value={config.data.current.data.client.nickname} onChange={(value) => config.dispatch({ type: "update", path: "data.client.nickname", data: value.target.value })} additionalProps={{ maxLength: 32, required: true }} utils={utils} />
                                        <AppComponent.FormInput type="select" label={utils.translation.routes.dashboard.settings.language} htmlFor="language" value={clientLanguageOptions.find((a) => a.value === config.data.current.settings.language)} options={clientLanguageOptions} onChange={(value: any) => config.dispatch({ type: "update", path: "settings.language", data: value?.value })} additionalProps={{ maxLength: 32, required: true }} utils={utils} />
                                    </div>
                                </div>
                                <div className="card form">
                                    <h1 className="text title-8 info-icon">
                                        <Translate content="routes.dashboard.settings.ignore" />
                                        <AppComponent.Tooltip content={<Translate content="routes.dashboard.settings.ignore_i" />} children={<span className="icon" children={<BiInfoCircle />} />} />
                                    </h1>
                                    <div className="form-inputs">
                                        <AppComponent.FormInput type="select" htmlFor="ignore-channels" label={utils.translation.words.channels} placeholder={utils.translation.words.select_channels} value={config.data.current ? Utility.GuildDataFilterIgnore(config.data.current.data.client.ignore.channels, guild.channels) : []} onChange={(value: any) => config.dispatch({ type: "update", path: "data.client.ignore.channels", data: value.map((a: any) => a?.value) })} options={extra.channelOptions([0, 2, 5])} additionalProps={{ isMulti: true, closeMenuOnSelect: false }} utils={utils} />
                                        <AppComponent.FormInput type="select" htmlFor="ignore-roles" label={utils.translation.words.roles} placeholder={utils.translation.words.select_roles} value={Utility.DiscordRolesToDropdownOptions(config.data.current ? Utility.GuildDataFilterIgnore(config.data.current.data.client.ignore.roles, guild.roles, true) : [])} options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))} onChange={(value: any) => config.dispatch({ type: "update", path: "data.client.ignore.roles", data: value.map((a: any) => a?.value) })} additionalProps={{ isMulti: true, closeMenuOnSelect: false }} utils={utils} />
                                    </div>
                                </div>
                                <div className="card">
                                    <Translate content="words.danger" component="h1" className="text title-7 text-center" />
                                    <div className="button flex justify-center mt-4">
                                        <button className="hover02 color-danger" onClick={() => formatPopup[1](true)}>
                                            <Translate content="words.format" component="span" className="button-text both" />
                                            <span className="button-icon">
                                                <BiTrash />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="grid02">
                                <div className="card">
                                    <Translate content="routes.dashboard.settings.log" component="h1" className="text title-7 text-center" />
                                    <div style={{ margin: "1rem" }} />
                                    {auditLog.length < 1 ? (
                                        <Translate content="routes.dashboard.settings.no_log" component="p" className="text text-center" style={{ marginTop: "5px" }} with={{ name: "Titan-Bot" }} />
                                    ) : (
                                        <>
                                            <AppComponent.Pagination changePage={changeLogPage} currentPage={currentLogPage[0]} dataPerPage={logPerPage[0]} totalData={auditLog.length} utils={utils} />
                                            {currentLogs.map((changeData: any, i: number) => (
                                                <div
                                                    key={i}
                                                    className={`change-card id-${i}`}
                                                    onClick={() => {
                                                        const elements = document.querySelectorAll(`.change-card .changes.active`);
                                                        const element = document.querySelector(`.change-card.id-${i} .changes`);
                                                        if (element?.classList.contains("active")) element.classList.remove("active");
                                                        else element?.classList.add("active");

                                                        elements.forEach((e) => {
                                                            if (e === element) return;
                                                            e.classList.remove("active");
                                                        });
                                                    }}>
                                                    <div className="changes">
                                                        {changeData.changes
                                                            .sort((a: any, b: any) => b.index - a.index)
                                                            .map((change: any, j: number) => (
                                                                <div key={j} className="change text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`<span className="change-index">${Utility.LeadingZero(j + 1, 2)}</span> — ${generateChangeMessage(change)}`) }} />
                                                            ))}
                                                    </div>
                                                    <div className="main text">
                                                        <div className="flex justify-between mb-2 gap-2">
                                                            <div className="username">{`${Utility.TrimString(changeData.username.split("#")[0], 32)}#${changeData.username.split("#")[1]}`}</div>
                                                            <AppComponent.Tooltip content={changeData.username} children={<div className="id">ID {changeData.id}</div>} />
                                                        </div>
                                                        <div className="message">
                                                            <span className="message-text">
                                                                <AppComponent.Tooltip content={<ReactMoment locale={utils.locale} date={parseInt(changeData.timestamp)} format={"llll"} />} children={<span className="time">{<ReactMoment locale={utils.locale} date={parseInt(changeData.timestamp)} fromNow />}</span>} />
                                                                <Translate content={`routes.dashboard.settings.${changeData.changes.length > 1 ? "change_1" : "change_0"}`} component="span" className="message-changes" with={{ num: changeData.changes.length }} />
                                                            </span>
                                                            <span className="icon">
                                                                <BiDownArrow />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <AppComponent.Pagination changePage={changeLogPage} currentPage={currentLogPage[0]} dataPerPage={logPerPage[0]} totalData={auditLog.length} utils={utils} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto">
                    <Translate content="routes.dashboard.settings.cmd_config" component="h1" className="text title-6-5" />
                </div>
                <div className="container mx-auto mb-10">
                    <div className="background-02 p-4 rounded-[10px] max-w-[500px] mx-auto form">
                        <AppComponent.FormInput type="text" placeholder={utils.translation.routes.dashboard.settings.search + "..."} htmlFor="search" onChange={(value) => commandSearchFilter[1](value.target.value.toLowerCase())} utils={utils} />
                    </div>
                    <div className="guild-setting-command-grid mx-auto">
                        {commands
                            .filter((cmd) => !cmd.id.includes("00::") && !cmd.id.includes("01::"))
                            .filter((cmd) => cmd.name.toLowerCase().includes(commandSearchFilter[0]))
                            .map((command, key) => (
                                <div className="guild-setting-command-card" key={key}>
                                    <h1 className="text title-8">{Utility.CapitalizedCase(command.name)}</h1>
                                    <div
                                        className="click-area absolute w-full h-full left-0 top-0"
                                        onClick={() => {
                                            let data = config.data.current.settings.commands.find((cmd: any) => cmd.id === command.id) || { id: command.id, active: true, cooldown: 0, cooldownList: [], blacklistChannels: { active: true, list: [] }, whitelistChannels: { active: true, list: [] }, blacklistRoles: { active: true, list: [] }, whitelistRoles: { active: true, list: [] } };
                                            commandPopup[1](true);
                                            commandConfig[1]({ p: data, c: data });
                                        }}
                                    />
                                    <AppComponent.FormInput
                                        type="toggle-switch"
                                        value={checkActive(command.id)}
                                        onChange={(value) => {
                                            const data = JSON.parse(JSON.stringify(config.data.current));
                                            let cmd = data.settings.commands.find((cmd: any) => cmd.id === command.id);
                                            if (cmd) Dot.set("active", value.target.checked, cmd);
                                            else cmd = { id: command.id, active: value.target.checked, cooldown: 0, cooldownList: [], blacklistChannels: { active: true, list: [] }, whitelistChannels: { active: true, list: [] }, blacklistRoles: { active: true, list: [] }, whitelistRoles: { active: true, list: [] } };
                                            Dot.set("settings.commands", [...data.settings.commands.filter((a: any) => a.id !== cmd.id), cmd], data);
                                            config.dispatch({ type: "update", path: "settings.commands", data: data.settings.commands });
                                        }}
                                        additionalProps={{ toggleColor: "background-dark-03" }}
                                        utils={utils}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </section>
            <AppComponent.PopUp
                id="FormatGuildDataPopup"
                children={
                    guild.owner_id === profile._id ? (
                        <div className="form">
                            <h1 className="text title-6 text-center">
                                <Translate content="words.wait" />!
                            </h1>
                            <Translate content="routes.dashboard.settings.format_message" component="p" className="text text-center" with={{ name: "Titan-Bot" }} />
                            <p className="text text-center mt-4" style={{ color: "#ed4245" }}>
                                <b>{utils.translation.words.warning.toUpperCase()}</b> <Translate content="routes.dashboard.settings.format_warn" component="i" />
                            </p>
                            <Translate content="routes.dashboard.settings.enter_name" component="p" className="text text-center mt-6" with={{ name: <b>{guild.name}</b> }} />
                            <AppComponent.FormInput type="text" label={utils.translation.routes.dashboard.settings.server_name} htmlFor="server-format-name" utils={utils} />

                            <div className="button flex justify-center gap-4 mt-6">
                                <button className="hover02 color-secondary" onClick={() => formatPopup[1](false)}>
                                    <Translate content="words.nope" className="button-text" />
                                </button>
                                <button
                                    className="hover02 color-danger"
                                    onClick={() => {
                                        const input = document.getElementById("server-format-name");
                                        if (!input) return; // @ts-ignore
                                        const value: string = input.value;
                                        if (value !== guild.name) {
                                            input.parentElement?.classList.add("shake");
                                            input.parentElement?.classList.add("error");
                                            Utility.Wait(100).then(() => {
                                                input.parentElement?.classList.remove("shake");
                                                input.parentElement?.classList.remove("error");
                                            });
                                            return;
                                        } else {
                                            try {
                                                config.dispatch({ type: "format", data: { guild, update: extra.update, refetch: extra.refetch } });
                                                formatPopup[1](false);
                                            } catch (error) {
                                                formatPopup[1](false);
                                            }
                                        }
                                    }}>
                                    <span className="button-text both">Format</span>
                                    <span className="button-icon">
                                        <BiTrash />
                                    </span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h1 className="text title-6 text-center">( •̀ ω •́ )✧</h1>
                            <Translate content="routes.dashboard.settings.only_owner" component="p" className="text text-center" />
                            <div className="button flex justify-center mt-3">
                                <button className="hover02 color-success" onClick={() => formatPopup[1](false)}>
                                    <Translate content="words.okay" className="button-text" />
                                </button>
                            </div>
                        </div>
                    )
                }
                trigger={formatPopup}
                closeFull
                close
                utils={utils}
            />
            <AppComponent.PopUp
                id="CommandConfigPopup"
                children={
                    commandConfig[0] && (
                        <div className="form">
                            <div className="flex justify-between">
                                <h1 className="text title-6-5">
                                    {Utility.CapitalizedCase(commands.find((cmd) => cmd.id === commandConfig[0].c.id)?.name || "")} <Translate content="words.command" />
                                </h1>
                                <AppComponent.FormInput
                                    type="toggle-switch"
                                    value={commandConfig[0].c.active}
                                    onChange={(value) => {
                                        const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                        Dot.set("c.active", value.target.checked, data);
                                        commandConfig[1](data);
                                    }}
                                    additionalProps={{ toggleLarge: true, toggleColor: "background-dark-03" }}
                                    utils={utils}
                                />
                            </div>
                            <p className="text subtitle-4">{commands.find((cmd) => cmd.id === commandConfig[0].c.id)?.description}</p>
                            <div className="flex justify-between items-center mt-10">
                                <h1 className="text title-8 info-icon">
                                    <Translate content="words.cooldown" />
                                    <AppComponent.Tooltip
                                        content={
                                            <>
                                                <Translate content="routes.dashboard.settings.cmd_cooldown_i" />
                                                <br />
                                                <code>
                                                    <Translate content="words.hour" /> - <Translate content="words.minute" /> - <Translate content="words.second" />
                                                </code>
                                            </>
                                        }
                                        children={<span className="icon" children={<BiInfoCircle />} />}
                                    />
                                </h1>
                                <AppComponent.TimePicker
                                    startMs={commandConfig[0].c.cooldown}
                                    callback={(ms: any) => {
                                        const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                        Dot.set("c.cooldown", ms, data);
                                        Dot.set("c.cooldownList", [], data);
                                        commandConfig[1](data);
                                    }}
                                />
                            </div>
                            <div className="justify-between mt-5 mb-10">
                                <h1 className="text title-8 info-icon">
                                    <Translate content="words.blacklist" /> / <Translate content="words.whitelist" />
                                    <AppComponent.Tooltip
                                        content={
                                            <>
                                                <Translate content="words.blacklist" component="code" /> <Translate content="routes.dashboard.settings.cmd_blacklist_i" />
                                                <br />
                                                <Translate content="words.whitelist" component="code" /> <Translate content="routes.dashboard.settings.cmd_whitelist_i" />
                                            </>
                                        }
                                        children={<span className="icon" children={<BiInfoCircle />} />}
                                    />
                                </h1>
                                <div className="grid-col-2 gap-select items-end">
                                    <AppComponent.FormInput
                                        type="select"
                                        label={utils.translation.words.blacklisted_channels}
                                        placeholder={utils.translation.words.select_channels}
                                        value={commandConfig[0].c.blacklistChannels?.list ? Utility.GuildDataFilterIgnore(commandConfig[0].c.blacklistChannels.list, guild.channels) : []}
                                        options={extra.channelOptions([0, 2, 5])}
                                        onChange={(value: any) => {
                                            const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                            Dot.set(
                                                "c.blacklistChannels.list",
                                                value.map((a: any) => a?.value),
                                                data
                                            );
                                            commandConfig[1](data);
                                        }}
                                        additionalProps={{
                                            isMulti: true,
                                            closeMenuOnSelect: false,
                                            onFocus: (value: any) => {
                                                const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                                Dot.set("c.blacklistChannels.active", true, data);
                                                Dot.set("c.whitelistChannels.active", false, data);
                                                commandConfig[1](data);
                                            },
                                        }}
                                        utils={utils}
                                    />
                                    <AppComponent.FormInput
                                        type="select"
                                        label={utils.translation.words.whitelisted_channels}
                                        placeholder={utils.translation.words.select_channels}
                                        value={commandConfig[0].c.whitelistChannels?.list ? Utility.GuildDataFilterIgnore(commandConfig[0].c.whitelistChannels.list, guild.channels) : []}
                                        options={extra.channelOptions([0, 2, 5])}
                                        onChange={(value: any) => {
                                            const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                            Dot.set(
                                                "c.whitelistChannels.list",
                                                value.map((a: any) => a?.value),
                                                data
                                            );
                                            commandConfig[1](data);
                                        }}
                                        additionalProps={{
                                            isMulti: true,
                                            closeMenuOnSelect: false,
                                            onFocus: (value: any) => {
                                                const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                                Dot.set("c.whitelistChannels.active", true, data);
                                                Dot.set("c.blacklistChannels.active", false, data);
                                                commandConfig[1](data);
                                            },
                                        }}
                                        utils={utils}
                                    />
                                    <AppComponent.FormInput
                                        type="select"
                                        label={utils.translation.words.blacklisted_roles}
                                        placeholder={utils.translation.words.select_roles}
                                        value={commandConfig[0].c.blacklistRoles?.list ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(commandConfig[0].c.blacklistRoles.list, guild.roles, true)) : []}
                                        options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))}
                                        onChange={(value: any) => {
                                            const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                            Dot.set(
                                                "c.blacklistRoles.list",
                                                value.map((a: any) => a?.value),
                                                data
                                            );
                                            commandConfig[1](data);
                                        }}
                                        additionalProps={{
                                            isMulti: true,
                                            closeMenuOnSelect: false,
                                            onFocus: (value: any) => {
                                                const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                                Dot.set("c.blacklistRoles.active", true, data);
                                                Dot.set("c.whitelistRoles.active", false, data);
                                                commandConfig[1](data);
                                                commandConfig[1](data);
                                            },
                                        }}
                                        utils={utils}
                                    />
                                    <AppComponent.FormInput
                                        type="select"
                                        label={utils.translation.words.whitelisted_roles}
                                        placeholder={utils.translation.words.select_roles}
                                        value={commandConfig[0].c.whitelistRoles?.list ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(commandConfig[0].c.whitelistRoles.list, guild.roles, true)) : []}
                                        options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))}
                                        onChange={(value: any) => {
                                            const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                            Dot.set(
                                                "c.whitelistRoles.list",
                                                value.map((a: any) => a?.value),
                                                data
                                            );
                                            commandConfig[1](data);
                                        }}
                                        additionalProps={{
                                            isMulti: true,
                                            closeMenuOnSelect: false,
                                            onFocus: (value: any) => {
                                                const data = JSON.parse(JSON.stringify(commandConfig[0]));
                                                Dot.set("c.whitelistRoles.active", true, data);
                                                Dot.set("c.blacklistRoles.active", false, data);
                                                commandConfig[1](data);
                                            },
                                        }}
                                        utils={utils}
                                    />
                                </div>
                            </div>
                            <div className="button flex justify-between mt-3">
                                <button className="hover02 color-secondary" onClick={() => commandPopup[1](false)}>
                                    <Translate content="words.cancel" className="button-text" />
                                </button>
                                <button
                                    className={` hover02 color-success ${Lodash.isEqual(commandConfig[0].p, commandConfig[0].c) ? "disabled" : ""}`}
                                    onClick={() => {
                                        const data = JSON.parse(JSON.stringify(config.data.current));
                                        Dot.set("settings.commands", [...data.settings.commands.filter((a: any) => a.id !== commandConfig[0].c.id), commandConfig[0].c], data);
                                        config.dispatch({ type: "update", path: "settings.commands", data: data.settings.commands });
                                        config.dispatch({ type: "save", data: { guild, update: extra.update, refetch: extra.refetch } });
                                        commandPopup[1](false);
                                    }}>
                                    <Translate content="words.save" className="button-text both" />
                                    <span className="button-icon">
                                        <BiSave />
                                    </span>
                                </button>
                            </div>
                        </div>
                    )
                }
                trigger={commandPopup}
                closeFull
                close
                utils={utils}
            />
        </>
    );
};

export default Section;
