import React from "react";
import Dot from "dot-object";
import Translate from "react-translate-component";
import Select from "react-select";
import { BiInfoCircle } from "react-icons/bi";

import AppComponent from "@app@::@component";

import DashboardComponent from "./index";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "autorole";
    const sectionPath = "modules.autoRole";
    const sectionData = Dot.pick(sectionPath, config.data.current);

    return (
        <section className="dashboard-section">
            <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
            <div className="container mx-auto mb-20">
                {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                {sectionData.active && (
                    <>
                        <div className="background-02 w-full rounded-[8px] px-4 pb-4 pt-2 mb-5 form">
                            <Translate content="routes.dashboard.autorole.instant_roles" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.autorole.instant_roles_i" component="h1" className="text subtitle-4" />
                            <AppComponent.FormInput
                                type="select"
                                htmlFor="auto-role-timed-roles"
                                label={utils.translation.words.roles}
                                placeholder={utils.translation.words.select_roles}
                                value={Utility.DiscordRolesToDropdownOptions(
                                    Utility.GuildDataFilterIgnore(
                                        sectionData.data.timedRoles.map((role: any) => role.id),
                                        guild.roles,
                                        true
                                    )
                                )}
                                options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))}
                                onChange={(value: any) => {
                                    config.dispatch({ type: "update", path: "modules.autoRole.data.timedRoles", data: value.map((a: any) => ({ id: a?.value, addDelay: 0, removeDelay: 0 })) });
                                }}
                                additionalProps={{ isMulti: true, closeMenuOnSelect: false }}
                                utils={utils}
                            />
                        </div>
                        <div className="background-02 w-full rounded-[8px] px-4 pt-2 pb-4 relative form">
                            <div className="switch absolute right-4">
                                <AppComponent.FormInput
                                    type="toggle-switch"
                                    htmlFor="ignore-bots-publish"
                                    value={sectionData.settings.stickyRoles.active}
                                    onChange={(value) => {
                                        config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.active", data: value.target.checked });
                                    }}
                                    additionalProps={{ toggleColor: "background-dark-03" }}
                                    utils={utils}
                                />
                            </div>
                            <Translate content="routes.dashboard.autorole.sticky_roles" component="h1" className="text title-8" />
                            <Translate content="routes.dashboard.autorole.sticky_roles_i" component="h1" className="text subtitle-4" />
                            <>
                                <div className="mb-4" />
                                <h1 className="text title-8 info-icon">
                                    <Translate content="words.blacklist" /> / <Translate content="words.whitelist" />
                                    <AppComponent.Tooltip
                                        content={
                                            <>
                                                <Translate content="words.blacklist" component="code" /> <Translate content="routes.dashboard.autorole.sticky_blacklist_i" />
                                                <br />
                                                <Translate content="words.whitelist" component="code" /> <Translate content="routes.dashboard.autorole.sticky_whitelist_i" />
                                            </>
                                        }
                                        children={<span className="icon" children={<BiInfoCircle />} />}
                                    />
                                </h1>
                                <div className="grid-col-2 gap-select mt-0 items-end">
                                    <AppComponent.FormInput type="select" disabled={!sectionData.settings.stickyRoles.active} htmlFor="auto-role-sticky-role-blacklist" label={utils.translation.words.blacklisted_roles} placeholder={utils.translation.words.select_roles} value={sectionData.settings.stickyRoles.type === 1 ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(sectionData.settings.stickyRoles.list, guild.roles, true)) : []} options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.list", data: value.map((a: any) => a?.value) })} additionalProps={{ isMulti: true, closeMenuOnSelect: false, onFocus: (value: any) => config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.type", data: 1 }), onBlur: (value: any) => (sectionData.settings.stickyRoles.list.length < 1 ? config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.type", data: 0 }) : null) }} utils={utils} />
                                    <AppComponent.FormInput type="select" disabled={!sectionData.settings.stickyRoles.active} htmlFor="auto-role-sticky-role-whitelist" label={utils.translation.words.whitelisted_roles} placeholder={utils.translation.words.select_roles} value={sectionData.settings.stickyRoles.type === 2 ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(sectionData.settings.stickyRoles.list, guild.roles, true)) : []} options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.list", data: value.map((a: any) => a?.value) })} additionalProps={{ isMulti: true, closeMenuOnSelect: false, onFocus: (value: any) => config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.type", data: 2 }), onBlur: (value: any) => (sectionData.settings.stickyRoles.list.length < 1 ? config.dispatch({ type: "update", path: "modules.autoRole.settings.stickyRoles.type", data: 0 }) : null) }} utils={utils} />
                                </div>
                            </>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default Section;
