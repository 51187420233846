import React from "react";
import ReactMoment from "react-moment";
import AppComponent from "@app@::@component";
import Translate from "react-translate-component";
import Utility from "@utility@::@index";

const DiscordMessagePreviewer: React.FC<{ guild: any; message: any; utils: any }> = ({ guild, message, utils }) => (
    <div className="discord-message">
        <div className="header-wrapper">
            <img src={message.author?.avatarUrl ? message.author.avatarUrl : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="" className="avatar" />
            <h1 className="username">{message.author?.username ? message.author.username : <Translate content="words.unknown_user" />}</h1>
            {message.author?.bot && (
                <span className="bot-tag">
                    {message.author.botVerified && (
                        <AppComponent.Tooltip
                            content={<Translate content="words.verified_bot" />}
                            delay={0}
                            children={
                                <svg aria-label="Verified Bot" className="bot-tag-verified" aria-hidden="false" role="img" width="16" height="16" viewBox="0 0 16 15.2">
                                    <path d="M7.4,11.17,4,8.62,5,7.26l2,1.53L10.64,4l1.36,1Z" fill="currentColor"></path>
                                </svg>
                            }
                        />
                    )}
                    <Translate content="words.bot" className="bot-text" />
                </span>
            )}
            <div className="time" children={message.createdTimestamp ? <ReactMoment locale={utils.locale} calendar children={new Date(message.createdTimestamp)} /> : <ReactMoment locale={utils.locale} calendar />} />
        </div>
        {message.content && <div className="message-content mb-1" dangerouslySetInnerHTML={{ __html: Utility.FormatDiscordText(message.content, guild) }} />}
        {message.embeds?.length > 0 && message.embeds.map((embed: any, index: any) => <AppComponent.DiscordEmbedPreviewer key={index} guild={guild} embed={embed} utils={utils} />)}
        {message.components?.length > 0 && (
            <div className="components-wrapper">
                {Utility.SplitArray(message.components, 5).map((components: any, index: any) => (
                    <div className="components" key={index}>
                        {components.map((component: any, index: any) => (
                            <AppComponent.DiscordComponentPreviewer key={index} component={component} utils={utils} />
                        ))}
                    </div>
                ))}
            </div>
        )}
    </div>
);

export default DiscordMessagePreviewer;
