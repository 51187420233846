import React from "react";
import ReactDom from "react-dom";
import Dot from "dot-object";
import { BiXCircle } from "react-icons/bi";

const portal = document.getElementById("portal");

const PopUp: React.FC<{ id: string; background?: string; children: any; trigger: [boolean, React.Dispatch<boolean>]; closeFull?: boolean; close?: boolean; highIndex?: boolean; styleBox?: React.CSSProperties; outside?: any; onClose?: Function; utils: any }> = (props) => {
    Dot.set(`activePopups.${props.id}`, props.trigger[0], props.utils);
    Object.values(props.utils.activePopups).includes(true) ? (document.body.style.overflowY = "hidden") : (document.body.style.overflowY = "auto");
    return props.trigger[0] && portal ? (
        ReactDom.createPortal(
            <div className="popup" style={{ zIndex: props.highIndex ? 1001 : 105 }}>
                <div className="popup-container">
                    {props.closeFull && (
                        <div
                            className="close-full"
                            onClick={() => {
                                props.trigger[1](false);
                                if (props.onClose) props.onClose();
                            }}
                        />
                    )}
                    <div className="popup-element">
                        <div className={`popup-data ${props.background || "background-03"}`} style={props.styleBox}>
                            {props.children}
                        </div>
                    </div>
                </div>
                {props.close && (
                    <>
                        <button
                            className="close"
                            onClick={() => {
                                props.trigger[1](false);
                                if (props.onClose) props.onClose();
                            }}>
                            <BiXCircle />
                        </button>
                    </>
                )}
                {props.outside && <div className="absolute left-0 top-0 w-[100vw] h-[100vh]">{props.outside}</div>}
            </div>,
            portal
        )
    ) : (
        <></>
    );
};

export default PopUp;
