import React from "react";
import { Helmet } from "react-helmet";

import AppComponent from "@app@::@component";

import LeaderboardComponent from "@route@::@leaderboard:id:component";
import LeaderboardHook from "@route@::@leaderboard:id:hook";

const Path = "/leaderboard/:id";
const Options = { caseSensitive: true };

const Home: React.FC<PageProps> = ({ utils }) => {
    let guildId = utils.use.Match(Path).params.id; // Guild id

    const { loading, data, refetch } = LeaderboardHook.Query(guildId, "leaderboard");

    if (loading) return <AppComponent.Loading />; // Return loading component if loading is active
    else if (!data || !data.guild) return utils.use.Navigate("/"); // Redirects to the home page if theirs no data

    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body">
                <Helmet children={<title>Leaderboard – {data.guild.name}</title>} />
                <AppComponent.Header utils={utils} />
                <AppComponent.Animation.ScreenCover />
                <AppComponent.ProfileMenu utils={utils} profile={data.profile} path={Path} />
                <LeaderboardComponent.Section utils={utils} data={data} extra={{ refetch }} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: Home, Path, Options };

export default Page;
