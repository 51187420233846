import React from "react";

import DashboardComponent from "./index";

import AppData from "@app@::@data";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

export const Section: React.FC<DashboardInterface.SectionProps> = ({ utils, data, config, extra }) => {
    let newExtra = {
        ...extra,
        roleOptions: (filter: any) => data.guild.roles.filter(filter),
        channelOptions: (filter?: any[]) => {
            const options = [
                { label: AppData.translations.get(utils.locale)?.words.uncategorized, options: data.guild.channels.filter((channel) => (filter ? filter.includes(channel.type) : channel.type !== 4) && !channel.parent_id).map((channel) => ({ label: Utility.ChannelOptionSvgTypeAdd(channel), value: channel.id, search: channel.name })) },
                ...data.guild.channels
                    .filter((channel) => channel.type === 4)
                    .map((category) => {
                        let categoryData: any = { label: category.name, options: [] };
                        data.guild.channels
                            .filter((channel) => (filter ? filter.includes(channel.type) : channel.type !== 4) && channel.parent_id === category.id)
                            .map((channel) =>
                                categoryData.options.push({
                                    label: Utility.ChannelOptionSvgTypeAdd(channel),
                                    value: channel.id,
                                    search: channel.name,
                                })
                            );
                        if (categoryData.options.length < 1) return null;
                        return categoryData;
                    })
                    .filter((data) => data !== null),
            ];

            return options;
        },
    };
    return (
        <section className="content-area">
            {config.data.current && (
                <div className="container-default">
                    {extra.activeSection === "home" && <DashboardComponent.HomeSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "leaderboard" && <DashboardComponent.LeaderboardSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "settings" && <DashboardComponent.SettingsSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {/* {extra.activeSection === "core" && <DashboardComponent.CoreSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />} */}

                    {extra.activeSection === "moderation" && <DashboardComponent.ModerationSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "automod" && <DashboardComponent.AutoModSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "logging" && <DashboardComponent.LoggingSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}

                    {extra.activeSection === "chatbot" && <DashboardComponent.ChatbotSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "counting" && <DashboardComponent.CountingSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "cyclicquestions" && <DashboardComponent.CyclicQuestionsSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}

                    {extra.activeSection === "greeter" && <DashboardComponent.GreeterSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "selfrole" && <DashboardComponent.SelfRoleSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "leveling" && <DashboardComponent.LevelingSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "autorole" && <DashboardComponent.AutoRoleSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                    {extra.activeSection === "autopublish" && <DashboardComponent.AutoPublishSection utils={utils} profile={data.profile} config={config} guild={data.guild} commands={data.commands} extra={newExtra} />}
                </div>
            )}
        </section>
    );
};
