import React from "react";
import Scrollreveal from "scrollreveal";

const ScrollReveal: React.FC<{ classname?: string; children: any; data: ScrollRevealObjectOptions }> = ({ classname, children, data }) => {
    const sectionRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (sectionRef.current) Scrollreveal().reveal(sectionRef.current, data);
    }, [data]);
    return <section ref={sectionRef} className={"scroll-section " + classname} data-testid="section" children={children} />;
};

export default ScrollReveal;
