import Dot from "dot-object";
import Utility from "@utility@::@index";

export const RangeArray = (array: any[], from: number, to: number): any[] => (from <= to ? [...array].splice(Math.min(from, 0), Math.min(to - from, array.length - from)) : RangeArray(array, to, from));

export const RandomElement = (array: any[]): any => array[Utility.RandomInt(0, array.length - 1)];

export const RandomElements = (array: any[], count: number): any[] => {
    if (count >= array.length) return array;
    let r = [...array];
    for (let i = 0; i < array.length - count; i++) r.splice(Utility.RandomInt(0, r.length - 1), 1);
    return r;
};

export const StringifyArray = (array: any[], sep1: string = ", ", sep2: string = " & "): string => (array.length === 1 ? array[0] : RangeArray(array, 0, array.length - 1).join(sep1) + sep2 + array[array.length - 1]);

export const RemoveDuplicates = (array: any[], path?: string): any[] => {
    if (array.length === 0 || array.length === 1) return array;
    const added: any[] = [];
    const newArr: any[] = [];
    for (let i = 0; i < array.length; i++) {
        if (path) {
            const element = Dot.pick(path, array[i]);
            if (added.includes(element)) continue;
            added.push(element);
            newArr.push(array[i]);
        } else {
            if (newArr.includes(array[i])) continue;
            newArr.push(array[i]);
        }
    }
    return newArr;
};

export const TrimArray = (array: any[], length: number, message?: any): any[] => {
    let newArray = [];
    if (array.length <= length) {
        newArray = array;
    } else {
        newArray = [...[...array].splice(0, length)];
    }
    if (array.length > length) {
        if (message !== null) {
            newArray.push(message ? message.replace(`{num}`, (array.length - length).toString()) : `+${array.length - length} more`);
        }
    }
    return newArray;
};

export const SortByName = (array: any[], prop: any): any[] => {
    return array.sort((a, b) => {
        if (prop) return a[prop].toLowerCase() > b[prop].toLowerCase() ? 1 : -1;
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    });
};

export const ShuffleArray = (array: any[]): any[] => {
    let b = [...array],
        c = b.length - 1,
        d,
        e;
    while (c > 0) {
        d = Math.round(Math.random() * c);
        c--;
        e = b[d];
        b[d] = b[c];
        b[c] = e;
    }
    return b;
};

export const SplitArray = (arr: any[], size: number): any[][] => {
    let array = [];
    for (let i = 0; i < arr.length; i += size) {
        array.push(arr.slice(i, i + size));
    }
    return array;
};

export const GuildDataFilterIgnore = (ignoreArray: any[], guildDataArray: any[], justData?: boolean) => {
    return ignoreArray
        .map((id: Snowflake) => {
            const ignore = guildDataArray.find((ignore) => ignore.id === id);
            if (!ignore) return null;
            return justData ? ignore : { label: Utility.ChannelOptionSvgTypeAdd(ignore), value: ignore?.id, data: ignore };
        })
        .filter((data: any) => data !== null);
};

export const ToggleStates = (states: any[]) => states.map((state) => state[1](!state[0]));

export const DiscordRolesToDropdownOptions = (arr: any[]) =>
    arr.map((role: any) => ({
        label: (
            <div className="flex items-center gap-2">
                <div className={`w-[12px] h-[12px] rounded-[10px]`} style={{ backgroundColor: role.color === 0 ? "#99AAB5" : `#${role.color.toString(16)?.padStart(6, "0")}` }} />
                <span className="text mb-[2px]">{role.name}</span>
            </div>
        ),
        value: role.id,
        search: role.name,
    }));

export const GroupArray = (arr: any[], key: string) => {
    const keyParts = key.split(".");
    let mapObj = arr.reduce((obj, rootVal) => {
        let val = rootVal;
        for (let keyPart of keyParts) val = val[keyPart];
        if (obj[val]) obj[val].push(rootVal);
        else obj[val] = [rootVal];
        return obj;
    }, {});
    return Object.keys(mapObj).map((idx) => ({
        [key]: idx,
        value: mapObj[idx],
    }));
};
