import React from "react";
import Dot from "dot-object";
import Translate from "react-translate-component";
import Select from "react-select";
import { BiBox, BiShowAlt, BiTrash } from "react-icons/bi";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import DashboardComponent from "./index";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "greeter";
    const sectionPath = "modules.greeter";
    const sectionData = Dot.pick(sectionPath, config.data.current);

    const showEmbedBuilder01 = React.useState<boolean>(false);
    const showEmbedBuilder02 = React.useState<boolean>(false);
    const showEmbedBuilder03 = React.useState<boolean>(false);
    const showEmbedBuilder04 = React.useState<boolean>(false);
    const showPreviewPopup = React.useState<boolean>(false);
    const previewPopupData = React.useState<any>();

    const activeInput = React.useState<any>(null);

    const addVariableQuickSet = (value: string) => {
        const start = activeInput[0]?.element.selectionStart ?? 0;
        const end = activeInput[0]?.element.selectionEnd ?? 0;
        const message = Utility.SpliceSlice(activeInput[0]?.element.value, start, end - start, `{${value}}`);
        config.dispatch({ type: "update", path: activeInput[0]?.path, data: message });
        activeInput[0].element.focus();
    };

    return (
        <>
            <section className="dashboard-section">
                <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
                <div className="container mx-auto mb-20">
                    {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                    {sectionData.active && (
                        <>
                            <div className="background-02 w-full rounded-[8px] p-4 pt-2 mb-5 form">
                                <AppComponent.FormInput type="select" htmlFor="greeter-channel" label={utils.translation.words.channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.channelId], guild.channels)} options={extra.channelOptions([0, 5])} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.greeter.settings.channelId", data: value ? value.value : null })} additionalProps={{ isClearable: true }} utils={utils} />
                            </div>
                            <div className="greeter-grid">
                                <div className="greeter-grid-in">
                                    <div className="background-02 w-full rounded-[8px] px-4 py-2">
                                        <Translate content="routes.dashboard.greeter.welcome_message" component="h1" className="text title-8" />
                                        <Translate content="routes.dashboard.greeter.welcome_message_i" component="h1" className="text subtitle-4" />
                                        <div className="form mb-2">
                                            <AppComponent.FormInput type="textarea" label={utils.translation.words.content} placeholder={SetMessageVariables(utils.translation.routes.dashboard.greeter.join_placeholder, "{}", profile, guild).content} htmlFor="greeter-welcome-message-content" value={sectionData.data.joinMessage.content} onChange={(value) => config.dispatch({ type: "update", path: "modules.greeter.data.joinMessage.content", data: value.target.value })} additionalProps={{ maxLength: 15000, onFocus: (element: any) => activeInput[1]({ path: "modules.greeter.data.joinMessage.content", element: element.target }) }} utils={utils} />
                                        </div>
                                        <div className="mb-2 flex justify-between">
                                            <div className="button flex justify-between gap-2">
                                                <button className="hover02 color-blurple" onClick={() => showEmbedBuilder01[1](!showEmbedBuilder01[0])}>
                                                    <Translate content="words.embed" className="button-text both" />
                                                    <span className="button-icon">
                                                        <BiBox />
                                                    </span>
                                                </button>
                                                <button
                                                    className="hover02 color-blurple"
                                                    onClick={() => {
                                                        showPreviewPopup[1](true);
                                                        previewPopupData[1]({ content: sectionData.data.joinMessage.content, embeds: [sectionData.data.joinMessage.embed] });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiShowAlt />
                                                    </span>
                                                </button>
                                            </div>
                                            {showEmbedBuilder01[0] && (
                                                <div className="button flex justify-between">
                                                    <button className="hover02 color-danger" onClick={() => config.dispatch({ type: "update", path: "modules.greeter.data.joinMessage.embed", data: AppData.other.embedStructure })}>
                                                        <Translate content="words.clear" className="button-text both" />
                                                        <span className="button-icon">
                                                            <BiTrash />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {showEmbedBuilder01[0] && (
                                            <>
                                                <Translate content="words.embed" component="h1" className="text font-2 text-[20px] mb-1" />
                                                <AppComponent.DiscordEmbedBuilder profile={profile} id={1} bg="bg-2" maxSizeAll={15000} noSizeCheck utils={utils} embed={sectionData.data.joinMessage.embed} update={(embed: any) => config.dispatch({ type: "update", path: "modules.greeter.data.joinMessage.embed", data: embed })} activeInputElement={(data: any) => activeInput[1]({ path: `modules.greeter.data.joinMessage.embed.${data.path}`, element: data.element ? data.element.target : null })} />
                                                <div className="mb-2" />
                                            </>
                                        )}
                                    </div>
                                    <div className="background-02 w-full rounded-[8px] px-4 py-2">
                                        <Translate content="routes.dashboard.greeter.goodbye_message" component="h1" className="text title-8" />
                                        <Translate content="routes.dashboard.greeter.goodbye_message_i" component="h1" className="text subtitle-4" />{" "}
                                        <div className="form mb-2">
                                            <AppComponent.FormInput type="textarea" label={utils.translation.words.content} placeholder={SetMessageVariables(utils.translation.routes.dashboard.greeter.leave_placeholder, "{}", profile, guild).content} htmlFor="greeter-goodbye-message-content" value={sectionData.data.leaveMessage.content} onChange={(value) => config.dispatch({ type: "update", path: "modules.greeter.data.leaveMessage.content", data: value.target.value })} additionalProps={{ maxLength: 15000, onFocus: (element: any) => activeInput[1]({ path: "modules.greeter.data.leaveMessage.content", element: element.target }) }} utils={utils} />
                                        </div>
                                        <div className="mb-2 flex justify-between">
                                            <div className="button flex justify-between gap-2">
                                                <button className="hover02 color-blurple" onClick={() => showEmbedBuilder02[1](!showEmbedBuilder02[0])}>
                                                    <Translate content="words.embed" className="button-text both" />
                                                    <span className="button-icon">
                                                        <BiBox />
                                                    </span>
                                                </button>
                                                <button
                                                    className="hover02 color-blurple"
                                                    onClick={() => {
                                                        showPreviewPopup[1](true);
                                                        previewPopupData[1]({ content: sectionData.data.leaveMessage.content, embeds: [sectionData.data.leaveMessage.embed] });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiShowAlt />
                                                    </span>
                                                </button>
                                            </div>
                                            {showEmbedBuilder02[0] && (
                                                <div className="button flex justify-between">
                                                    <button className="hover02 color-danger" onClick={() => config.dispatch({ type: "update", path: "modules.greeter.data.leaveMessage.embed", data: AppData.other.embedStructure })}>
                                                        <Translate content="words.clear" className="button-text both" />
                                                        <span className="button-icon">
                                                            <BiTrash />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {showEmbedBuilder02[0] && (
                                            <>
                                                <Translate content="words.embed" component="h1" className="text font-2 text-[20px] mb-1" />
                                                <AppComponent.DiscordEmbedBuilder profile={profile} id={2} bg="bg-2" maxSizeAll={15000} noSizeCheck utils={utils} embed={sectionData.data.leaveMessage.embed} update={(embed: any) => config.dispatch({ type: "update", path: "modules.greeter.data.leaveMessage.embed", data: embed })} activeInputElement={(data: any) => activeInput[1]({ path: `modules.greeter.data.leaveMessage.embed.${data.path}`, element: data.element ? data.element.target : null })} />
                                                <div className="mb-2" />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="greeter-grid-in">
                                    <div className="background-02 w-full rounded-[8px] px-4 py-2">
                                        <Translate content="routes.dashboard.greeter.ban_message" component="h1" className="text title-8" />
                                        <Translate content="routes.dashboard.greeter.ban_message_i" component="h1" className="text subtitle-4" />
                                        <div className="form mb-2">
                                            <AppComponent.FormInput type="textarea" label={utils.translation.words.content} placeholder={SetMessageVariables(utils.translation.routes.dashboard.greeter.ban_placeholder, "{}", profile, guild).content} htmlFor="greeter-ban-message-content" value={sectionData.data.banMessage.content} onChange={(value) => config.dispatch({ type: "update", path: "modules.greeter.data.banMessage.content", data: value.target.value })} additionalProps={{ maxLength: 15000, onFocus: (element: any) => activeInput[1]({ path: "modules.greeter.data.banMessage.content", element: element.target }) }} utils={utils} />
                                        </div>
                                        <div className="mb-2 flex justify-between">
                                            <div className="button flex justify-between gap-2">
                                                <button className="hover02 color-blurple" onClick={() => showEmbedBuilder03[1](!showEmbedBuilder03[0])}>
                                                    <Translate content="words.embed" className="button-text both" />
                                                    <span className="button-icon">
                                                        <BiBox />
                                                    </span>
                                                </button>
                                                <button
                                                    className="hover02 color-blurple"
                                                    onClick={() => {
                                                        showPreviewPopup[1](true);
                                                        previewPopupData[1]({ content: sectionData.data.banMessage.content, embeds: [sectionData.data.banMessage.embed] });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiShowAlt />
                                                    </span>
                                                </button>
                                            </div>
                                            {showEmbedBuilder03[0] && (
                                                <div className="button flex justify-between">
                                                    <button className="hover02 color-danger" onClick={() => config.dispatch({ type: "update", path: "modules.greeter.data.banMessage.embed", data: AppData.other.embedStructure })}>
                                                        <Translate content="words.clear" className="button-text both" />
                                                        <span className="button-icon">
                                                            <BiTrash />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {showEmbedBuilder03[0] && (
                                            <>
                                                <Translate content="words.embed" component="h1" className="text font-2 text-[20px] mb-1" />
                                                <AppComponent.DiscordEmbedBuilder profile={profile} id={3} bg="bg-2" maxSizeAll={15000} noSizeCheck utils={utils} embed={sectionData.data.banMessage.embed} update={(embed: any) => config.dispatch({ type: "update", path: "modules.greeter.data.banMessage.embed", data: embed })} activeInputElement={(data: any) => activeInput[1]({ path: `modules.greeter.data.banMessage.embed.${data.path}`, element: data.element ? data.element.target : null })} />
                                                <div className="mb-2" />
                                            </>
                                        )}
                                    </div>
                                    <div className="background-02 w-full rounded-[8px] px-4 py-2 relative">
                                        <div className="switch absolute right-4">
                                            <AppComponent.FormInput
                                                type="toggle-switch"
                                                htmlFor="direct-message-toggle"
                                                value={sectionData.settings.directMessage}
                                                onChange={(value) => {
                                                    config.dispatch({ type: "update", path: "modules.greeter.settings.directMessage", data: value.target.checked });
                                                }}
                                                additionalProps={{ toggleColor: "background-dark-03" }}
                                                utils={utils}
                                            />
                                        </div>
                                        <Translate content="routes.dashboard.greeter.direct_message" component="h1" className="text title-8" />
                                        <Translate content="routes.dashboard.greeter.direct_message_i" component="h1" className="text subtitle-4" />
                                        <div className="form mb-2">
                                            <AppComponent.FormInput type="textarea" disabled={!sectionData.settings.directMessage} label={utils.translation.words.content} placeholder={SetMessageVariables(utils.translation.routes.dashboard.greeter.direct_placeholder, "{}", profile, guild).content} htmlFor="greeter-direct-message-content" value={sectionData.data.directMessage.content} onChange={(value) => config.dispatch({ type: "update", path: "modules.greeter.data.directMessage.content", data: value.target.value })} additionalProps={{ maxLength: 15000, onFocus: (element: any) => activeInput[1]({ path: "modules.greeter.data.directMessage.content", element: element.target }) }} utils={utils} />
                                        </div>
                                        <div className="mb-2 flex justify-between">
                                            <div className="button flex justify-between gap-2">
                                                <button className={`hover02 color-blurple ${sectionData.settings.directMessage ? "" : "disabled"}`} onClick={() => showEmbedBuilder04[1](!showEmbedBuilder04[0])}>
                                                    <Translate content="words.embed" className="button-text both" />
                                                    <span className="button-icon">
                                                        <BiBox />
                                                    </span>
                                                </button>
                                                <button
                                                    className={`hover02 color-blurple ${sectionData.settings.directMessage ? "" : "disabled"}`}
                                                    onClick={() => {
                                                        showPreviewPopup[1](true);
                                                        previewPopupData[1]({ content: sectionData.data.directMessage.content, embeds: [sectionData.data.directMessage.embed] });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiShowAlt />
                                                    </span>
                                                </button>
                                            </div>
                                            {showEmbedBuilder04[0] && sectionData.settings.directMessage && (
                                                <div className="button flex justify-between">
                                                    <button className="hover02 color-danger" onClick={() => config.dispatch({ type: "update", path: "modules.greeter.data.directMessage.embed", data: AppData.other.embedStructure })}>
                                                        <Translate content="words.clear" className="button-text both" />
                                                        <span className="button-icon">
                                                            <BiTrash />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {showEmbedBuilder04[0] && sectionData.settings.directMessage && (
                                            <>
                                                <Translate content="words.embed" component="h1" className="text font-2 text-[20px] mb-1" />
                                                <AppComponent.DiscordEmbedBuilder profile={profile} id={4} bg="bg-2" maxSizeAll={15000} noSizeCheck utils={utils} embed={sectionData.data.directMessage.embed} update={(embed: any) => config.dispatch({ type: "update", path: "modules.greeter.data.directMessage.embed", data: embed })} activeInputElement={(data: any) => activeInput[1]({ path: `modules.greeter.data.directMessage.embed.${data.path}`, element: data.element ? data.element.target : null })} />
                                                <div className="mb-2" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={`greeter-variables ${activeInput[0] && sectionData.active ? "" : "closed"}`}>
                    <div className="bar">
                        <span className="text font-2 background-05 py-[5px] px-[10px] rounded-[6px]">Variables</span>
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_member_i" />
                                    <br />
                                    <Translate content="words.example" />: <code children={`@${profile.settings.user.username}`} />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("member")}>
                                    <Translate content="routes.dashboard.greeter.var_member" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_member_tag_i" />
                                    <br />
                                    <Translate content="words.example" />: <code children={`${profile.settings.user.username}#${profile.settings.user.discriminator}`} />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("member:tag")}>
                                    <Translate content="routes.dashboard.greeter.var_member_tag" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_member_avatar_i" />
                                    <br />
                                    <div className="text-center mt-1">
                                        <Translate content="words.example" />:
                                    </div>
                                    <img className="w-[100px] h-[100px] mx-auto rounded-[8px] mt-1" src={profile.settings.user.avatar ? Utility.GetUserAvatarUrl(profile._id, profile.settings.user.avatar) : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="avatar" />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("member:ava")}>
                                    <Translate content="routes.dashboard.greeter.var_member_avatar" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_server_i" />
                                    <br />
                                    <Translate content="words.example" />: <code children={guild.name} />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("server")}>
                                    <Translate content="routes.dashboard.greeter.var_server" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_server_icon_i" />
                                    <br />
                                    <div className="text-center mt-1">
                                        <Translate content="words.example" />:
                                    </div>
                                    <img className="w-[100px] h-[100px] mx-auto rounded-[8px] mt-1" src={guild.icon ? Utility.GetGuildIconUrl(guild.id, guild.icon) + "?size=512" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("server:ico")}>
                                    <Translate content="routes.dashboard.greeter.var_server_icon" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_total_i" />
                                    <br />
                                    <Translate content="words.example" />: <code children={guild.approximate_member_count} />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("total")}>
                                    <Translate content="routes.dashboard.greeter.var_total" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_total_pos_i" />
                                    <br />
                                    <Translate content="words.example" />: <code children={Utility.FormatNumber(guild.approximate_member_count)} />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet("total:pos")}>
                                    <Translate content="routes.dashboard.greeter.var_total_pos" />
                                </button>
                            }
                        />
                        <AppComponent.Tooltip
                            content={
                                <>
                                    <Translate content="routes.dashboard.greeter.var_random_i" />
                                    <br />
                                    <Translate content="words.example" />: <code children={`Hey there {random:Cutie/Bean/Friend} welcome to the server!`} />
                                </>
                            }
                            children={
                                <button onClick={() => addVariableQuickSet(`random:${utils.translation.words.option} 1/${utils.translation.words.option} 2`)}>
                                    <Translate content="routes.dashboard.greeter.var_random" />
                                </button>
                            }
                        />
                    </div>
                </div>
            </section>
            {previewPopupData[0] && <AppComponent.PopUp id="EmbedPreviewPopup" trigger={showPreviewPopup} closeFull close utils={utils} background={"discord-bg noselect"} children={<AppComponent.DiscordMessagePreviewer guild={guild} message={{ author: { username: "Titan-Bot", bot: true, botVerified: true, avatarUrl: AppData.project.client.botAvatar }, ...SetMessageVariables(previewPopupData[0].content, previewPopupData[0].embeds, profile, guild) }} utils={utils} />} />}
        </>
    );
};

const SetMessageVariables = (content: string, embed: any, profile: any, guild: any) => {
    embed = JSON.parse(JSON.stringify(embed[0]));
    const ReplaceVarAndTrimString = (string: string, length: number) =>
        Utility.TrimString(
            Utility.SubstituteRandomStrings(
                string
                    .replace(/{member}/g, `<@!${profile._id}>`)
                    .replace(/{member:tag}/g, `${profile.settings.user.username}#${profile.settings.user.discriminator}`)
                    .replace(/{server}/g, guild.name)
                    .replace(/{total}/g, guild.approximate_member_count.toString())
                    .replace(/{total:pos}/g, Utility.FormatNumber(guild.approximate_member_count))
                    .replace(/{member:ava}/g, profile.settings.user.avatar ? Utility.GetUserAvatarUrl(profile._id, profile.settings.user.avatar) : `${process.env.PUBLIC_URL}/media/png/discord.png`)
                    .replace(/{server:ico}/g, guild.icon ? Utility.GetGuildIconUrl(guild.id, guild.icon) + "?size=1024" : `${process.env.PUBLIC_URL}/media/png/discord.png`)
            ),
            length
        );
    if (embed?.author?.name) embed.author.name = ReplaceVarAndTrimString(embed.author.name, 256);
    if (embed?.author?.url) embed.author.url = ReplaceVarAndTrimString(embed.author.url, 10000);
    if (embed?.author?.icon_url) embed.author.icon_url = ReplaceVarAndTrimString(embed.author.icon_url, 10000);
    if (embed?.title) embed.title = ReplaceVarAndTrimString(embed.title, 256);
    if (embed?.description) embed.description = ReplaceVarAndTrimString(embed.description, 4096);
    if (embed?.url) embed.url = ReplaceVarAndTrimString(embed.url, 10000);
    if (embed?.image?.url) embed.image.url = ReplaceVarAndTrimString(embed.image.url, 10000);
    if (embed?.thumbnail?.url) embed.thumbnail.url = ReplaceVarAndTrimString(embed.thumbnail.url, 10000);
    if (embed?.footer?.text) embed.footer.text = ReplaceVarAndTrimString(embed.footer.text, 2048);
    if (embed?.footer?.icon_url) embed.footer.icon_url = ReplaceVarAndTrimString(embed.footer.icon_url, 10000);
    embed?.fields?.map((field: any) => {
        if (field?.name) field.name = ReplaceVarAndTrimString(field.name, 256);
        if (field?.value) field.value = ReplaceVarAndTrimString(field.value, 1024);
        return field;
    });
    const validEmbed = Utility.IsValidDiscordEmbed(embed);
    return content.length > 0 || validEmbed.success
        ? {
              content: ReplaceVarAndTrimString(content, 2000),
              embeds: validEmbed.success ? [validEmbed.embed] : [],
          }
        : {};
};

export default Section;
