import { useEffect } from "react";

const useClickOutside = (func: any, ref: any) => {
    useEffect(() => {
        let handler = (event: MouseEvent) => {
            if (!ref.current?.contains(event.target)) func();
        };

        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        };
    });

    return ref;
};

export default useClickOutside;
