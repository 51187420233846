import React, { useEffect, useState } from "react";
import Select from "react-select";
import Cookies from "js-cookie";
import Translate from "react-translate-component";
import Counterpart from "counterpart";
import { BiMoon, BiSun, BiCopyright } from "react-icons/bi";
import { FaDiscord, FaTwitter, FaYoutube } from "react-icons/fa";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import Utility from "@utility@::@index";

const Footer: React.FC<{ utils: any }> = ({ utils }) => {
    const Theme = Cookies.get("theme");
    const ThemeCheckbox = Theme === "dark-theme" ? true : false;
    const langOptions = useState<any[]>([]);
    const defaultLang = useState<any>();

    const updateLang = () => {
        const Language = Cookies.get("language");
        const options = [];
        for (let [key, value] of AppData.translations.entries()) {
            const label = `${AppData.translations.get(Language || "en")?.other_name[key as keyof typeof value.other_name]}${key !== Language ? " — " + value?.other_name[key as keyof typeof value.other_name] : ""}`;
            options.push({
                value: key,
                label: (
                    <div className="flex gap-2 items-center">
                        <img className="max-h-[25px] rounded-[6px]" src={`${process.env.PUBLIC_URL}/media/png/${key}.png`} alt="" />
                        {label}
                    </div>
                ),
                search: label,
            });
        }
        langOptions[1](
            options.sort((a: any, b: any) => {
                return a.value.localeCompare(b.value);
            })
        );
        defaultLang[1](options.find((a) => a.value === (Language || "en")));
    };

    useEffect(() => updateLang(), []); // eslint-disable-line

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="content-footer">
                    <div className="profil">
                        <div className="logo-area">
                            <img src={`${process.env.PUBLIC_URL}/media/svg/logo02.svg`} alt="logo" />
                            <span className="logo-name">Titan-Bot</span>
                            <div>
                                <input defaultChecked={ThemeCheckbox} type="checkbox" className="theme-toggle" id="theme-toggle" onChange={(value) => (value.target.checked ? (document.body.className = "dark-theme") && Cookies.set("theme", "dark-theme") : (document.body.className = "light-theme") && Cookies.set("theme", "light-theme"))} />
                                <label htmlFor="theme-toggle" className="theme-toggle-label">
                                    <BiMoon className="moon" />
                                    <BiSun className="sun" />
                                    <div className="ball"></div>
                                </label>
                            </div>
                        </div>
                        <div className="description-area">
                            <Translate content="components.footer.description" component="p" />
                        </div>
                        <div className="social-media flex">
                            <a href="https://discord.gg/j5pkCEff8P" rel="noreferrer" target="_blank">
                                <FaDiscord />
                            </a>
                            <a href="https://twitter.com/DeathStormTitan" rel="noreferrer" target="_blank">
                                <FaTwitter />
                            </a>
                            <a href="https://youtube.com/@DeathStormTitan" rel="noreferrer" target="_blank">
                                <FaYoutube />
                            </a>
                        </div>
                        <AppComponent.FormInput
                            className="language my-5 max-h-[100px] pr-4"
                            type="select"
                            htmlFor="website-language"
                            value={defaultLang[0]}
                            options={langOptions[0]}
                            onChange={(value: any) => {
                                if (window.confirm(utils.translation.errors.reload_warning)) {
                                    Cookies.set("language", value?.value ? value.value : "en") && Counterpart.setLocale(value?.value ? value.value : "en");
                                    updateLang();
                                    document.location.reload();
                                }
                            }}
                            dark
                            utils={utils}
                        />
                    </div>
                    <div className="service-area">
                        <ul className="service-header">
                            <li className="service-name">Titan-Bot</li>
                            <li>
                                <Translate content="components.footer.col1.link1" component="a" href="/commands" />
                            </li>
                            <li>
                                <Translate content="components.footer.col1.link2" component="a" href="/premium" />
                            </li>
                            {/* <li className="disabled">
                                <Translate content="components.footer.col1.link3" component="a" href="/contact?type=feedback" />
                            </li> */}
                            <li>
                                <Translate content="components.footer.col1.link4" component="a" href="https://discord.com/invite/j5pkCEff8P" rel="noreferrer" target="_blank" />
                            </li>
                        </ul>
                        <ul className="service-header">
                            <Translate content="components.footer.col2.name" component="li" className="service-name" />
                            <li className="disabled">
                                <Translate content="components.footer.col2.link1" component="a" href="/" />
                            </li>
                            <li className="disabled">
                                <Translate content="components.footer.col2.link2" component="a" href="/" />
                            </li>
                            <li className="disabled">
                                <Translate content="components.footer.col2.link3" component="a" href="/" />
                            </li>
                        </ul>
                        <ul className="service-header">
                            <Translate content="components.footer.col3.name" component="li" className="service-name" />
                            <li className="disabled">
                                <Translate content="components.footer.col3.link1" component="a" href="/economy" />
                            </li>
                            <li className="disabled">
                                <Translate content="components.footer.col3.link2" component="a" href="/leaderboard" />
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="footer-bottom">
                    <div className="copyright">
                        <BiCopyright />
                        <span>2023 Titan Bot | Titan Studios ▪ Made For You</span>
                    </div>
                    <div className="tou">
                        <a href="/terms">Terms of Use</a>
                        <a href="/privacy">Privacy Notice</a>
                        {/* <Translate content="components.footer.tou.link3" component="a" href="/cookie" /> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
