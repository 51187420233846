import React, { useState, useReducer } from "react";
import { BiSend, BiTrash, BiInfoCircle, BiPlus, BiRocket, BiEdit, BiBox, BiSave, BiHappyBeaming, BiCopyAlt, BiShowAlt, BiCheck } from "react-icons/bi";
import { TiWarning } from "react-icons/ti";
import { toast } from "react-toastify";
import { v4 as UUID } from "uuid";
import Select from "react-select";
import Dot from "dot-object";
import Lodash from "lodash";
import Translate from "react-translate-component";
import EmojiPicker from "@emoji-mart/react";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";
import AppUtil from "@app@::@util";

import Utility from "@utility@::@index";

import DashboardComponent from "./index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "selfrole";
    const sectionPath = "modules.selfRole";
    const sectionData = JSON.parse(JSON.stringify(Dot.pick(sectionPath, config.data.current)));

    const [ReducerData, ReducerDispatch] = useReducer(SectionReducer, { id: guild.id, menu: undefined, message: undefined, selectedRole: { id: "", name: "", emoji: "" } });

    const addNewPopup = useState<boolean>(false);
    const addNewRolePopup = useState<boolean>(false);
    const sendMainMenuPopup = useState<boolean>(false);
    const showEmbedBuilder = useState<boolean>(false);
    const showEmojiPicker = useState<boolean>(false);
    const showPreviewPopup = useState<boolean>(false);
    const showEmbedColorPicker = useState<boolean>(false);
    const selectMenuActive = useState<boolean>(false);
    const previewPopupData = useState<any>();
    const roleEdit = useState<boolean>(false);

    const customEmojis = [{ id: "server", name: guild.name, emojis: guild.emojis.map((emoji) => ({ id: emoji.id, name: emoji.name, skins: [{ src: `${AppData.project.client.emojiImage}/${emoji.id}.${emoji.animated ? "gif" : "png"}` }] })) }];

    const selectedSelfRoleDropdown = () => {
        if (!config.data.current || !ReducerData.selectedRole.id) return [];
        const role = guild.roles.find((a) => a.id === ReducerData.selectedRole.id);
        if (!role) return [];
        return [{ label: role.name, value: role.id }];
    };

    const isEditingMenu = sectionData.data.roleMenus.find((menu: any) => menu.id === ReducerData.menu?.id);

    return (
        <>
            <section className="dashboard-section">
                <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
                <div className="container mx-auto mb-20">
                    {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                    {sectionData.active && (
                        <div className="dashboard-multi-add-list">
                            <div className="list-bar">
                                <div className="button flex gap-3">
                                    <button
                                        className="hover01 color-blurple"
                                        onClick={() => {
                                            ReducerDispatch({ type: "message-create", data: { placeholder: AppData.translations.get(utils.locale)?.routes.dashboard.selfrole.hub_placeholder } });
                                            showEmbedBuilder[1](false);
                                            sendMainMenuPopup[1](true);
                                        }}>
                                        <Translate content="routes.dashboard.selfrole.send_menu" className="button-text both" />
                                        <span className="button-icon">
                                            <BiRocket />
                                        </span>
                                    </button>
                                    <button
                                        className={`hover01 color-success ${sectionData.data.roleMenus.length >= 30 ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (sectionData.data.roleMenus.length >= 30) return;
                                            ReducerDispatch({ type: "menu-create", data: { placeholder: AppData.translations.get(utils.locale)?.routes.dashboard.selfrole.placeholder } });
                                            showEmbedBuilder[1](false);
                                            addNewPopup[1](true);
                                        }}>
                                        <Translate content="words.create_new" className="button-text both" />
                                        <span className="button-icon">
                                            <BiPlus />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="list">
                                {sectionData.data.roleMenus.map((menu: any, index: any) => (
                                    <div key={index} className="card">
                                        <div className="main flex justify-between items-center overflow-hidden ">
                                            <div className="text title-8 title flex gap-1 items-center break-all">
                                                <span className="text text-[30px]">
                                                    <BiHappyBeaming />
                                                </span>
                                                {Utility.TrimString(menu.name, 25)}
                                            </div>
                                            <div className="button flex gap-3 items-center">
                                                {menu.data.roles.filter((a: any) => !guild.roles.find((b) => a.id === b.id)).length > 0 && <AppComponent.Tooltip content={<Translate content="routes.dashboard.selfrole.role_unknown_1" />} children={<span className="icon" children={<TiWarning className="text text-[30px]" style={{ color: "#f04747" }} />} />} />}
                                                <button className="color-secondary color-theme pointer-events-none background-01">
                                                    <span className="button-text gap-1">
                                                        {menu.data.roles.length} {menu.data.roles.length > 1 ? <Translate content="words.roles" /> : <Translate content="words.role" />}
                                                    </span>
                                                </button>
                                                <button
                                                    className="hover02 color-blurple"
                                                    onClick={() => {
                                                        ReducerDispatch({ type: "menu-set", data: menu });
                                                        showEmbedBuilder[1](Object.values(menu.data.message.embed).length > 0 ? true : false);
                                                        addNewPopup[1](true);
                                                    }}>
                                                    <Translate content="words.edit" className="button-text both" />
                                                    <span className="button-icon">
                                                        <BiEdit />
                                                    </span>
                                                </button>
                                                <button
                                                    className="hover02 color-danger"
                                                    onClick={() => {
                                                        const data = JSON.parse(JSON.stringify(sectionData.data.roleMenus));
                                                        config.dispatch({ type: "update", path: `${sectionPath}.data.roleMenus`, data: data.filter((a: any) => a.id !== menu.id) });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiTrash />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <AppComponent.PopUp id="AddNewSelfRoleMenuPopup" trigger={addNewPopup} close utils={utils}>
                {ReducerData.menu && (
                    <span className="noselect form">
                        <Translate content={`routes.dashboard.selfrole.${isEditingMenu ? "edit_menu" : "new_menu"}`} component="h1" className="text title-7" />
                        <AppComponent.FormInput
                            className="mb-2"
                            type="text"
                            label={utils.translation.routes.dashboard.selfrole.menu_name}
                            placeholder={`${utils.translation.words.menu} #${sectionData.data.roleMenus.length + 1}`}
                            htmlFor="self-role-menu-name"
                            onChange={(value) => ReducerDispatch({ type: "menu-update", path: "name", data: value.target.value })}
                            additionalProps={{
                                maxLength: 100,
                                defaultValue: ReducerData.menu?.name,
                            }}
                            icon={
                                <div className="icon-right emoji-picker">
                                    <AppComponent.EmojiMenu
                                        utils={utils}
                                        element={
                                            <div className="icon cursor-pointer flex items-center justify-center" onClick={() => showEmojiPicker[1](!showEmojiPicker[0])}>
                                                <span dangerouslySetInnerHTML={{ __html: `<em-emoji set="twitter" class="emoji-select ${ReducerData.menu?.emoji?.id ? "bg-3" : ""}" id="${ReducerData.menu?.emoji?.id || "0"}"></em-emoji>` }} />
                                                <BiHappyBeaming className="hover:text-[#8c82ce] duration-200" />
                                            </div>
                                        }
                                        onClose={() => showEmojiPicker[1](false)}
                                        picker={
                                            <EmojiPicker
                                                set="twitter"
                                                custom={customEmojis}
                                                onEmojiSelect={(emoji: any) => {
                                                    ReducerDispatch({ type: "menu-update", data: emoji.id === ReducerData.menu?.emoji?.id ? { name: "", native: "", id: "" } : { name: emoji.name, native: emoji.native ? emoji.native : "", id: emoji.id }, path: "emoji" });
                                                    showEmojiPicker[1](false);
                                                }}
                                                onClose={() => showEmojiPicker[1](false)}
                                                emojiButtonColors={["#364157"]}
                                                emojiButtonRadius="8px"
                                            />
                                        }
                                        active={showEmojiPicker[0]}
                                    />
                                </div>
                            }
                            utils={utils}
                        />
                        <AppComponent.FormInput
                            className="w-fit"
                            type="button-select"
                            value={ReducerData.menu.settings?.type}
                            options={[
                                { label: utils.translation.words.button, value: 0 },
                                { label: utils.translation.words.select_menu, value: 1 },
                            ]}
                            onChange={(value: any) => ReducerDispatch({ type: "menu-update", path: "settings.type", data: value.value })}
                            additionalProps={{ checkPlacement: "left" }}
                            utils={utils}
                        />
                        <Translate content="words.settings" component="h1" className="text title-8" />
                        <div className="form-inputs">
                            {ReducerData.menu.settings?.type === 1 && (
                                <AppComponent.FormInput
                                    type="text"
                                    label={utils.translation.routes.dashboard.selfrole.menu_placeholder}
                                    placeholder={utils.translation.routes.dashboard.selfrole.placeholder}
                                    htmlFor="menu-placeholder"
                                    onChange={(value) => ReducerDispatch({ type: "menu-update", path: "data.placeholder", data: value.target.value })}
                                    additionalProps={{
                                        maxLength: 150,
                                        defaultValue: ReducerData.menu?.data.placeholder || utils.translation.routes.dashboard.selfrole.placeholder,
                                    }}
                                    utils={utils}
                                />
                            )}
                            <div className={ReducerData.menu.settings?.type === 1 ? "grid-col-2 gap-y-2 gap-x-4" : ""}>
                                {ReducerData.menu.settings?.type === 1 && <AppComponent.FormInput type="number" label={utils.translation.routes.dashboard.selfrole.min_selection} tooltip={utils.translation.routes.dashboard.selfrole.min_selection_i} htmlFor="count" value={ReducerData.menu?.settings?.minSelection} onChange={(value: any) => ReducerDispatch({ type: "menu-update", path: "settings.minSelection", data: value.target.value > 25 ? 25 : value.target.value < 0 ? 0 : !value.target.value ? 0 : parseInt(value.target.value) })} utils={utils} />}
                                <AppComponent.FormInput type="number" label={utils.translation.routes.dashboard.selfrole.max_selection} tooltip={utils.translation.routes.dashboard.selfrole.max_selection_i} htmlFor="count" value={ReducerData.menu?.settings?.maxSelection} onChange={(value: any) => ReducerDispatch({ type: "menu-update", path: "settings.maxSelection", data: value.target.value > 25 ? 25 : value.target.value < 0 ? 0 : !value.target.value ? 0 : parseInt(value.target.value) })} utils={utils} />
                            </div>
                            <div className="grid-col-2 gap-y-2 gap-x-4 items-end">
                                <AppComponent.FormInput
                                    type="select"
                                    label={utils.translation.words.blacklisted_roles}
                                    placeholder={utils.translation.words.select_roles}
                                    tooltip={utils.translation.routes.dashboard.selfrole.blacklist_i}
                                    value={ReducerData.menu?.settings?.blacklist?.list ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(ReducerData.menu?.settings?.blacklist.list, guild.roles, true)) : []}
                                    options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_ADMIN"])))}
                                    onChange={(value: any) => {
                                        ReducerDispatch({ type: "menu-update", path: "settings.blacklist.list", data: value.map((a: any) => a.value) });
                                    }}
                                    additionalProps={{
                                        isMulti: true,
                                        closeMenuOnSelect: false,
                                        onMenuOpen: () => selectMenuActive[1](true),
                                        onMenuClose: () => selectMenuActive[1](false),
                                        onFocus: (value: any) => {
                                            ReducerDispatch({ type: "menu-update", path: "settings.blacklist.active", data: true });
                                            ReducerDispatch({ type: "menu-update", path: "settings.whitelist.active", data: false });
                                        },
                                    }}
                                    utils={utils}
                                />
                                <AppComponent.FormInput
                                    type="select"
                                    label={utils.translation.words.whitelisted_roles}
                                    placeholder={utils.translation.words.select_roles}
                                    tooltip={utils.translation.routes.dashboard.selfrole.whitelist_i}
                                    value={ReducerData.menu?.settings?.whitelist?.list ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(ReducerData.menu?.settings?.whitelist.list, guild.roles, true)) : []}
                                    options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_ADMIN"])))}
                                    onChange={(value: any) => {
                                        ReducerDispatch({ type: "menu-update", path: "settings.whitelist.list", data: value.map((a: any) => a.value) });
                                    }}
                                    additionalProps={{
                                        isMulti: true,
                                        closeMenuOnSelect: false,
                                        onMenuOpen: () => selectMenuActive[1](true),
                                        onMenuClose: () => selectMenuActive[1](false),
                                        onFocus: (value: any) => {
                                            ReducerDispatch({ type: "menu-update", path: "settings.blacklist.active", data: false });
                                            ReducerDispatch({ type: "menu-update", path: "settings.whitelist.active", data: true });
                                        },
                                    }}
                                    utils={utils}
                                />
                            </div>
                            {ReducerData.menu.settings?.blacklist.active && ReducerData.menu.settings?.blacklist.list.length > 0 && <AppComponent.FormInput type="textarea" placeholder={utils.translation.routes.dashboard.selfrole.blacklist_message_placeholder} label={utils.translation.routes.dashboard.selfrole.blacklist_message} htmlFor="self-role-menu-blacklist-message" onChange={(value) => ReducerDispatch({ type: "menu-update", path: "settings.blacklist.message", data: value.target.value })} additionalProps={{ maxLength: 2000, defaultValue: ReducerData.menu.settings?.blacklist.message }} utils={utils} />}
                            {ReducerData.menu.settings?.whitelist.active && ReducerData.menu.settings?.whitelist.list.length > 0 && <AppComponent.FormInput type="textarea" placeholder={utils.translation.routes.dashboard.selfrole.whitelist_message_placeholder} label={utils.translation.routes.dashboard.selfrole.whitelist_message} htmlFor="self-role-menu-whitelist-message" onChange={(value) => ReducerDispatch({ type: "menu-update", path: "settings.whitelist.message", data: value.target.value })} additionalProps={{ maxLength: 2000, defaultValue: ReducerData.menu.settings?.whitelist.message }} utils={utils} />}

                            <AppComponent.FormInput
                                className="w-fit"
                                type="button-select"
                                label={utils.translation.words.mode}
                                value={ReducerData.menu.settings?.mode}
                                options={[
                                    { label: utils.translation.words.normal, value: 0, tooltip: <Translate content={`routes.dashboard.selfrole.type_0`} /> },
                                    { label: utils.translation.words.add, value: 1, tooltip: <Translate content={`routes.dashboard.selfrole.type_1`} /> },
                                    { label: utils.translation.words.remove, value: 2, tooltip: <Translate content={`routes.dashboard.selfrole.type_2`} /> },
                                    { label: utils.translation.words.bind, value: 3, tooltip: <Translate content={`routes.dashboard.selfrole.type_3`} /> },
                                ]}
                                onChange={(value: any) => ReducerDispatch({ type: "menu-update", path: "settings.mode", data: value.value })}
                                additionalProps={{ checkPlacement: "left" }}
                                utils={utils}
                            />
                        </div>

                        <div className="flex justify-between items-center mt-5 mb-4">
                            <Translate content="words.select_roles" component="h1" className="text title-8" />
                            <div className="button flex gap-2">
                                <button
                                    className={`hover02 color-secondary ${ReducerData.menu?.data.roles.length < 1 ? "disabled" : ""}`}
                                    onClick={() => {
                                        if (ReducerData.menu?.data.roles.length < 1) return;
                                        navigator.clipboard.writeText(
                                            ReducerData.menu?.data.roles
                                                .map((role: any) => {
                                                    const animated = guild.emojis.find((emoji) => emoji.id === role.emoji.id)?.animated;
                                                    return `<@&${role.id}> ${role.name}${role.emoji.id ? (role.emoji.native ? ` ${role.emoji.native}` : ` <${animated ? "a" : ""}:${role.emoji.name}:${role.emoji.id}>`) : ""}`;
                                                })
                                                .join("\n")
                                        );
                                        toast(<Translate content="words.copied_clipboard" />, { type: "success", autoClose: 4000 });
                                    }}>
                                    <span className="button-icon">
                                        <BiCopyAlt />
                                    </span>
                                </button>
                                <button
                                    className={`hover02 color-success ${ReducerData.menu?.data.roles.length > 24 ? "disabled" : ""}`}
                                    onClick={() => {
                                        if (ReducerData.menu?.data.roles.length > 24) return;
                                        roleEdit[1](false);
                                        addNewPopup[1](false);
                                        addNewRolePopup[1](true);
                                        ReducerDispatch({ type: "selected-role-reset" });
                                    }}>
                                    <Translate content="routes.dashboard.selfrole.new_role" className="button-text both" />
                                    <span className="button-icon">
                                        <BiPlus />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="self-roles-role-grid">
                            {ReducerData.menu?.data.roles.length < 1 && (
                                <div className="background-02 flex justify-center p-4 rounded-[10px] drop-shadow-lg">
                                    <Translate content="routes.dashboard.selfrole.no_role_selected" className="text subtitle-04 center" />
                                </div>
                            )}
                            {ReducerData.menu?.data.roles.map((role: any, index: any) => {
                                const discordRole = guild.roles.find((a) => a.id === role.id);
                                const color = discordRole?.color === 0 ? "#99AAB5" : `#${discordRole?.color.toString(16)?.padStart(6, "0")}`;
                                return (
                                    <div className="card" key={index}>
                                        <div className="card-detail flex gap-2 items-center">
                                            {role?.emoji?.id && <span dangerouslySetInnerHTML={{ __html: `<em-emoji set="twitter" class="selfrole-emoji-picker-selected-role" id="${role?.emoji?.id}"></em-emoji>` }} />}
                                            <div className="name flex flex-col">
                                                <span className="text leading-[20px] text-[20px] font-2">{Utility.TrimString(role.name, 20)}</span>
                                                <span className="flex items-center gap-1 mt-[3px]">
                                                    <div className={`w-[12px] h-[12px] rounded-[10px]`} style={{ backgroundColor: color }} />
                                                    <span className="text leading-[12px] mb-[2px]">{discordRole?.name ? Utility.TrimString(discordRole.name, 20) : <Translate content="words.unknown_role" />}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="button flex items-center gap-2">
                                            {!discordRole && <AppComponent.Tooltip content={<Translate content="routes.dashboard.selfrole.role_unknown_2" />} children={<span className="icon" children={<TiWarning className="text text-[30px]" style={{ color: "#f04747" }} />} />} />}
                                            <button
                                                className="hover02 color-blurple"
                                                onClick={() => {
                                                    roleEdit[1](true);
                                                    addNewPopup[1](false);
                                                    addNewRolePopup[1](true);
                                                    ReducerDispatch({ type: "selected-role-set", data: { index, ...role } });
                                                }}>
                                                <span className="button-icon">
                                                    <BiEdit />
                                                </span>
                                            </button>
                                            <button
                                                className="hover02 color-danger"
                                                onClick={() => {
                                                    const data = JSON.parse(JSON.stringify(ReducerData.menu));
                                                    ReducerDispatch({ type: "menu-update", path: "data.roles", data: data.data.roles.filter((a: any) => a.id !== role.id) });
                                                }}>
                                                <span className="button-icon">
                                                    <BiTrash />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex justify-between items-center mt-5">
                            <Translate content="routes.dashboard.selfrole.message" component="h1" className="text title-8" />
                            <div className="button flex gap-2">
                                <button
                                    className="hover02 color-blurple"
                                    onClick={() => {
                                        showPreviewPopup[1](true);
                                        const embed = Utility.IsValidDiscordEmbed(ReducerData.menu.data.message.embed);
                                        previewPopupData[1]({ content: ReducerData.menu.data.message.content, embeds: embed.success ? [embed.embed] : [], components: ReducerData.menu?.settings.type === 0 ? ReducerData.menu?.data.roles.map((role: any) => ({ type: 2, label: role.name, emoji: role.emoji, style: role.buttonStyle })) : [{ type: 3, placeholder: ReducerData.menu?.data.placeholder, options: ReducerData.menu?.data.roles.map((role: any) => ({ label: role.name, emoji: role.emoji })) }] });
                                    }}>
                                    <span className="button-icon">
                                        <BiShowAlt />
                                    </span>
                                </button>
                                <button className="hover02 color-blurple" onClick={() => showEmbedBuilder[1](!showEmbedBuilder[0])}>
                                    <Translate content="words.embed" className="button-text both" />
                                    <span className="button-icon">
                                        <BiBox />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="mb-6">
                            <AppComponent.FormInput type="textarea" label={utils.translation.routes.dashboard.selfrole.message_content} placeholder={utils.translation.routes.dashboard.selfrole.message_content_placeholder} htmlFor="self-role-menu-message-content" onChange={(value) => ReducerDispatch({ type: "menu-update", path: "data.message.content", data: value.target.value })} additionalProps={{ maxLength: 2000, defaultValue: ReducerData.menu.data.message.content }} utils={utils} />
                            <div className="discord-bg mt-2 px-2 py-1 flex rounded-[8px] gap-2">
                                <div>
                                    <AppComponent.DiscordComponentPreviewer component={{ style: ReducerData.menu.settings.buttonStyle, type: 2 }} utils={utils} />
                                </div>
                                <div className="button flex">
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 1, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            ReducerDispatch({ type: "menu-update", path: "settings.buttonStyle", data: 1 });
                                        }}
                                    />
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 2, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            ReducerDispatch({ type: "menu-update", path: "settings.buttonStyle", data: 2 });
                                        }}
                                    />
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 3, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            ReducerDispatch({ type: "menu-update", path: "settings.buttonStyle", data: 3 });
                                        }}
                                    />
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 4, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            ReducerDispatch({ type: "menu-update", path: "settings.buttonStyle", data: 4 });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {showEmbedBuilder[0] && (
                            <>
                                <Translate content="words.embed" component="h1" className="text font-2 text-[20px] mb-1" />
                                <AppComponent.DiscordEmbedBuilder showColorPicker={showEmbedColorPicker} utils={utils} embed={ReducerData.menu.data.message.embed} update={(embed: any) => ReducerDispatch({ type: "menu-update", path: "data.message.embed", data: embed })} />
                            </>
                        )}
                        <div className="button flex justify-between mt-6">
                            <button className="hover02 color-secondary" onClick={() => addNewPopup[1](false)}>
                                <Translate content="words.cancel" className="button-text" />
                            </button>
                            <button
                                className="hover02 color-success"
                                onClick={() => {
                                    if (ReducerData.menu.data.roles.length < 1) return toast(<Translate content="routes.dashboard.selfrole.role_required" />, { type: "error", autoClose: 5000 });
                                    if (!ReducerData.menu.name) Dot.set("name", `${utils.translation.words.menu} #${sectionData.data.roleMenus.length + 1}`, ReducerData.menu);
                                    if (!showEmbedBuilder[0]) Dot.set("data.message.embed", {}, ReducerData.menu);
                                    if (!AppUtil.ToastDiscordEmbedValidity(ReducerData.menu.data?.message?.embed)) return;
                                    if (isEditingMenu) {
                                        const index = sectionData.data.roleMenus.findIndex((menu: any) => menu.id === ReducerData.menu.id);
                                        if (index < 0) return;
                                        else if (Lodash.isEqual(sectionData.data.roleMenus[index], ReducerData.menu)) return toast(<Translate content="errors.no_changes" />, { type: "error", autoClose: 5000 });
                                        sectionData.data.roleMenus[index] = ReducerData.menu;
                                    } else sectionData.data.roleMenus.push(ReducerData.menu);
                                    config.dispatch({ type: "update", path: `${sectionPath}.data.roleMenus`, data: sectionData.data.roleMenus });
                                    config.dispatch({ type: "save", data: { guild, update: extra.update, refetch: extra.refetch } });
                                    addNewPopup[1](false);
                                }}>
                                {isEditingMenu ? (
                                    <>
                                        <Translate content="words.save" className="button-text both" />
                                        <span className="button-icon">
                                            <BiSave />
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Translate content="words.create" className="button-text both" />
                                        <span className="button-icon">
                                            <BiPlus />
                                        </span>
                                    </>
                                )}
                            </button>
                        </div>
                    </span>
                )}
            </AppComponent.PopUp>
            <AppComponent.PopUp id="AddNewSelfRolePopup" trigger={addNewRolePopup} closeFull={!showPreviewPopup[0] && !showEmojiPicker[0] && !showEmbedColorPicker[0] && !selectMenuActive[0]} close onClose={() => addNewPopup[1](true)} utils={utils}>
                <span className="noselect form">
                    <Translate content={`words.${roleEdit[0] ? "edit_role" : "select_role"}`} className="text title-7 info-icon leading-[36px]" />
                    <div className="form-inputs">
                        <AppComponent.FormInput
                            type="select"
                            label={utils.translation.words.role}
                            placeholder={utils.translation.words.select_role}
                            tooltip={utils.translation.routes.dashboard.selfrole.select_role_i}
                            value={selectedSelfRoleDropdown()}
                            options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS", "NO_ADMIN", "NO_MANAGER", "NO_KICK_BAN"], ReducerData.menu?.data.roles)))}
                            onChange={(value: any) => {
                                const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                Dot.set("id", value?.value ? value?.value : "", data);
                                if (!data.name) Dot.set("name", value?.value ? guild.roles.find((v) => v.id === value?.value)?.name : "", data);
                                ReducerDispatch({ type: "selected-role-set", data });
                            }}
                            additionalProps={{ isClearable: true, onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }}
                            utils={utils}
                        />
                        <AppComponent.FormInput
                            type="text"
                            label={utils.translation.routes.dashboard.selfrole.role_name}
                            placeholder={utils.translation.routes.dashboard.selfrole.role_name_p}
                            htmlFor="self-role-menu-role-name"
                            value={ReducerData.selectedRole.name}
                            onChange={(value) => {
                                const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                Dot.set("name", value.target.value, data);
                                ReducerDispatch({ type: "selected-role-set", data });
                            }}
                            additionalProps={{
                                maxLength: 100,
                            }}
                            utils={utils}
                            icon={
                                <div className="icon-right emoji-picker">
                                    <AppComponent.EmojiMenu
                                        utils={utils}
                                        element={
                                            <div className="icon cursor-pointer" onClick={() => showEmojiPicker[1](!showEmojiPicker[0])}>
                                                <span dangerouslySetInnerHTML={{ __html: `<em-emoji set="twitter" class="emoji-select ${ReducerData.selectedRole?.emoji?.id ? "bg-3" : ""}" id="${ReducerData.selectedRole?.emoji?.id || "0"}"></em-emoji>` }} />
                                                <BiHappyBeaming className="hover:text-[#8c82ce] duration-200" />
                                            </div>
                                        }
                                        onClose={() => showEmojiPicker[1](false)}
                                        picker={
                                            <EmojiPicker
                                                set="twitter"
                                                custom={customEmojis}
                                                onEmojiSelect={(emoji: any) => {
                                                    const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                                    Dot.set("emoji", emoji.id === data.emoji.id ? { name: "", native: "", id: "" } : { name: emoji.name, native: emoji.native ? emoji.native : "", id: emoji.id }, data);
                                                    ReducerDispatch({ type: "selected-role-set", data });
                                                    showEmojiPicker[1](false);
                                                }}
                                                onClose={() => showEmojiPicker[1](false)}
                                                emojiButtonColors={["#364157"]}
                                                emojiButtonRadius="8px"
                                                skinTonePosition="none"
                                            />
                                        }
                                        active={showEmojiPicker[0]}
                                    />
                                </div>
                            }
                        />
                        {ReducerData.menu?.settings?.type === 1 && (
                            <AppComponent.FormInput
                                type="text"
                                label={utils.translation.routes.dashboard.selfrole.role_description}
                                placeholder={utils.translation.routes.dashboard.selfrole.role_description_p}
                                value={ReducerData.selectedRole.description}
                                htmlFor="self-role-menu-role-description"
                                onChange={(value) => {
                                    const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                    Dot.set("description", value.target.value, data);
                                    ReducerDispatch({ type: "selected-role-set", data });
                                }}
                                additionalProps={{
                                    maxLength: 100,
                                }}
                                utils={utils}
                            />
                        )}
                        {ReducerData.menu?.settings?.type === 0 && (
                            <div className="discord-bg px-2 py-1 flex rounded-[8px] gap-2">
                                <div>
                                    <AppComponent.DiscordComponentPreviewer component={{ label: Utility.TrimString(ReducerData.selectedRole.name, 40), style: ReducerData.selectedRole.buttonStyle, emoji: ReducerData.selectedRole.emoji, type: 2 }} utils={utils} />
                                </div>
                                <div className="button flex">
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 1, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                            Dot.set("buttonStyle", 1, data);
                                            ReducerDispatch({ type: "selected-role-set", data });
                                        }}
                                    />
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 2, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                            Dot.set("buttonStyle", 2, data);
                                            ReducerDispatch({ type: "selected-role-set", data });
                                        }}
                                    />
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 3, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                            Dot.set("buttonStyle", 3, data);
                                            ReducerDispatch({ type: "selected-role-set", data });
                                        }}
                                    />
                                    <AppComponent.DiscordComponentPreviewer
                                        component={{ forColor: true, style: 4, type: 2 }}
                                        utils={utils}
                                        onClick={() => {
                                            const data = JSON.parse(JSON.stringify(ReducerData.selectedRole));
                                            Dot.set("buttonStyle", 4, data);
                                            ReducerDispatch({ type: "selected-role-set", data });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="button flex justify-between mt-6">
                        <button
                            className="hover02 color-secondary"
                            onClick={() => {
                                addNewRolePopup[1](false);
                                addNewPopup[1](true);
                            }}>
                            <Translate content="words.cancel" className="button-text" />
                        </button>
                        {roleEdit[0] ? (
                            <button
                                className={` hover02 color-success ${!ReducerData.selectedRole.id ? "disabled" : ""}`}
                                onClick={() => {
                                    if (!ReducerData.selectedRole.id) return;
                                    ReducerDispatch({ type: "menu-add-role", data: { selectedSelfRoleDropdown, isEdit: true } });
                                    addNewPopup[1](true);
                                    addNewRolePopup[1](false);
                                }}>
                                <Translate content="words.edit" className="button-text both" />
                                <span className="button-icon">
                                    <BiEdit />
                                </span>
                            </button>
                        ) : (
                            <button
                                className={` hover02 color-success ${!ReducerData.selectedRole.id ? "disabled" : ""}`}
                                onClick={() => {
                                    if (!ReducerData.selectedRole.id) return;
                                    ReducerDispatch({ type: "menu-add-role", data: { selectedSelfRoleDropdown } });
                                    addNewPopup[1](true);
                                    addNewRolePopup[1](false);
                                }}>
                                <Translate content="words.add" className="button-text both" />
                                <span className="button-icon">
                                    <BiPlus />
                                </span>
                            </button>
                        )}
                    </div>
                </span>
            </AppComponent.PopUp>
            <AppComponent.PopUp id="SendSelfRoleMenuPopup" trigger={sendMainMenuPopup} closeFull={!showPreviewPopup[0] && !showEmojiPicker[0] && !showEmbedColorPicker[0] && !selectMenuActive[0]} close utils={utils}>
                {ReducerData.message && (
                    <span className="noselect form">
                        <h1 className="text title-7 info-icon" style={{ lineHeight: "36px" }}>
                            <Translate content="routes.dashboard.selfrole.send_menu" />
                            <AppComponent.Tooltip content={<Translate content="routes.dashboard.selfrole.menu_hub_description" />} children={<span className="icon" children={<BiInfoCircle />} />} />
                        </h1>
                        <div className="form-inputs">
                            <AppComponent.FormInput type="select" htmlFor="self-role-menu-channel" label={utils.translation.words.channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([ReducerData.message.channelId], guild.channels)} options={extra.channelOptions([0, 2, 5])} onChange={(value: any) => ReducerDispatch({ type: "message-update", path: "channelId", data: value.value })} additionalProps={{ isClearable: true, onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }} utils={utils} />
                            <AppComponent.FormInput type="select" label={utils.translation.routes.dashboard.selfrole.menus} placeholder={utils.translation.words.select_menus} options={sectionData.data.roleMenus.map((menu: any) => ({ label: menu.name, search: menu.name, value: menu.id, emoji: menu.emoji, buttonStyle: menu.settings.buttonStyle }))} onChange={(value: any) => ReducerDispatch({ type: "message-update", path: "menus", data: value })} additionalProps={{ isMulti: true, closeMenuOnSelect: false, onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false), isOptionDisabled: () => ReducerData.message.menus.length >= 25 }} utils={utils} />
                            <AppComponent.FormInput
                                className="w-fit"
                                type="button-select"
                                value={ReducerData.message.type}
                                options={[
                                    { label: utils.translation.words.button, value: 0 },
                                    { label: utils.translation.words.select_menu, value: 1 },
                                ]}
                                onChange={(value: any) => ReducerDispatch({ type: "message-update", path: "type", data: value.value })}
                                additionalProps={{ checkPlacement: "left" }}
                                utils={utils}
                            />
                            {ReducerData.message.type === 1 && (
                                <AppComponent.FormInput
                                    type="text"
                                    label={utils.translation.routes.dashboard.selfrole.menu_placeholder}
                                    placeholder={utils.translation.routes.dashboard.selfrole.hub_placeholder}
                                    htmlFor="menu-placeholder"
                                    onChange={(value) => ReducerDispatch({ type: "message-update", path: "placeholder", data: value.target.value })}
                                    additionalProps={{
                                        maxLength: 150,
                                        defaultValue: utils.translation.routes.dashboard.selfrole.hub_placeholder,
                                    }}
                                    utils={utils}
                                />
                            )}
                        </div>
                        <div className="flex justify-between items-center mt-3">
                            <Translate content="routes.dashboard.selfrole.message" component="h1" className="text title-8" />
                            <div className="button flex gap-2">
                                <button
                                    className="hover02 color-blurple"
                                    onClick={() => {
                                        showPreviewPopup[1](true);
                                        const embed = Utility.IsValidDiscordEmbed(ReducerData.message.embed);
                                        previewPopupData[1]({ content: ReducerData.message.content, embeds: embed.success ? [embed.embed] : [], components: ReducerData.message.type === 0 ? ReducerData.message.menus.map((menu: any) => ({ type: 2, label: menu.label, emoji: menu.emoji, style: menu.buttonStyle })) : [{ type: 3, placeholder: ReducerData.message.placeholder, options: ReducerData.message.menus.map((menu: any) => ({ label: menu.label, emoji: menu.emoji })) }] });
                                    }}>
                                    <span className="button-icon">
                                        <BiShowAlt />
                                    </span>
                                </button>
                                <button className="hover02 color-blurple" onClick={() => showEmbedBuilder[1](!showEmbedBuilder[0])}>
                                    <Translate content="words.embed" className="button-text both" />
                                    <span className="button-icon">
                                        <BiBox />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="mb-6">
                            <AppComponent.FormInput type="textarea" label={utils.translation.routes.dashboard.selfrole.message_content} placeholder={utils.translation.routes.dashboard.selfrole.message_content_placeholder} htmlFor="self-role-menu-message-content" onChange={(value) => ReducerDispatch({ type: "message-update", path: "content", data: value.target.value })} additionalProps={{ maxLength: 2000, defaultValue: ReducerData.message.content }} utils={utils} />
                        </div>
                        {showEmbedBuilder[0] && (
                            <>
                                <Translate content="words.embed" component="h1" className="text font-2 text-[20px] mb-1" />
                                <AppComponent.DiscordEmbedBuilder showColorPicker={showEmbedColorPicker} utils={utils} embed={ReducerData.message.embed} update={(embed: any) => ReducerDispatch({ type: "message-update", path: "embed", data: embed })} />
                            </>
                        )}
                        <div className="button flex justify-between mt-6">
                            <button className="hover02 color-secondary" onClick={() => sendMainMenuPopup[1](false)}>
                                <Translate content="words.cancel" className="button-text" />
                            </button>
                            <button
                                className="hover02 color-success"
                                onClick={() => {
                                    if (!ReducerData.message?.channelId) return toast(<Translate content="routes.dashboard.selfrole.channel_required" />, { type: "error", autoClose: 5000 });
                                    else if (ReducerData.message?.menus?.length < 1) return toast(<Translate content="routes.dashboard.selfrole.menu_required" />, { type: "error", autoClose: 5000 });
                                    else if (!AppUtil.ToastDiscordEmbedValidity(ReducerData.message.embed)) return;
                                    if (!showEmbedBuilder[0]) ReducerDispatch({ type: "message-update", path: "embed", data: {} });
                                    ReducerDispatch({ type: "message-send", data: { update: extra.update, refetch: extra.refetch } });
                                    sendMainMenuPopup[1](false);
                                    toast(<Translate content="words.success" />, { type: "success", autoClose: 3000 });
                                }}>
                                <Translate content="words.send" className="button-text both" />
                                <span className="button-icon">
                                    <BiSend />
                                </span>
                            </button>
                        </div>
                    </span>
                )}
            </AppComponent.PopUp>
            {previewPopupData[0] && <AppComponent.PopUp id="EmbedPreviewPopup" trigger={showPreviewPopup} closeFull close utils={utils} background={"discord-bg noselect"} children={<AppComponent.DiscordMessagePreviewer guild={guild} message={{ author: { username: "Titan-Bot", bot: true, botVerified: true, avatarUrl: AppData.project.client.botAvatar }, ...previewPopupData[0] }} utils={utils} />} />}
            <span style={{ display: "none" }} children={<EmojiPicker set="twitter" custom={customEmojis} />} />
        </>
    );
};

const SectionReducer = (state: DashboardInterface.ModuleReducer, action: { type: string; path?: string; data?: any }): any => {
    let data = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "menu-create":
            Dot.set("menu", { id: UUID(), name: "", emoji: "", settings: { buttonStyle: 1, type: 1, mode: 0, minSelection: 0, maxSelection: 0, blacklist: { active: false, list: [], message: "" }, whitelist: { active: false, list: [], message: "" } }, data: { placeholder: action.data.placeholder, message: { content: "", embed: {} }, roles: [] } }, data);
            break;
        case "menu-update":
            Dot.set(`menu.${action.path}`, action.data, data);
            break;
        case "menu-set":
            Dot.set(`menu`, action.data, data);
            break;
        case "menu-add-role": {
            const menu = data.menu;
            const selectedRole = data.selectedRole;
            const index = Dot.remove("index", selectedRole);
            if (!selectedRole.name) Dot.set("name", action.data.selectedSelfRoleDropdown()[0]?.label, selectedRole);
            if (action.data.isEdit) Dot.set(`data.roles.${index}`, selectedRole, menu);
            else menu.data.roles.push(selectedRole);
            Dot.set("menu", menu, data);
            break;
        }
        case "message-create":
            Dot.set(`message`, { menus: [], placeholder: action.data.placeholder, type: 1, channelId: "", content: "", embed: {} }, data);
            break;
        case "message-update":
            Dot.set(`message.${action.path}`, action.data, data);
            break;
        case "message-send": {
            const messageData = { id: data.id, message: data.message };
            action.data.update({ variables: { id: messageData.id, config: JSON.stringify({ type: "selfrole-send-message", data: messageData }), type: "module" } }).then(() => action.data.refetch().then(() => {}));
            break;
        }
        case "selected-role-set":
            Dot.set("selectedRole", action.data, data);
            break;
        case "selected-role-reset":
            Dot.set("selectedRole", { id: "", name: "", description: "", emoji: "", buttonStyle: 1 }, data);
            break;
        default:
            break;
    }
    return data;
};

export default Section;
