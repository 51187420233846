import React from "react";
import ReactDom from "react-dom";
import Translate from "react-translate-component";
import Dot from "dot-object";
import { BiCalendarAlt, BiCheck, BiTrash, BiX } from "react-icons/bi";
import DateTimePicker from "react-datetime-picker";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

const portal = document.getElementById("portal");

const DiscordEmbedBuilder: React.FC<{ id?: number; showColorPicker?: any; embed: any; update: Function; utils: any; noSizeCheck?: boolean; maxSizeAll?: number; bg?: string; activeInputElement?: any; profile?: any }> = (props) => {
    let showColorPicker = React.useState<boolean>(false);
    if (props.showColorPicker) showColorPicker = props.showColorPicker;

    const embed = JSON.parse(JSON.stringify(props.embed));

    const embedSize = Utility.GetDiscordEmbedSize(embed) || 0;
    const ColorPickerColor = React.useState<string>(embed?.color ? "#" + embed?.color.toString(16)?.padStart(6, "0") : "#2e3135");

    return (
        <>
            <div className="form">
                <Translate content="components.embed.author" component="h1" className="text font-2 text-[15px]" />
                <div className="form-inputs">
                    <AppComponent.FormInput
                        type="textarea"
                        className="small"
                        htmlFor={`menu-embed-author-${props.id ? props.id : 0}`}
                        label={props.utils.translation.words.name}
                        placeholder={props.profile ? props.profile.settings.user.username + " ..." : ""}
                        value={embed?.author?.name || ""}
                        onChange={(value) => {
                            Dot.set("author.name", value.target.value, embed);
                            props.update(embed);
                        }}
                        additionalProps={{ maxLength: props.maxSizeAll ? props.maxSizeAll : 256, onFocus: (element: any) => props?.activeInputElement?.({ path: "author.name", element }) }}
                        utils={props.utils}
                    />
                    <div className="grid-col-2 gap-2 items-end">
                        <AppComponent.FormInput
                            type="text"
                            htmlFor={`menu-embed-author-icon-url-${props.id ? props.id : 0}`}
                            label={props.utils.translation.words.icon_url}
                            placeholder="https://example.com/cat.png ..."
                            value={embed?.author?.icon_url || ""}
                            onChange={(value) => {
                                Dot.set("author.icon_url", value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ onFocus: (element: any) => props?.activeInputElement?.({ path: "author.icon_url", element }) }}
                            utils={props.utils}
                        />
                        <AppComponent.FormInput
                            type="text"
                            htmlFor={`menu-embed-author-url-${props.id ? props.id : 0}`}
                            placeholder="https://example.com ..."
                            label={props.utils.translation.words.url}
                            value={embed?.author?.url || ""}
                            onChange={(value) => {
                                Dot.set("author.url", value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ onFocus: (element: any) => props?.activeInputElement?.({ path: "author.url", element }) }}
                            utils={props.utils}
                        />
                    </div>
                </div>
                <Translate content="components.embed.body" component="h1" className="text font-2 text-[15px] mt-3" />
                <div className="form-inputs">
                    <AppComponent.FormInput
                        type="textarea"
                        className="small"
                        htmlFor={`menu-embed-title-${props.id ? props.id : 0}`}
                        label={props.utils.translation.components.embed.title}
                        value={embed?.title || ""}
                        onChange={(value) => {
                            Dot.set("title", value.target.value, embed);
                            props.update(embed);
                        }}
                        additionalProps={{ maxLength: props.maxSizeAll ? props.maxSizeAll : 256, onFocus: (element: any) => props?.activeInputElement?.({ path: "title", element }) }}
                        utils={props.utils}
                    />

                    <AppComponent.FormInput
                        type="textarea"
                        label={props.utils.translation.components.embed.description}
                        htmlFor={`menu-embed-description-${props.id ? props.id : 0}`}
                        value={embed?.description || ""}
                        onChange={(value) => {
                            Dot.set("description", value.target.value, embed);
                            props.update(embed);
                        }}
                        additionalProps={{ maxLength: props.maxSizeAll ? props.maxSizeAll : 4096, onFocus: (element: any) => props?.activeInputElement?.({ path: "description", element }) }}
                        utils={props.utils}
                    />
                    <div className="grid-col-2 gap-2 items-end">
                        <AppComponent.FormInput
                            type="text"
                            label={props.utils.translation.components.embed.url}
                            placeholder="https://example.com ..."
                            htmlFor={`menu-embed-url-${props.id ? props.id : 0}`}
                            value={embed?.url || ""}
                            onChange={(value) => {
                                Dot.set("url", value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ onFocus: (element: any) => props?.activeInputElement?.({ path: "url", element }) }}
                            utils={props.utils}
                        />

                        <div className="form-input color-picker h-[48px] cursor-pointer noselect mt-2">
                            <AppComponent.ColorPicker
                                element={
                                    <div className="main h-[48px]" style={{ backgroundColor: ColorPickerColor[0] }} onClick={() => showColorPicker[1](!showColorPicker[0])}>
                                        <div className="color-hex text font-2 text-[17px]">{ColorPickerColor[0]}</div>
                                    </div>
                                }
                                active={showColorPicker[0]}
                                color={ColorPickerColor[0]}
                                utils={props.utils}
                                onChange={(color: any) => {
                                    ColorPickerColor[1](color.hex);
                                    Dot.set("color", parseInt(ColorPickerColor[0].replace("#", ""), 16), embed);
                                    props.update(embed);
                                }}
                                onClose={() => showColorPicker[1](false)}
                            />
                        </div>
                    </div>

                    <div className="grid-col-2 gap-2 items-end">
                        <AppComponent.FormInput
                            type="text"
                            label={props.utils.translation.components.embed.image_url}
                            placeholder="https://example.com/rabbit.png ..."
                            htmlFor={`menu-embed-image-url-${props.id ? props.id : 0}`}
                            value={embed?.image?.url || ""}
                            onChange={(value) => {
                                Dot.set("image.url", value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ onFocus: (element: any) => props?.activeInputElement?.({ path: "image.url", element }) }}
                            utils={props.utils}
                        />
                        <AppComponent.FormInput
                            type="text"
                            label={props.utils.translation.components.embed.thumbnail_url}
                            placeholder="https://example.com/fox.png ..."
                            htmlFor={`menu-embed-thumbnail-url-${props.id ? props.id : 0}`}
                            value={embed?.thumbnail?.url || ""}
                            onChange={(value) => {
                                Dot.set("thumbnail.url", value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ onFocus: (element: any) => props?.activeInputElement?.({ path: "thumbnail.url", element }) }}
                            utils={props.utils}
                        />
                    </div>
                </div>
                <div className="flex justify-between items-center mt-3">
                    <Translate content="components.embed.fields" component="h1" className="text font-2 text-[15px]" />
                    <div className="button">
                        <button
                            className={`hover02 color-success h-8 ${embed?.fields?.length > 24 ? "disabled" : ""}`}
                            onClick={() => {
                                if (!embed?.fields) Dot.set("fields", [], embed);
                                embed.fields.push({ name: "", value: "", inline: false });
                                props.update(embed);
                            }}>
                            <Translate content="components.embed.add_field" className="button-text" />
                        </button>
                    </div>
                </div>
                {embed?.fields?.map((field: any, index: any) => (
                    <div key={index} className="form-inputs">
                        <AppComponent.FormInput
                            className="small"
                            type="textarea"
                            label={props.utils.translation.components.embed.field_name}
                            htmlFor={`menu-embed-field-name-${index}-${props.id ? props.id : 0}`}
                            value={field?.name || ""}
                            onChange={(value) => {
                                Dot.set(`fields.${index}.name`, value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ maxLength: props.maxSizeAll ? props.maxSizeAll : 256, onFocus: (element: any) => props?.activeInputElement?.({ path: `fields.${index}.name`, element }) }}
                            utils={props.utils}
                        />

                        <AppComponent.FormInput
                            type="textarea"
                            label={props.utils.translation.components.embed.field_value}
                            htmlFor={`menu-embed-field-value-${index}-${props.id ? props.id : 0}`}
                            value={field?.value || ""}
                            onChange={(value) => {
                                Dot.set(`fields.${index}.value`, value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ maxLength: props.maxSizeAll ? props.maxSizeAll : 1024, onFocus: (element: any) => props?.activeInputElement?.({ path: `fields.${index}.value`, element }) }}
                            utils={props.utils}
                        />

                        <div className="flex justify-between items-center">
                            <div className="button">
                                <button
                                    className="hover02 color-danger h-8"
                                    onClick={() => {
                                        embed.fields[index] = undefined;
                                        embed.fields = embed.fields.filter((a: any) => a !== undefined);
                                        props.update(embed);
                                    }}>
                                    <span className="button-icon">
                                        <BiTrash />
                                    </span>
                                </button>
                            </div>
                            <AppComponent.FormInput
                                type="toggle-checkbox"
                                htmlFor={`embed-inline-toggle-${index}-${props.id ? props.id : 0}`}
                                label={props.utils.translation.components.embed.field_inline}
                                value={field?.inline}
                                onChange={(event) => {
                                    Dot.set(`fields.${index}.inline`, event.target.checked, embed);
                                    props.update(embed);
                                }}
                                additionalProps={{ togglePlacement: "right" }}
                                utils={props.utils}
                            />
                        </div>
                    </div>
                ))}

                <Translate content="components.embed.footer" component="h1" className="text font-2 text-[15px] mt-3" />
                <div className="form-inputs">
                    <AppComponent.FormInput
                        className="small"
                        type="textarea"
                        label={props.utils.translation.words.text}
                        htmlFor={`menu-embed-footer-${props.id ? props.id : 0}`}
                        value={embed?.footer?.text || ""}
                        onChange={(value) => {
                            Dot.set("footer.text", value.target.value, embed);
                            props.update(embed);
                        }}
                        additionalProps={{ maxLength: props.maxSizeAll ? props.maxSizeAll : 2048, onFocus: (element: any) => props?.activeInputElement?.({ path: "footer.text", element }) }}
                        utils={props.utils}
                    />

                    <div className="grid-col-2 gap-2 items-end">
                        <AppComponent.FormInput
                            type="text"
                            label={props.utils.translation.words.icon_url}
                            placeholder="https://example.com/cat.png ..."
                            htmlFor={`menu-embed-footer-icon-url-${props.id ? props.id : 0}`}
                            value={embed?.footer?.icon_url || ""}
                            onChange={(value) => {
                                Dot.set("footer.icon_url", value.target.value, embed);
                                props.update(embed);
                            }}
                            additionalProps={{ onFocus: (element: any) => props?.activeInputElement?.({ path: "footer.icon_url", element }) }}
                            utils={props.utils}
                        />

                        <DateTimePicker
                            className="mt-2"
                            disableClock
                            calendarIcon={<BiCalendarAlt className="text-[25px]" />}
                            clearIcon={<BiX className="text-[25px]" />}
                            yearPlaceholder={props.utils.translation.words.year}
                            monthPlaceholder={props.utils.translation.words.month}
                            format="dd MMM y h:mm:ss a"
                            value={embed?.timestamp ? new Date(embed?.timestamp) : undefined}
                            onChange={(value) => {
                                Dot.set("timestamp", value ? value.toISOString() : value, embed);
                                props.update(embed);
                            }}
                        />
                    </div>
                </div>
            </div>
            {portal &&
                embedSize > 4000 &&
                !props.noSizeCheck &&
                ReactDom.createPortal(
                    <div className="background-01 px-4 py-1 rounded-md flex flex-col" style={{ position: "fixed", left: "50%", transform: "translate(-50%, 0)", top: "5px", zIndex: 10000 }}>
                        <span className="text center">Embed Size</span>
                        <span className="text center">
                            <span className={embedSize > 5999 ? "text-red-500" : embedSize > 4999 ? "text-yellow-500" : "text-green-500"}>{embedSize}</span> / 6000
                        </span>
                    </div>,
                    portal
                )}
        </>
    );
};

export default DiscordEmbedBuilder;
