import React, { useState } from "react";
import Translate from "react-translate-component";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as UUID } from "uuid";
import Select from "react-select";
import { BiEdit, BiPlus, BiTrash, BiShowAlt, BiPlanet, BiSave, BiRocket, BiCopy, BiX } from "react-icons/bi";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

import AppComponent from "@app@::@component";
import AppUtil from "@app@::@util";
import AppData from "@app@::@data";

import Utility from "@utility@::@index";

// const sectionName = "core";
const sectionPath = "settings.core";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const backgroundObjects = useState(<AppComponent.BackgroundObjects totalObjects={20} positionLimit={[0, 115]} sizeLimit={[5, 150]} colors={["#ff0f7b", "#f8acff", "#ffa585", "#f13c77"]} />);
    const activeDrop = React.useState("none");
    const activeEmbedData = React.useState<any>();
    const showPreviewPopup = React.useState(false);
    const showEmbedBuilderPopup = React.useState(false);
    const showEmbedColorPicker = React.useState(false);
    const activeComponentData = React.useState<any>();
    const showComponentBuilderPopup = React.useState(false);
    const deletePopup = React.useState(false);
    const quickInfo = React.useState<any>();
    const selectMenuActive = useState<boolean>(false);

    const messageComponentType = [
        { label: <Translate content="words.button" />, value: 2 },
        { label: <Translate content="words.select_menu" />, value: 3 },
    ];
    const messageComponentStyle = [
        { label: <AppComponent.DiscordComponentPreviewer component={{ type: 2, style: 1, label: activeComponentData[0]?.component?.label }} utils={utils} />, value: 1 },
        { label: <AppComponent.DiscordComponentPreviewer component={{ type: 2, style: 2, label: activeComponentData[0]?.component?.label }} utils={utils} />, value: 2 },
        { label: <AppComponent.DiscordComponentPreviewer component={{ type: 2, style: 3, label: activeComponentData[0]?.component?.label }} utils={utils} />, value: 3 },
        { label: <AppComponent.DiscordComponentPreviewer component={{ type: 2, style: 4, label: activeComponentData[0]?.component?.label }} utils={utils} />, value: 4 },
        { label: <AppComponent.DiscordComponentPreviewer component={{ type: 2, style: 5, label: activeComponentData[0]?.component?.label }} utils={utils} />, value: 5 },
    ];
    const messageComponentLinkType = [
        { label: <Translate content="words.message" />, value: 0 },
        { label: <Translate content="routes.dashboard.selfrole.name" />, value: 1 },
    ];

    return (
        <>
            <section className="dashboard-section noselect">
                <DragDropContext
                    onDragStart={(result) => {
                        activeDrop[1](result.draggableId.split("-")[0]);
                    }}
                    onDragEnd={(result) => {
                        if (!result.destination) return;
                        const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));

                        let sourceMessageId = parseInt(result.source.droppableId.split("-")[1]),
                            destinationMessageId = parseInt(result.destination.droppableId.split("-")[1]);

                        const [recordedSourceMessage] = items.splice(sourceMessageId, 1);

                        if (result.draggableId.split("-")[0] === "embed") {
                            if (destinationMessageId === sourceMessageId) {
                                const [recordedEmbed] = recordedSourceMessage.embeds.splice(result.source.index, 1);
                                recordedSourceMessage.embeds.splice(result.destination.index, 0, recordedEmbed);
                                items.splice(destinationMessageId, 0, recordedSourceMessage);
                            } else {
                                const [recordedDestinationMessage] = items.splice(destinationMessageId - 1, 1);
                                const [recordedEmbed] = recordedSourceMessage.embeds.splice(result.source.index, 1);
                                recordedDestinationMessage.embeds.splice(result.destination.index, 0, recordedEmbed);
                                items.splice(sourceMessageId, 0, recordedSourceMessage);
                                items.splice(destinationMessageId, 0, recordedDestinationMessage);
                            }
                        } else if (result.draggableId.split("-")[0] === "component") {
                            if (destinationMessageId === sourceMessageId) {
                                const [recordedEmbed] = recordedSourceMessage.components.splice(result.source.index, 1);
                                recordedSourceMessage.components.splice(result.destination.index, 0, recordedEmbed);
                                items.splice(destinationMessageId, 0, recordedSourceMessage);
                            } else {
                                const [recordedDestinationMessage] = items.splice(destinationMessageId - 1, 1);
                                const [recordedEmbed] = recordedSourceMessage.components.splice(result.source.index, 1);
                                recordedDestinationMessage.components.splice(result.destination.index, 0, recordedEmbed);
                                items.splice(sourceMessageId, 0, recordedSourceMessage);
                                items.splice(destinationMessageId, 0, recordedDestinationMessage);
                            }
                        }

                        config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                        activeDrop[1]("none");
                    }}>
                    <div className="container mx-auto relative z-index-01 ">
                        <div className="overflow-hidden">{backgroundObjects[0]}</div>
                        <Translate content="routes.dashboard.core.title" component="h1" className="text text-center title-2 drop-shadow-lg " />
                        <div className="float-0-2 absolute top-[-200px] left-[calc(50%-500px)] z-index-01 pointer-events-none">
                            <svg viewBox="0 0 300 300" width="1000" height="1000">
                                <circle cx="150" cy="150" r="50" fill="#FFBF00" />
                                <g transform=" matrix(1.5, -0.5, 0.25, 0.433, 150, 150)">
                                    <path d="M 0,70 A 65,70 0 0,0 65,0 5,5 0 0,1 75,0 75,70 0 0,1 0,70Z" fill="#4361ee">
                                        <animateTransform attributeName="transform" type="rotate" from="360 0 0" to="0 0 0" dur="4s" repeatCount="indefinite" />
                                    </path>
                                </g>
                                <g transform=" matrix(0.866, -1.5, 0.25, 0.433, 150, 150)">
                                    <path d="M 0,70 A 65,70 0 0,0 65,0 5,5 0 0,1 75,0 75,70 0 0,1 0,70Z" fill="#4361ee">
                                        <animateTransform attributeName="transform" type="rotate" from="180 0 0" to="-180 0 0" dur="3s" repeatCount="indefinite" />
                                    </path>
                                </g>
                                <path d="M 50,0 A 50,50 0 0,0 -50,0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 150, 150)" fill="#FFBF00" />
                            </svg>
                        </div>
                        <Translate content="routes.dashboard.core.description" component="h1" className="text text-center subtitle-4 relative z-index-02 drop-shadow-lg " />
                    </div>
                    {config.data.current.settings.core.messages.length <= 0 && (
                        <div className="relative button z-index-02 flex justify-center">
                            <button
                                className="hover02 color-secondary drop-shadow-lg bg-gradient-to-r from-[#f4e784] via-[#f24389] to-[#a478f1] px-2"
                                onClick={() => {
                                    if (config.data.current.settings.core.messages.length > 0) return;
                                    config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: [...config.data.current.settings.core.messages, { id: UUID(), name: utils.translation.routes.dashboard.core.core_message, content: "", embeds: [], components: [] }] });
                                }}>
                                <span className="button-icon both">
                                    <BiRocket />
                                </span>
                                <Translate content="words.activate" className="button-text" />
                            </button>
                        </div>
                    )}
                    <div className="container mx-auto mt-[15rem] mb-32 relative z-index-02">
                        <ul className="core-message-list">
                            {config.data.current.settings.core.messages.map((message, index01) => (
                                <li key={index01} className="message background-02 w-[100%] rounded-lg relative form">
                                    <div className="text text-center title-7">{message.name}</div>
                                    {index01 === 0 && (
                                        <div className="button">
                                            <button className="hover02 color-primary full" onClick={() => {}}>
                                                <span className="button-icon both">
                                                    <BiPlanet />
                                                </span>
                                                <Translate content="words.deploy" className="button-text" />
                                            </button>
                                        </div>
                                    )}
                                    <div className="form-inputs">
                                        {index01 !== 0 && (
                                            <AppComponent.FormInput
                                                type="text"
                                                label={utils.translation.words.message_name}
                                                htmlFor="message-name"
                                                value={message.name}
                                                onChange={(value) => {
                                                    const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                                    const [recordedMessage] = items.splice(index01, 1);
                                                    recordedMessage.name = value.target.value;
                                                    items.splice(index01, 0, recordedMessage);
                                                    config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                                }}
                                                additionalProps={{
                                                    maxLength: 50,
                                                    required: true,
                                                    onBlur: (value: any) => {
                                                        if (value.target.value) return;
                                                        const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                                        const [recordedMessage] = items.splice(index01, 1);
                                                        recordedMessage.name = `${utils.translation.words.message} ${Utility.LeadingZero(index01, 2)}`;
                                                        items.splice(index01, 0, recordedMessage);
                                                        config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                                    },
                                                }}
                                                utils={utils}
                                            />
                                        )}
                                        <AppComponent.FormInput
                                            type="textarea"
                                            label={utils.translation.words.message_content}
                                            htmlFor={`message-content-${index01}`}
                                            value={message.content}
                                            onChange={(value) => {
                                                const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                                const [recordedMessage] = items.splice(index01, 1);
                                                recordedMessage.content = value.target.value;
                                                items.splice(index01, 0, recordedMessage);
                                                config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                            }}
                                            additionalProps={{ maxLength: 2000 }}
                                            utils={utils}
                                        />
                                    </div>
                                    <div className="mt-5"></div>
                                    <Translate content="words.embeds" component="h1" className="text text-center title-8 leading-none" />
                                    <Droppable key={`embeds-${index01}`} droppableId={`embeds-${index01}`} direction={"horizontal"} isDropDisabled={activeDrop[0] !== "embed" || message.embeds.length >= 25}>
                                        {(provided) => (
                                            <ul className="core-message-embed-list" {...provided.droppableProps} ref={provided.innerRef}>
                                                {message.embeds.map((embed: any, index02) => {
                                                    return (
                                                        <Draggable key={index02} draggableId={`embed-${index01}-${index02}`} index={index02}>
                                                            {(provided) => (
                                                                <li className="embed background-03 drop-shadow-lg" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="flex justify-between">
                                                                        <div className="button flex gap-2">
                                                                            <button
                                                                                className="hover02 color-blurple"
                                                                                onClick={() => {
                                                                                    quickInfo[1]({ index01, index02, type: "embed-edit" });
                                                                                    activeEmbedData[1](embed);
                                                                                    showEmbedBuilderPopup[1](true);
                                                                                }}>
                                                                                <span className="button-icon">
                                                                                    <BiEdit />
                                                                                </span>
                                                                            </button>
                                                                            <button
                                                                                className="hover02 color-blurple"
                                                                                onClick={() => {
                                                                                    quickInfo[1]({ index01, index02, type: "embed-preview" });
                                                                                    activeEmbedData[1](embed);
                                                                                    showPreviewPopup[1](true);
                                                                                }}>
                                                                                <span className="button-icon">
                                                                                    <BiShowAlt />
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="button">
                                                                            <button
                                                                                className={`hover02 color-secondary ${message.embeds.length >= 25 ? "disabled" : ""}`}
                                                                                onClick={() => {
                                                                                    if (message.embeds.length >= 25) return;
                                                                                    const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                                                                    const [message01] = items.splice(index01, 1);
                                                                                    message01.embeds.splice(index02 + 1, 0, { ...embed, id: UUID() });
                                                                                    items.splice(index01, 0, message01);
                                                                                    config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                                                                }}>
                                                                                <span className="button-icon">
                                                                                    <BiCopy />
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="background-01 mt-[10px] h-[56px] w-full rounded-lg flex justify-center items-center text font-2 text-[18px]">{Utility.TrimString(embed.name, 18)}</div>
                                                                    <button
                                                                        className="delete-button"
                                                                        onClick={() => {
                                                                            deletePopup[1](true);
                                                                            quickInfo[1]({ index01, index02, type: "embed" });
                                                                        }}>
                                                                        <span className="icon">
                                                                            <BiX />
                                                                        </span>
                                                                    </button>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                                {message.embeds.length < 25 && (
                                                    <div className="button flex h-[120px] m-[1em]">
                                                        <button
                                                            className="hover02 color-success"
                                                            onClick={() => {
                                                                if (message.embeds.length >= 25) return;
                                                                quickInfo[1]({ index01, index02: null, type: "embed-add" });
                                                                activeEmbedData[1]({ id: UUID(), name: `${utils.translation.words.embed} ${Utility.LeadingZero(message.embeds.length + 1, 2)}`, embed: {} });
                                                                showEmbedBuilderPopup[1](true);
                                                            }}>
                                                            <span className="button-icon">
                                                                <BiPlus />
                                                            </span>
                                                        </button>
                                                    </div>
                                                )}
                                            </ul>
                                        )}
                                    </Droppable>
                                    <Translate content="words.components" component="h1" className="text text-center title-8 leading-none" />
                                    <Droppable key={`components-${index01}`} droppableId={`components-${index01}`} direction={"horizontal"} isDropDisabled={activeDrop[0] !== "component" || message.components.length >= 25}>
                                        {(provided) => (
                                            <ul className="core-message-component-list" {...provided.droppableProps} ref={provided.innerRef}>
                                                {message.components.map((component: any, index02) => {
                                                    return (
                                                        <Draggable key={index02} draggableId={`component-${index01}-${index02}`} index={index02}>
                                                            {(provided) => (
                                                                <li className="component background-05 drop-shadow-lg rounded-lg" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className="absolute button-name text font-2 text-[18px] top-0 left-0 w-[250px] h-[60px] flex justify-center items-center">{Utility.TrimString(component.name, 9)}</div>
                                                                    <div className="button flex justify-between">
                                                                        <button
                                                                            className="hover02 color-blurple"
                                                                            onClick={() => {
                                                                                quickInfo[1]({ index01, index02, type: "component-edit" });
                                                                                activeComponentData[1](component);
                                                                                showComponentBuilderPopup[1](true);
                                                                            }}>
                                                                            <span className="button-icon">
                                                                                <BiEdit />
                                                                            </span>
                                                                        </button>
                                                                        <button
                                                                            className={`hover02 color-secondary ${message.components.length >= 25 ? "disabled" : ""}`}
                                                                            onClick={() => {
                                                                                if (message.components.length >= 25) return;
                                                                                const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                                                                const [message01] = items.splice(index01, 1);
                                                                                message01.components.splice(index02 + 1, 0, { ...component, id: UUID() });
                                                                                items.splice(index01, 0, message01);
                                                                                config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                                                            }}>
                                                                            <span className="button-icon">
                                                                                <BiCopy />
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    <button
                                                                        className="delete-button"
                                                                        onClick={() => {
                                                                            deletePopup[1](true);
                                                                            quickInfo[1]({ index01, index02, type: "component" });
                                                                        }}>
                                                                        <span className="icon">
                                                                            <BiX />
                                                                        </span>
                                                                    </button>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                                {message.components.length < 25 && (
                                                    <div className="button flex h-[59px] m-[1em]">
                                                        <button
                                                            className="hover02 color-success"
                                                            onClick={() => {
                                                                if (message.components.length >= 25) return;
                                                                quickInfo[1]({ index01, index02: null, type: "component-add" });
                                                                activeComponentData[1]({ id: UUID(), name: `${utils.translation.words.component} ${Utility.LeadingZero(message.components.length + 1, 2)}`, link: { type: 0, data: {} }, component: { label: utils.translation.words.button, type: 2, style: 1 } });
                                                                showComponentBuilderPopup[1](true);
                                                            }}>
                                                            <span className="button-icon">
                                                                <BiPlus />
                                                            </span>
                                                        </button>
                                                    </div>
                                                )}
                                            </ul>
                                        )}
                                    </Droppable>

                                    <div className="flex justify-between mb-4">
                                        <div className="button flex gap-3">
                                            <button
                                                className="hover02 color-blurple"
                                                onClick={() => {
                                                    let messageData: any = {};
                                                    messageData.content = message.content;
                                                    messageData.embeds = message.embeds?.map((data) => data.embed);
                                                    messageData.components = message.components?.map((data) => data.component);
                                                    quickInfo[1]({ index01, index02: null, type: "message-preview", message: messageData });
                                                    showPreviewPopup[1](true);
                                                }}>
                                                <span className="button-icon both">
                                                    <BiShowAlt />
                                                </span>
                                                <Translate content="words.preview" className="button-text" />
                                            </button>
                                            <button
                                                className={`hover02 color-secondary ${config.data.current.settings.core.messages.length >= 25 ? "disabled" : ""}`}
                                                onClick={() => {
                                                    if (config.data.current.settings.core.messages.length >= 25) return;
                                                    const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                                    items.splice(index01 + 1, 0, { ...message, id: UUID() });
                                                    config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                                }}>
                                                <span className="button-icon">
                                                    <BiCopy />
                                                </span>
                                            </button>
                                        </div>
                                        {index01 !== 0 && (
                                            <div className="button">
                                                <button
                                                    className="hover02 color-danger"
                                                    onClick={() => {
                                                        deletePopup[1](true);
                                                        quickInfo[1]({ index01, type: "message" });
                                                    }}>
                                                    <span className="button-icon both">
                                                        <BiTrash />
                                                    </span>
                                                    <Translate content="words.delete" className="button-text" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ))}
                            {config.data.current.settings.core.messages.length > 0 && (
                                <li className="message-new background-02 w-[100%] rounded-lg relative">
                                    <div className="button">
                                        <button
                                            className={`hover02 color-success full ${config.data.current.settings.core.messages.length >= 25 ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (config.data.current.settings.core.messages.length >= 25) return;
                                                config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: [...config.data.current.settings.core.messages, { id: UUID(), name: `${utils.translation.words.message} ${Utility.LeadingZero(config.data.current.settings.core.messages.length, 2)}`, content: "", embeds: [], components: [] }] });
                                            }}>
                                            <span className="button-icon both">
                                                <BiPlus />
                                            </span>
                                            <Translate content="words.new_message" className="button-text" />
                                        </button>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </DragDropContext>
            </section>
            <AppComponent.PopUp id="DeletePopup" trigger={deletePopup} closeFull close utils={utils}>
                <Translate content="routes.dashboard.core.delete" component="h1" className="text text-center title-8" />
                <div className="button flex justify-between mt-7">
                    <button className="hover02 color-secondary" onClick={() => deletePopup[1](false)}>
                        <Translate content="words.cancel" className="button-text" />
                    </button>
                    <button
                        className="hover02 color-danger"
                        onClick={() => {
                            const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                            const [message] = items.splice(quickInfo[0]?.index01, 1);
                            if (quickInfo[0]?.type === "embed") {
                                message.embeds.splice(quickInfo[0]?.index02, 1);
                                items.splice(quickInfo[0]?.index01, 0, message);
                            } else if (quickInfo[0]?.type === "component") {
                                message.components.splice(quickInfo[0]?.index02, 1);
                                items.splice(quickInfo[0]?.index01, 0, message);
                            }
                            config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                            deletePopup[1](false);
                        }}>
                        <span className="button-icon both">
                            <BiTrash />
                        </span>
                        <Translate content="words.delete" className="button-text" />
                    </button>
                </div>
            </AppComponent.PopUp>
            {activeEmbedData[0] && (
                <AppComponent.PopUp id="EmbedBuilderPopup" trigger={showEmbedBuilderPopup} closeFull={!showEmbedColorPicker[0]} close utils={utils}>
                    <Translate content="words.embed" component="h1" className="text font-2 text-[25px] mb-1" />
                    <div className="form mb-2">
                        <AppComponent.FormInput
                            type="text"
                            label={utils.translation.words.name}
                            htmlFor="embed-name"
                            value={activeEmbedData[0].name}
                            onChange={(value) => activeEmbedData[1]({ ...activeEmbedData[0], name: value.target.value })}
                            additionalProps={{
                                maxLength: 50,
                                required: true,
                                onBlur: (value: any) => {
                                    if (!value.target.value) activeEmbedData[1]({ ...activeEmbedData[0], name: `${utils.translation.words.embed} ${Utility.LeadingZero(config.data.current.settings.core.messages[quickInfo[0].index01].embeds.length + 1, 2)}` });
                                },
                            }}
                            utils={utils}
                        />
                    </div>
                    <AppComponent.DiscordEmbedBuilder showColorPicker={showEmbedColorPicker} utils={utils} embed={activeEmbedData[0].embed} update={(embed: any) => activeEmbedData[1]({ ...activeEmbedData[0], embed })} activeInputElement={() => {}} />
                    <div className="button flex justify-between mt-7">
                        <button className="hover02 color-secondary" onClick={() => showEmbedBuilderPopup[1](false)}>
                            <Translate content="words.cancel" className="button-text" />
                        </button>
                        <button
                            className="hover02 color-success"
                            onClick={() => {
                                if (!AppUtil.ToastDiscordEmbedValidity(activeEmbedData[0].embed) || Utility.GetDiscordEmbedSize(activeEmbedData[0].embed) + Utility.GetDiscordEmbedImageUrlSize(activeEmbedData[0].embed) < 1) return;
                                const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                const [message] = items.splice(quickInfo[0]?.index01, 1);
                                if (quickInfo[0]?.index02 === null) message.embeds.splice(message.embeds.length, 0, { id: UUID(), ...activeEmbedData[0] });
                                else message.embeds.splice(quickInfo[0]?.index02, 1, activeEmbedData[0]);
                                items.splice(quickInfo[0]?.index01, 0, message);
                                config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                showEmbedBuilderPopup[1](false);
                            }}>
                            {quickInfo[0].type === "embed-edit" ? (
                                <>
                                    <span className="button-icon both">
                                        <BiSave />
                                    </span>
                                    <Translate content="words.save" className="button-text" />
                                </>
                            ) : (
                                <>
                                    <span className="button-icon both">
                                        <BiPlus />
                                    </span>
                                    <Translate content="words.add" className="button-text" />
                                </>
                            )}
                        </button>
                    </div>
                </AppComponent.PopUp>
            )}
            {activeComponentData[0] && (
                <AppComponent.PopUp id="EmbedBuilderPopup" trigger={showComponentBuilderPopup} closeFull={!selectMenuActive[0]} close utils={utils}>
                    <div className="form">
                        <Translate content="words.component" component="h1" className="text font-2 text-[25px]" />
                        <div className="form-inputs">
                            <AppComponent.FormInput
                                type="text"
                                label={utils.translation.words.name}
                                htmlFor="component-name"
                                value={activeComponentData[0].name}
                                onChange={(value) => activeComponentData[1]({ ...activeComponentData[0], name: value.target.value })}
                                additionalProps={{
                                    maxLength: 50,
                                    required: true,
                                    onBlur: (value: any) => {
                                        if (!value.target.value) activeComponentData[1]({ ...activeComponentData[0], name: `${utils.translation.words.component} ${Utility.LeadingZero(config.data.current.settings.core.messages[quickInfo[0].index01].components.length + 1, 2)}` });
                                    },
                                }}
                                utils={utils}
                            />
                            <AppComponent.FormInput
                                type="select"
                                className="mb-5"
                                htmlFor="component-type"
                                label={utils.translation.words.type}
                                placeholder={utils.translation.words.select_type}
                                value={messageComponentType.filter((a: any) => a.value === activeComponentData[0].component?.type)}
                                options={messageComponentType.filter((a: any) => a.value !== activeComponentData[0].component?.type)}
                                onChange={(value: any) => {
                                    const data = activeComponentData[0];
                                    data.component.type = value.value;
                                    activeComponentData[1](data);
                                }}
                                additionalProps={{ onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }}
                                utils={utils}
                            />
                        </div>
                        <Translate content="words.details" component="h1" className="text font-2 text-[18px]" />
                        {activeComponentData[0].component?.type === 2 && (
                            <div className="form-inputs">
                                <AppComponent.FormInput
                                    type="text"
                                    label={utils.translation.words.label}
                                    htmlFor="component-label"
                                    value={activeComponentData[0].component?.label}
                                    onChange={(value) => activeComponentData[1]({ ...activeComponentData[0], component: { ...activeComponentData[0].component, label: value.target.value } })}
                                    additionalProps={{
                                        maxLength: 80,
                                        required: true,
                                        onBlur: (value: any) => {
                                            if (!value.target.value) activeComponentData[1]({ ...activeComponentData[0], component: { ...activeComponentData[0].component, label: utils.translation.words.button } });
                                        },
                                    }}
                                    utils={utils}
                                />
                                <AppComponent.FormInput
                                    type="select"
                                    className="mb-5"
                                    htmlFor="component-style"
                                    label={utils.translation.words.style}
                                    placeholder={utils.translation.words.style}
                                    value={messageComponentStyle.filter((a: any) => a.value === activeComponentData[0].component?.style).map((a: any) => ({ ...a, label: <div className="mt-[5px]" children={a.label} /> }))}
                                    options={messageComponentStyle.filter((a: any) => a.value !== activeComponentData[0].component?.style)}
                                    onChange={(value: any) => {
                                        const data = activeComponentData[0];
                                        data.component.style = value.value;
                                        activeComponentData[1](data);
                                    }}
                                    additionalProps={{ onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }}
                                    utils={utils}
                                />
                            </div>
                        )}
                        <Translate content="words.connection" component="h1" className="text font-2 text-[18px]" />
                        <AppComponent.FormInput
                            type="select"
                            className="mb-5"
                            htmlFor="component-link-type"
                            label={utils.translation.words.destination}
                            placeholder={utils.translation.words.destination}
                            value={messageComponentLinkType.filter((a: any) => a.value === activeComponentData[0].link?.type)}
                            options={messageComponentLinkType.filter((a: any) => a.value !== activeComponentData[0].link?.type)}
                            onChange={(value: any) => {
                                const data = activeComponentData[0];
                                data.link.type = value.value;
                                activeComponentData[1](data);
                            }}
                            additionalProps={{ onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }}
                            utils={utils}
                        />

                        <div className="button flex justify-between mt-7">
                            <button className="hover02 color-secondary" onClick={() => showComponentBuilderPopup[1](false)}>
                                <Translate content="words.cancel" className="button-text" />
                            </button>
                            <button
                                className="hover02 color-success"
                                onClick={() => {
                                    const items = JSON.parse(JSON.stringify(config.data.current.settings.core.messages));
                                    const [message] = items.splice(quickInfo[0]?.index01, 1);
                                    if (quickInfo[0]?.index02 === null) message.components.splice(message.components.length, 0, { id: UUID(), ...activeComponentData[0] });
                                    else message.components.splice(quickInfo[0]?.index02, 1, activeComponentData[0]);
                                    items.splice(quickInfo[0]?.index01, 0, message);
                                    config.dispatch({ type: "update", path: `${sectionPath}.messages`, data: items });
                                    showComponentBuilderPopup[1](false);
                                }}>
                                {quickInfo[0].type === "component-edit" ? (
                                    <>
                                        <span className="button-icon both">
                                            <BiSave />
                                        </span>
                                        <Translate content="words.save" className="button-text" />
                                    </>
                                ) : (
                                    <>
                                        <span className="button-icon both">
                                            <BiPlus />
                                        </span>
                                        <Translate content="words.add" className="button-text" />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </AppComponent.PopUp>
            )}
            {quickInfo[0] && <AppComponent.PopUp id="EmbedPreviewPopup" trigger={showPreviewPopup} closeFull close utils={utils} background={"discord-bg noselect"} children={<AppComponent.DiscordMessagePreviewer guild={guild} message={{ author: { username: "Titan-Bot", bot: true, botVerified: true, avatarUrl: AppData.project.client.botAvatar }, ...(quickInfo[0].type === "embed-preview" ? { embeds: [activeEmbedData[0]?.embed] } : quickInfo[0].message) }} utils={utils} />} />}
        </>
    );
};

export default Section;
