import React from "react";
import Translate from "react-translate-component";
import { BiMeteor, BiPlus, BiBarChartAlt2 } from "react-icons/bi";
import { abbreviateNumber as AbbreviateNumber } from "js-abbreviation-number";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

import Utility from "@utility@::@index";

import { DataType } from "@route@::@dashboard:hook";

const Section: React.FC<{ data: DataType; utils: any }> = ({ data: { guilds }, utils }) => (
    <section className="content-area">
        <AppComponent.BackgroundObjects totalObjects={10} positionLimit={[0, 100]} sizeLimit={[10, 300]} />
        <div className="container mx-auto">
            <AppComponent.ScrollReveal data={{ delay: 500, origin: "top", duration: 1200, reset: false }} children={<Translate content="routes.servers.title1" component="h1" className="text title-5 center-text" />} />
            <AppComponent.ScrollReveal data={{ origin: "bottom", reset: false }} children={<div className="sep p10 mx-auto" />} />
        </div>
        <Guilds guilds={guilds} utils={utils} />
    </section>
);

const Guilds: React.FC<{ utils: any; guilds: { id: Snowflake; name: string; icon: string; owner: boolean; commands: number; modules: number; hasClient: boolean }[] }> = ({ utils, guilds }) => {
    const included = guilds.filter((guild) => guild.hasClient);
    const excluded = guilds.filter((guild) => !guild.hasClient);

    return (
        <div className="container mx-auto">
            {guilds.length > 0 ? (
                <>
                    {included.length > 0 && <div className="mt-28" />}
                    <div className="grid-card-menu">
                        {included.map((guild: any, index) => (
                            <AppComponent.ScrollReveal
                                key={index}
                                data={{ delay: 100, origin: Utility.RandomElement(["left", "right", "top", "bottom"]), reset: false }}
                                children={
                                    <div className="card-menu-size">
                                        <div className="card-menu">
                                            <AppComponent.ScrollReveal
                                                data={{ delay: 1500, origin: "top", reset: false }}
                                                children={
                                                    <div className="image-box">
                                                        <img src={guild.icon ? Utility.GetGuildIconUrl(guild.id, guild.icon) + "?size=256" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                                                    </div>
                                                }
                                            />

                                            <div className="content">
                                                <div className="details">
                                                    <h1 className="guild-name">
                                                        {Utility.TrimString(guild.name, 23)}
                                                        <br />
                                                        <Translate content={`routes.servers.words.${guild.owner ? "owner" : "manager"}`} component="span" />
                                                    </h1>
                                                    <div className="data overflow-hidden">
                                                        <h1>
                                                            {AbbreviateNumber(guild.modules)}
                                                            <br />
                                                            <Translate content={`routes.servers.modules_active`} component="span" className="whitespace-nowrap" />
                                                        </h1>
                                                        <h1>
                                                            {AbbreviateNumber(guild.commands)}
                                                            <br />
                                                            <Translate content={`routes.servers.commands_ran`} component="span" className="whitespace-nowrap" />
                                                        </h1>
                                                    </div>
                                                    <div className="button flex justify-center gap-2">
                                                        <button
                                                            className="hover01 color-success"
                                                            onClick={() => {
                                                                utils.use.Navigate(`/dashboard/${guild.id}?section=home`);
                                                            }}>
                                                            <span className="button-icon both">
                                                                <BiMeteor />
                                                            </span>
                                                            <Translate content="routes.servers.buttons.b1" component="span" className="button-text" />
                                                        </button>
                                                        <button
                                                            className="hover01 color-secondary"
                                                            onClick={() => {
                                                                utils.use.Navigate(`/leaderboard/${guild.id}`);
                                                            }}>
                                                            <span className="button-icon">
                                                                <BiBarChartAlt2 />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </div>
                    {included.length > 0 && <AppComponent.ScrollReveal data={{ delay: 600, origin: "bottom", reset: false }} children={<div className="sep p50 mt-24 mx-auto" />} />}
                    <div className="grid-card-menu my-24">
                        {excluded.map((guild, index) => (
                            <AppComponent.ScrollReveal
                                key={index}
                                data={{ delay: 250, origin: Utility.RandomElement(["left", "right", "top", "bottom"]), reset: false }}
                                children={
                                    <div className="card-menu-add-bot">
                                        <AppComponent.ScrollReveal
                                            data={{ delay: 1500, origin: "top", reset: false }}
                                            children={
                                                <div className="image-box">
                                                    <img src={guild.icon ? Utility.GetGuildIconUrl(guild.id, guild.icon) + "?size=256" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                                                </div>
                                            }
                                        />
                                        <div className="content">
                                            <div className="details">
                                                <h1 className="guild-name">{Utility.TrimString(guild.name, 23)}</h1>
                                                <div className="button flex justify-center">
                                                    <button className="hover01 color-success" onClick={() => (window.location.href = AppData.project.client.invite.replace(/{clientId}/g, AppData.project.client.id).replaceAll(/{guildId}/g, guild.id))}>
                                                        <span className="button-icon both">
                                                            <BiPlus />
                                                        </span>
                                                        <Translate content="routes.servers.buttons.b3" component="span" className="button-text" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className="container mx-auto relative background-06 w-fit p-4 rounded-xl mt-5">
                        <Translate content="routes.servers.no_server" component="p" className="text subtitle-3 text-center" />
                    </div>
                </>
            )}
        </div>
    );
};

const Components = { Section, Guilds };

export default Components;
