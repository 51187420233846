import React from "react";
import Translate from "react-translate-component";
import { BiBarChartAlt2, BiCrown, BiEdit, BiReset, BiSave } from "react-icons/bi";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const memberSearchFilter = React.useState<string>("");
    const leaderboardRenderSize = React.useState<number>(100);
    const rankUpdate = React.useState<any>(null);
    const rankUpdatePopup = React.useState<boolean>(false);
    const rankResetPopup = React.useState<boolean>(false);
    const getPremiumPopup = React.useState<boolean>(false);

    const members = config.data.current.data.user.map((user) => ({ id: user.id, xp: user.level.xp, ...guild.members.find((member: any) => member.user?.id === user.id) }));
    const filteredMembers = members
        .filter((a: any) => a.user)
        .sort((a: any, b: any) => b.xp - a.xp)
        .map((a: any, index: any) => ({ ...a, pos: index }))
        .filter((a: any) => a.user.username.toLowerCase().includes(memberSearchFilter[0]) || a.id.toLowerCase().includes(memberSearchFilter[0]));
    const leaderboardList = Utility.TrimArray(filteredMembers, leaderboardRenderSize[0], null);
    const leaderboardRenderObserver = React.useRef<any>();
    const leaderboardRenderLastElementRef = React.useCallback(
        (node: any) => {
            if (leaderboardRenderObserver.current) leaderboardRenderObserver.current.disconnect();
            leaderboardRenderObserver.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && filteredMembers.length !== leaderboardList.length) leaderboardRenderSize[1](leaderboardRenderSize[0] + 100);
            });
            if (node) leaderboardRenderObserver.current.observe(node);
        },
        [leaderboardRenderSize] // eslint-disable-line
    );

    return (
        <>
            <section className="dashboard-section">
                <div className="container mx-auto">
                    <Translate content="routes.dashboard.leaderboard.name" component="h1" className="text font-1 text-[40px] text-center" />
                    <Translate content="routes.dashboard.leaderboard.description" component="h1" className="text subtitle-4 text-center" />
                    <div className="button flex justify-center mt-5 gap-2 mb-5">
                        <button className={`hover02 color-primary ${config.data.current.settings.leaderboard ? "" : "disabled"}`} onClick={() => utils.use.Navigate(`/leaderboard/${guild.id}`)}>
                            <Translate content="words.view_leaderboard" className="button-text both" />
                            <span className="button-icon">
                                <BiBarChartAlt2 />
                            </span>
                        </button>
                        <button
                            className={`hover02 color-${config.data.current.settings.leaderboard ? "danger" : "success"}`}
                            onClick={() => {
                                config.dispatch({ type: "update", path: "settings.leaderboard", data: !config.data.current.settings.leaderboard });
                                config.dispatch({ type: "save", data: { guild, update: extra.update, refetch: extra.refetch } });
                            }}>
                            <Translate content={`words.${config.data.current.settings.leaderboard ? "disable" : "enable"}`} className="button-text" />
                        </button>
                    </div>
                </div>
                <div className="server-leaderboard manager relative container mx-auto mb-20">
                    <div className="background-02 p-4 rounded-[10px] max-w-[500px] mx-auto form">
                        <AppComponent.FormInput
                            type="text"
                            placeholder={utils.translation.routes.dashboard.leaderboard.search + "..."}
                            htmlFor="search"
                            onChange={(value) => {
                                memberSearchFilter[1](value.target.value.toLowerCase());
                                leaderboardRenderSize[1](100);
                            }}
                            utils={utils}
                        />
                    </div>
                    <div className="leaderboard-list background-02 mt-3 mx-auto">
                        <div className="leaderboard-list-item background-03">
                            <div className="left-info">
                                <h1 className="text font-1 text-[13px] text-center w-[50px]">#</h1>
                                <h1 className="text font-1 text-[13px] text-center min-w-[50px]">
                                    <Translate content="words.member" />
                                </h1>
                            </div>
                        </div>
                        {leaderboardList.map((member: any, index: any) => (
                            <AppComponent.Tooltip
                                key={member.pos}
                                content={
                                    <div className="text-center">
                                        {member.user?.username}#{member.user?.discriminator}
                                        <br />
                                        {member.xp.toLocaleString()} XP - <Translate content="words.level" /> {Utility.GetServerLevelFromXP(member.xp) > config.data.current.modules.leveling.settings.maxLevel ? config.data.current.modules.leveling.settings.maxLevel : Utility.GetServerLevelFromXP(member.xp)}
                                    </div>
                                }
                                children={
                                    <div ref={index + 1 === leaderboardList.length ? leaderboardRenderLastElementRef : undefined} className="leaderboard-list-item lb-item background-03">
                                        <div className="left-info">
                                            <div className="leaderboard-position background-01">
                                                <h1 className="text font-1 text-[25px] text-center">{member.pos + 1}</h1>
                                            </div>
                                            <img className="leaderboard-avatar" src={member?.avatar ? Utility.GetMemberAvatarUrl(guild.id, member.user.id, member.avatar) + "?size=128" : member.user?.avatar ? Utility.GetUserAvatarUrl(member.user.id, member.user.avatar) + "?size=128" : `${process.env.PUBLIC_URL}/media/png/discord.png`} alt="icon" />
                                            <h1 className="leaderboard-name manager text font-2 text-left">
                                                {member.user?.username}#{member.user?.discriminator}
                                                <br />
                                                <Translate content="words.level" /> {Utility.GetServerLevelFromXP(member.xp) > config.data.current.modules.leveling.settings.maxLevel ? config.data.current.modules.leveling.settings.maxLevel : Utility.GetServerLevelFromXP(member.xp)}
                                            </h1>
                                        </div>
                                        <div className="right-info">
                                            <div className="button flex gap-2">
                                                <button
                                                    className={`hover02 ${!config.data.previous.settings.premium.status ? "color-gold" : "color-primary"}`}
                                                    onClick={() => {
                                                        if (!config.data.previous.settings.premium.status) return getPremiumPopup[1](true);
                                                        rankUpdate[1]({ id: member.id, xp: member.xp, name: `${member.user?.username}#${member.user?.discriminator}` });
                                                        rankUpdatePopup[1](true);
                                                    }}>
                                                    <Translate content="words.edit" className="button-text both" />
                                                    <span className="button-icon">{!config.data.previous.settings.premium.status ? <BiCrown /> : <BiEdit />}</span>
                                                </button>
                                                <button
                                                    className="hover02 color-danger"
                                                    onClick={() => {
                                                        rankUpdate[1]({ id: member.id, xp: member.xp, name: `${member.user?.username}#${member.user?.discriminator}` });
                                                        rankResetPopup[1](true);
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiReset />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        ))}
                    </div>
                </div>
            </section>
            {rankUpdate[0] && config.data.previous.settings.premium.status && (
                <AppComponent.PopUp id="RankResetPopup" trigger={rankUpdatePopup} utils={utils} close closeFull>
                    <Translate content="routes.dashboard.leaderboard.edit" component="h1" className="text title-7" with={{ name: rankUpdate[0].name }} />
                    <div className="form form-inputs">
                        <AppComponent.FormInput
                            type="number"
                            label={utils.translation.words.level}
                            htmlFor="level"
                            value={Utility.GetServerLevelFromXP(rankUpdate[0].xp) > config.data.current.modules.leveling.settings.maxLevel ? config.data.current.modules.leveling.settings.maxLevel : Utility.GetServerLevelFromXP(rankUpdate[0].xp)}
                            onChange={(value) => {
                                let num = parseInt(value.target.value);
                                if (isNaN(num)) num = 0;
                                const xp = Utility.GetServerLevelXPRequirement(num < 0 ? 0 : num > config.data.current.modules.leveling.settings.maxLevel ? config.data.current.modules.leveling.settings.maxLevel : num);
                                rankUpdate[1]({ ...rankUpdate[0], xp });
                            }}
                            additionalProps={{ max: config.data.current.modules.leveling.settings.maxLevel, min: 0 }}
                            utils={utils}
                        />
                        <AppComponent.FormInput
                            type="number"
                            label={"XP"}
                            htmlFor="xp"
                            value={rankUpdate[0].xp}
                            onChange={(value) => {
                                let num = parseInt(value.target.value);
                                if (isNaN(num)) num = 0;
                                const xp = num < 0 ? 0 : num > 1824907900 ? 1824907900 : num;
                                rankUpdate[1]({ ...rankUpdate[0], xp });
                            }}
                            additionalProps={{ max: 1824907900, min: 0 }}
                            utils={utils}
                        />
                    </div>
                    <div className="button flex gap-2 justify-between mt-5">
                        <button className="hover02 color-secondary" onClick={() => rankUpdate[1](false)}>
                            <Translate content="words.cancel" className="button-text" />
                        </button>
                        <button
                            className="hover02 color-success"
                            onClick={() => {
                                rankUpdatePopup[1](false);
                                config.dispatch({
                                    type: "update",
                                    path: "data.user",
                                    data: config.data.current.data.user.map((user) => {
                                        let newUser = JSON.parse(JSON.stringify(user));
                                        if (user.id === rankUpdate[0].id) {
                                            newUser.level.xp = rankUpdate[0].xp;
                                            newUser.updated = true;
                                        }
                                        return newUser;
                                    }),
                                });
                            }}>
                            <Translate content="words.edit" className="button-text both" />
                            <span className="button-icon">
                                <BiSave />
                            </span>
                        </button>
                    </div>
                </AppComponent.PopUp>
            )}
            {rankUpdate[0] && (
                <AppComponent.PopUp id="RankResetPopup" trigger={rankResetPopup} utils={utils} close closeFull>
                    <Translate content="routes.dashboard.leaderboard.reset" component="h1" className="text subtitle-4 text-center" with={{ name: rankUpdate[0].name }} />
                    <div className="button flex gap-2 justify-between mt-5">
                        <button className="hover02 color-secondary" onClick={() => rankResetPopup[1](false)}>
                            <Translate content="words.cancel" className="button-text" />
                        </button>
                        <button
                            className="hover02 color-danger"
                            onClick={() => {
                                rankResetPopup[1](false);
                                config.dispatch({
                                    type: "update",
                                    path: "data.user",
                                    data: config.data.current.data.user.map((user) => {
                                        let newUser = JSON.parse(JSON.stringify(user));
                                        if (user.id === rankUpdate[0].id) {
                                            newUser.level.xp = 0;
                                            newUser.updated = true;
                                        }
                                        return newUser;
                                    }),
                                });
                            }}>
                            <Translate content="words.reset" className="button-text both" />
                            <span className="button-icon">
                                <BiReset />
                            </span>
                        </button>
                    </div>
                </AppComponent.PopUp>
            )}
            <AppComponent.PopUp background="premium-bg" id="AddNewSelfRolePopup" trigger={getPremiumPopup} closeFull close utils={utils}>
                <span className="noselect w-full">
                    <Translate content="words.server_premium_feature" component="h1" className="text title-6 text-center white-lock" />
                    <Translate content="routes.dashboard.leaderboard.premium" className="text subtitle-3 center white-lock" />
                    <div className="button flex justify-center mt-6">
                        <button className="hover02 color-secondary shadow bg-gradient-to-r from-[#d397fa] to-[#8364e8] disabled" onClick={() => {}}>
                            <Translate content="routes.profile.subscription.get_server_premium" className="button-text" />
                        </button>
                    </div>
                </span>
            </AppComponent.PopUp>
        </>
    );
};

export default Section;
