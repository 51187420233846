import React from "react";

import Utility from "@utility@::@index";

const BackgroundObjects: React.FC<{ totalObjects: number; positionLimit: number[]; sizeLimit: number[]; colors?: string[]; float?: boolean }> = React.memo(({ totalObjects, positionLimit, sizeLimit, colors, float }) => {
    let bubbles = [];
    if (!colors || colors?.length < 1) colors = ["rgb(192 132 252)"];

    for (let i = 0; i < totalObjects; i++) {
        let style: any = {};

        const classname = `bg-object rounded-full list-none ${float ? `float-${Utility.RandomInt(0, 1)}-${Utility.RandomInt(1, 3)}` : ""}`;
        const color = Utility.RandomElement(colors);
        const size = `${Utility.RandomInt(sizeLimit[0], sizeLimit[1])}px`;
        const posX = `${Utility.RandomInt(positionLimit[0], positionLimit[1])}%`;
        const posY = `${Utility.RandomInt(positionLimit[0], positionLimit[1])}%`;

        style.width = size;
        style.height = size;
        style.left = posX;
        style.top = posY;
        style.backgroundColor = color;

        bubbles.push(<li key={i} className={classname} style={style}></li>);
    }
    return <ul className="bg-object-container">{bubbles.map((a) => a)}</ul>;
});

export default BackgroundObjects;
