import React, { useState, useReducer } from "react";
import { BiTrash, BiInfoCircle, BiPlus, BiEdit, BiSave, BiCrown } from "react-icons/bi";
import { TiWarning } from "react-icons/ti";
import { VscMegaphone } from "react-icons/vsc";
import { toast } from "react-toastify";
import Select from "react-select";
import Dot from "dot-object";
import Lodash from "lodash";
import Translate from "react-translate-component";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import DashboardComponent from "./index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "autopublish";
    const sectionPath = "modules.autoPublish";
    const sectionData = JSON.parse(JSON.stringify(Dot.pick(sectionPath, config.data.current)));

    const [ReducerData, ReducerDispatch] = useReducer(SectionReducer, { id: guild.id, channel: undefined });

    const addNewPopup = useState<boolean>(false);
    const selectMenuActive = useState<boolean>(false);
    const getPremiumPopup = useState<boolean>(false);

    const announcementChannels = extra.channelOptions([5]).map((a: any) => {
        a.options = a.options.filter((b: any) => !sectionData.data.channels.find((c: any) => b.value === c.id));
        return a;
    });

    const isEditingChannel = sectionData.data.channels.find((channel: any) => channel.id === ReducerData.channel?.id);

    return (
        <>
            <section className="dashboard-section">
                <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
                <div className="container mx-auto mb-20">
                    {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                    {sectionData.active && (
                        <div className="dashboard-multi-add-list">
                            <div className="list-bar">
                                <div className="button flex">
                                    <button
                                        className={`hover01 ${sectionData.data.channels.length >= 2 && !config.data.previous.settings.premium.status ? "color-gold" : "color-success"} ${sectionData.data.channels.length >= 10 ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (sectionData.data.channels.length >= 10) return;
                                            if (!config.data.previous.settings.premium.status && sectionData.data.channels.length >= 2) return getPremiumPopup[1](true);
                                            ReducerDispatch({ type: "new" });
                                            addNewPopup[1](true);
                                        }}>
                                        <Translate content="words.add_new" className="button-text both" />
                                        <span className="button-icon">{sectionData.data.channels.length >= 2 && !config.data.previous.settings.premium.status ? <BiCrown /> : <BiPlus />}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="list">
                                {sectionData.data.channels.map((channel: any, index: any) => (
                                    <div key={index} className="card autopublish-channel-list-card" id={`autopublish-channel-list-card-${index}`}>
                                        <div className="main flex justify-between items-center overflow-hidden ">
                                            <div className={`text title-8 title flex gap-1 items-center break-all ${index >= 2 && !config.data.previous.settings.premium.status ? "opacity-50" : ""}`}>
                                                <span className="text text-[30px]">
                                                    <VscMegaphone />
                                                </span>
                                                {guild.channels.find((guildChannel) => guildChannel.id === channel.id)?.name || "Unknown Channel"}
                                            </div>
                                            <div className="button flex gap-3 items-center">
                                                {index >= 2 && !config.data.previous.settings.premium.status && <AppComponent.Tooltip content={<Translate content="routes.dashboard.autopublish.premium_required" />} children={<span className="icon" children={<BiCrown className="text text-[30px]" style={{ color: "#fce03a" }} />} />} />}
                                                {!guild.channels.find((guildChannel) => guildChannel.id === channel.id) && <AppComponent.Tooltip content={<Translate content="errors.channel_unknown" />} children={<span className="icon" children={<TiWarning className="text text-[30px]" style={{ color: "#f04747" }} />} />} />}
                                                <button
                                                    className={`hover02 color-blurple ${!guild.channels.find((guildChannel) => guildChannel.id === channel.id) || (index >= 2 && !config.data.previous.settings.premium.status) ? "disabled" : ""}`}
                                                    onClick={() => {
                                                        if (!guild.channels.find((guildChannel) => guildChannel.id === channel.id) || (index >= 2 && !config.data.previous.settings.premium.status)) return;
                                                        ReducerDispatch({ type: "set", data: channel });
                                                        addNewPopup[1](true);
                                                    }}>
                                                    <Translate content="words.edit" className="button-text both" />
                                                    <span className="button-icon">
                                                        <BiEdit />
                                                    </span>
                                                </button>
                                                <button
                                                    className="hover02 color-danger"
                                                    onClick={() => {
                                                        const data = JSON.parse(JSON.stringify(sectionData.data.channels));
                                                        config.dispatch({ type: "update", path: `${sectionPath}.data.channels`, data: data.filter((a: any) => a.id !== channel.id) });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiTrash />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <AppComponent.PopUp id="AddNewAutoPublishChannelPopup" trigger={addNewPopup} closeFull={!selectMenuActive[0]} close utils={utils}>
                <Translate content={`routes.dashboard.autopublish.${isEditingChannel ? "edit_channel" : "new_channel"}`} component="h1" className="text title-7" />
                <div className="form form-inputs">
                    <AppComponent.FormInput
                        type="select"
                        disabled={isEditingChannel}
                        htmlFor="channel"
                        label={utils.translation.words.channel}
                        placeholder={utils.translation.words.select_ann_channel}
                        tooltip={utils.translation.routes.dashboard.autopublish.channel_i}
                        value={Utility.GuildDataFilterIgnore([ReducerData.channel?.id], guild.channels)}
                        options={announcementChannels}
                        onChange={(value: any) => {
                            if (isEditingChannel) return;
                            ReducerDispatch({ type: "update", data: value.value, path: "id" });
                        }}
                        additionalProps={{
                            onMenuOpen: () => selectMenuActive[1](true),
                            onMenuClose: () => selectMenuActive[1](false),
                        }}
                        utils={utils}
                    />
                    <AppComponent.FormInput
                        type="toggle-checkbox"
                        htmlFor="ignore-users-publish"
                        label={utils.translation.routes.dashboard.autopublish.ignore_users}
                        tooltip={utils.translation.routes.dashboard.autopublish.ignore_users_i}
                        value={ReducerData.channel?.settings.publishUsers ? false : true}
                        onChange={(value) => {
                            ReducerDispatch({ type: "update", data: !value.target.checked, path: "settings.publishUsers" });
                            ReducerDispatch({ type: "update", data: ReducerData.channel.settings.publishUsers && !ReducerData.channel.settings.publishBots ? true : ReducerData.channel.settings.publishBots, path: "settings.publishBots" });
                        }}
                        additionalProps={{ togglePlacement: "left" }}
                        utils={utils}
                    />
                    <AppComponent.FormInput
                        type="toggle-checkbox"
                        htmlFor="ignore-bots-publish"
                        label={utils.translation.routes.dashboard.autopublish.ignore_bots}
                        tooltip={utils.translation.routes.dashboard.autopublish.ignore_bots_i}
                        value={ReducerData.channel?.settings.publishBots ? false : true}
                        onChange={(value) => {
                            ReducerDispatch({ type: "update", data: !value.target.checked, path: "settings.publishBots" });
                            ReducerDispatch({ type: "update", data: ReducerData.channel.settings.publishBots && !ReducerData.channel.settings.publishUsers ? true : ReducerData.channel.settings.publishUsers, path: "settings.publishUsers" });
                        }}
                        additionalProps={{ togglePlacement: "left" }}
                        utils={utils}
                    />
                </div>
                <div className="button flex justify-between mt-6">
                    <button className="hover02 color-secondary" onClick={() => addNewPopup[1](false)}>
                        <Translate content="words.cancel" className="button-text" />
                    </button>
                    <button
                        className="hover02 color-success"
                        onClick={() => {
                            if (!ReducerData.channel.id) return toast(<Translate content="routes.dashboard.autopublish.channel_required" />, { type: "error", autoClose: 5000 });
                            if (isEditingChannel) {
                                const index = sectionData.data.channels.findIndex((channel: any) => channel.id === ReducerData.channel?.id);
                                if (index < 0) return;
                                else if (Lodash.isEqual(sectionData.data.channels[index], ReducerData.channel)) return toast(<Translate content="errors.no_changes" />, { type: "error", autoClose: 5000 });
                                sectionData.data.channels[index] = ReducerData.channel;
                            } else sectionData.data.channels.push(ReducerData.channel);
                            config.dispatch({ type: "update", path: `${sectionPath}.data.channels`, data: sectionData.data.channels });
                            addNewPopup[1](false);
                        }}>
                        {isEditingChannel ? (
                            <>
                                <Translate content="words.save" className="button-text both" />
                                <span className="button-icon">
                                    <BiSave />
                                </span>
                            </>
                        ) : (
                            <>
                                <Translate content="words.add" className="button-text both" />
                                <span className="button-icon">
                                    <BiPlus />
                                </span>
                            </>
                        )}
                    </button>
                </div>
            </AppComponent.PopUp>
            <AppComponent.PopUp background="premium-bg" id="AddNewSelfRolePopup" trigger={getPremiumPopup} closeFull close utils={utils}>
                <span className="noselect w-full">
                    <Translate content="words.server_premium_feature" component="h1" className="text title-6 text-center white-lock" />
                    <Translate content="routes.dashboard.autopublish.premium" className="text subtitle-3 center white-lock" />
                    <div className="button flex justify-center mt-6">
                        <button className="hover02 color-secondary shadow bg-gradient-to-r from-[#d397fa] to-[#8364e8] disabled" onClick={() => {}}>
                            <Translate content="routes.profile.subscription.get_server_premium" className="button-text" />
                        </button>
                    </div>
                </span>
            </AppComponent.PopUp>
        </>
    );
};

const SectionReducer = (state: DashboardInterface.ModuleReducer, action: { type: string; path?: string; data?: any }): any => {
    let data = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case "new":
            Dot.set("channel", { id: "", settings: { publishBots: true, publishUsers: true } }, data);
            break;
        case "update":
            Dot.set(`channel.${action.path}`, action.data, data);
            break;
        case "set":
            Dot.set("channel", action.data, data);
            break;
        default:
            break;
    }
    return data;
};

export default Section;
