import React, { useState } from "react";
import { BiCheck, BiCrown, BiEdit, BiInfoCircle, BiPlus, BiTrash } from "react-icons/bi";
import Dot from "dot-object";
import Translate from "react-translate-component";
import Select from "react-select";

import AppComponent from "@app@::@component";

import Utility from "@utility@::@index";

import DashboardComponent from "./index";

import * as DashboardInterface from "@route@::@dashboard:id:interface";
import { useRef } from "react";

const Section: React.FC<DashboardInterface.ModuleSectionProps> = ({ utils, profile, config, guild, commands, extra }) => {
    const sectionName = "leveling";
    const sectionPath = "modules.leveling";
    const sectionData = JSON.parse(JSON.stringify(Dot.pick(sectionPath, config.data.current)));

    const levelUpMessageRef = useRef<any>();

    const selectMenuActive = useState<boolean>(false);
    const rewardRoleData = useState<any>(null);
    const rewardRoles = useState<any>(null);
    const getRewardRolePopup = useState<boolean>(false);
    const isEditingReward = useState<boolean>(false);
    const getPremiumPopup = useState<boolean>(false);
    const getPremiumPopupElement = useState<any>();

    const levelUpMessageType = [
        { label: <Translate content="words.disabled" />, value: 0 },
        { label: <Translate content="words.dm" />, value: 1 },
        { label: <Translate content="words.current_channel" />, value: 2 },
        { label: <Translate content="words.custom_channel" />, value: 3 },
    ];

    const addLevelUpMessageQuickSet = (value: string) => {
        const element: HTMLInputElement = document.getElementById("leveling-settings-levelup-message") as any;
        if (!element) return;
        const start = element.selectionStart ?? 0;
        const end = element.selectionEnd ?? 0;
        const message = Utility.SpliceSlice(sectionData.settings.levelUp.message, start, end - start, `{${value}}`);
        if (message.length <= 2000) config.dispatch({ type: "update", path: "modules.leveling.settings.levelUp.message", data: message });
        element?.focus();
    };

    return (
        <>
            <section className="dashboard-section grid place-items-center noselect">
                <div className="container mx-auto" children={<DashboardComponent.ModuleTitle utils={utils} config={config} sectionName={sectionName} sectionData={sectionData} sectionPath={sectionPath} extra={extra} />} />
                <div className="container mx-auto mb-20 overflow-hidden">
                    {!sectionData.active && <DashboardComponent.ModuleDisabled />}
                    {sectionData.active && (
                        <div>
                            <div className="background-02 w-full rounded-[8px] py-2 px-4 pb-5 mb-5 form">
                                <>
                                    <div className="text title-8">
                                        <Translate content="routes.dashboard.leveling.xp_rate" component="span" />
                                        <span className="text color-02 ml-2">{sectionData.settings.xpRate}x</span>
                                    </div>
                                    <Translate content="routes.dashboard.leveling.xp_rate_i" component="h1" className="text subtitle-4" />
                                    <div className="flex justify-center mb-7 mt-2">
                                        <div className="range text font-3 background-03">
                                            <div className="field">
                                                <input
                                                    className="background-01"
                                                    type="range"
                                                    min={0.25}
                                                    max={3}
                                                    step={0.25}
                                                    value={sectionData.settings.xpRate}
                                                    onChange={(value) => {
                                                        if (parseFloat(value.target.value) > 1.25 && !config.data.previous.settings.premium.status) {
                                                            value.target.value = "1.25";
                                                            getPremiumPopup[1](true);
                                                            getPremiumPopupElement[1](<Translate content="routes.dashboard.leveling.xp_rate_premium" className="text subtitle-3 center white-lock" />);
                                                        }
                                                        config.dispatch({ type: "update", path: "modules.leveling.settings.xpRate", data: parseFloat(value.target.value) });
                                                    }}
                                                />
                                                <div className="value left-[0%] text-center">0.25x</div>
                                                <div className="value left-[9.2%] text-center">0.5x</div>
                                                <div className="value left-[27.5%] text-center">1x</div>
                                                <div className="value gold left-[44.5%] text-center">1.5x</div>
                                                <div className="value gold left-[62.8%] text-center">2x</div>
                                                <div className="value gold left-[80%] text-center">2.5x</div>
                                                <div className="value gold left-[99%] text-center">3x</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                <>
                                    <Translate content="routes.dashboard.leveling.levelup_message" component="h1" className="text title-8" />
                                    <Translate content="routes.dashboard.leveling.levelup_message_i" component="h1" className="text subtitle-4" />
                                    <div className="form-inputs">
                                        <AppComponent.FormInput type="select" label={utils.translation.words.type} value={levelUpMessageType.filter((a: any) => a.value === sectionData.settings.levelUp.type)} options={levelUpMessageType.filter((a: any) => a.value !== sectionData.settings.levelUp.type)} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.levelUp.type", data: value.value })} additionalProps={{ onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }} utils={utils} />
                                        {sectionData.settings.levelUp.type !== 0 && (
                                            <>
                                                {sectionData.settings.levelUp.type === 3 && <AppComponent.FormInput type="select" htmlFor="self-role-menu-channel" label={utils.translation.words.channel} placeholder={utils.translation.words.select_channel} value={Utility.GuildDataFilterIgnore([sectionData.settings.levelUp.channelId], guild.channels)} options={extra.channelOptions()} onChange={(value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.levelUp.channelId", data: value ? value.value : "" })} additionalProps={{ isClearable: true, onMenuOpen: () => selectMenuActive[1](true), onMenuClose: () => selectMenuActive[1](false) }} utils={utils} />}
                                                <div ref={levelUpMessageRef}>
                                                    <AppComponent.FormInput
                                                        type="textarea"
                                                        label={utils.translation.words.message}
                                                        placeholder={utils.translation.routes.dashboard.leveling.levelup_message}
                                                        htmlFor="leveling-settings-levelup-message"
                                                        value={sectionData.settings.levelUp.message}
                                                        onChange={(value) => {
                                                            config.dispatch({ type: "update", path: "modules.leveling.settings.levelUp.message", data: value.target.value });
                                                        }}
                                                        additionalProps={{ maxLength: 2000 }}
                                                        utils={utils}
                                                    />
                                                </div>
                                                <div className="tag-buttons">
                                                    <button onClick={() => addLevelUpMessageQuickSet("member")}>Member</button>
                                                    <button onClick={() => addLevelUpMessageQuickSet("member:username")}>Username</button>
                                                    <button onClick={() => addLevelUpMessageQuickSet("member:nickname")}>Nickname</button>
                                                    <button onClick={() => addLevelUpMessageQuickSet("member:tag")}>Tag</button>
                                                    <button onClick={() => addLevelUpMessageQuickSet("level")}>Level</button>
                                                    <button onClick={() => addLevelUpMessageQuickSet("xp")}>XP</button>
                                                    <button onClick={() => addLevelUpMessageQuickSet("server")}>Server</button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="mb-7" />
                                </>
                                <h1 className="text title-8 info-icon">
                                    <Translate content="words.blacklist" /> / <Translate content="words.whitelist" />
                                    <AppComponent.Tooltip
                                        content={
                                            <>
                                                <Translate content="words.blacklist" component="code" /> <Translate content="routes.dashboard.leveling.blacklist_i" />
                                                <br />
                                                <Translate content="words.whitelist" component="code" /> <Translate content="routes.dashboard.leveling.whitelist_i" />
                                            </>
                                        }
                                        children={<span className="icon" children={<BiInfoCircle />} />}
                                    />
                                </h1>
                                <div className="form-inputs">
                                    <div className="grid-col-2 gap-select items-end mt-0">
                                        <AppComponent.FormInput
                                            type="select"
                                            htmlFor="self-role-xp-channel-blacklist"
                                            label={utils.translation.words.blacklisted_channels}
                                            placeholder={utils.translation.words.select_channels}
                                            value={sectionData.settings.xpChannels.type === 1 ? Utility.GuildDataFilterIgnore(sectionData.settings.xpChannels.list, guild.channels) : []}
                                            options={extra.channelOptions([0, 5])}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpChannels.list", data: value.map((a: any) => a?.value) })}
                                            additionalProps={{
                                                isMulti: true,
                                                closeMenuOnSelect: false,
                                                onFocus: (value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpChannels.type", data: 1 }),
                                                onBlur: (value: any) => (sectionData.settings.xpChannels.list.length < 1 ? config.dispatch({ type: "update", path: "modules.leveling.settings.xpChannels.type", data: 0 }) : null),
                                            }}
                                            utils={utils}
                                        />
                                        <AppComponent.FormInput
                                            type="select"
                                            htmlFor="self-role-xp-channel-whitelist"
                                            label={utils.translation.words.whitelisted_channels}
                                            placeholder={utils.translation.words.select_channels}
                                            value={sectionData.settings.xpChannels.type === 2 ? Utility.GuildDataFilterIgnore(sectionData.settings.xpChannels.list, guild.channels) : []}
                                            options={extra.channelOptions([0, 5])}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpChannels.list", data: value.map((a: any) => a?.value) })}
                                            additionalProps={{
                                                isMulti: true,
                                                closeMenuOnSelect: false,
                                                onFocus: (value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpChannels.type", data: 2 }),
                                                onBlur: (value: any) => (sectionData.settings.xpChannels.list.length < 1 ? config.dispatch({ type: "update", path: "modules.leveling.settings.xpChannels.type", data: 0 }) : null),
                                            }}
                                            utils={utils}
                                        />
                                        <AppComponent.FormInput
                                            type="select"
                                            htmlFor="self-role-xp-role-blacklist"
                                            label={utils.translation.words.blacklisted_roles}
                                            placeholder={utils.translation.words.select_roles}
                                            value={sectionData.settings.xpRoles.type === 1 ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(sectionData.settings.xpRoles.list, guild.roles, true)) : []}
                                            options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpRoles.list", data: value.map((a: any) => a?.value) })}
                                            additionalProps={{
                                                isMulti: true,
                                                closeMenuOnSelect: false,
                                                onFocus: (value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpRoles.type", data: 1 }),
                                                onBlur: (value: any) => (sectionData.settings.xpRoles.list.length < 1 ? config.dispatch({ type: "update", path: "modules.leveling.settings.xpRoles.type", data: 0 }) : null),
                                            }}
                                            utils={utils}
                                        />
                                        <AppComponent.FormInput
                                            type="select"
                                            htmlFor="self-role-xp-role-whitelist"
                                            label={utils.translation.words.whitelisted_roles}
                                            placeholder={utils.translation.words.select_roles}
                                            value={sectionData.settings.xpRoles.type === 2 ? Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore(sectionData.settings.xpRoles.list, guild.roles, true)) : []}
                                            options={Utility.DiscordRolesToDropdownOptions(extra.roleOptions(Utility.RoleOptionsFilter(["NO_EVERYONE", "NO_BOTS"])))}
                                            onChange={(value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpRoles.list", data: value.map((a: any) => a?.value) })}
                                            additionalProps={{
                                                isMulti: true,
                                                closeMenuOnSelect: false,
                                                onFocus: (value: any) => config.dispatch({ type: "update", path: "modules.leveling.settings.xpRoles.type", data: 2 }),
                                                onBlur: (value: any) => (sectionData.settings.xpRoles.list.length < 1 ? config.dispatch({ type: "update", path: "modules.leveling.settings.xpRoles.type", data: 0 }) : null),
                                            }}
                                            utils={utils}
                                        />
                                    </div>

                                    <div
                                        onClick={() => {
                                            if (config.data.previous.settings.premium.status) return;
                                            getPremiumPopup[1](true);
                                            getPremiumPopupElement[1](<Translate content="routes.dashboard.leveling.max_level_premium" className="text subtitle-3 center white-lock" />);
                                        }}>
                                        <AppComponent.FormInput
                                            className={!config.data.previous.settings.premium.status ? "premium" : ""}
                                            type="number"
                                            label={utils.translation.routes.dashboard.leveling.max_level}
                                            tooltip={utils.translation.routes.dashboard.leveling.max_level_i}
                                            htmlFor="max-level"
                                            value={sectionData.settings.maxLevel}
                                            onChange={(value: any) => {
                                                if (!config.data.previous.settings.premium.status) return;
                                                config.dispatch({ type: "update", path: "modules.leveling.settings.maxLevel", data: value.target.value > 1000 && config.data.previous.settings.premium.status ? 1000 : value.target.value > 100 && !config.data.previous.settings.premium.status ? 100 : value.target.value < 10 ? 10 : !value.target.value ? 10 : parseInt(value.target.value) });
                                            }}
                                            additionalProps={{ premium: !config.data.previous.settings.premium.status, inputClass: config.data.previous.settings.premium.status ? "" : "pointer-events-none" }}
                                            utils={utils}
                                            icon={
                                                !config.data.previous.settings.premium.status && (
                                                    <span className="icon-right">
                                                        <div className="icon premium-icon">
                                                            <BiCrown />
                                                        </div>
                                                    </span>
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="background-02 w-full rounded-[8px] py-2 px-4 pb-5">
                                <Translate content="routes.dashboard.leveling.reward_roles" component="h1" className="text title-8" />
                                <Translate content="routes.dashboard.leveling.reward_roles_i" component="h1" className="text subtitle-4" />
                                <div className="flex gap-3 mt-2 flex-wrap items-center">
                                    {Utility.GroupArray(sectionData.data.roleReward, "level").map((rewardGroup, index) => (
                                        <div key={index} className="background-03 rounded-[8px] p-3 flex gap-4 justify-between items-center grow basis-[32.6%] whitespace-nowrap">
                                            <div className="flex gap-4 items-center">
                                                <div>
                                                    <h1 className="text font-2 text-[17px]">
                                                        {utils.translation.words.level} {rewardGroup.level}
                                                    </h1>
                                                    <h1 className="text text-[14px]">{Utility.FormatDuration(Utility.CalculateExpectedTimeToReachXP(Utility.GetServerLevelXPRequirement(rewardGroup.level), 5, 25, sectionData.settings.xpRate), 2)} ~</h1>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    {Utility.TrimArray(rewardGroup.value, 3, null).map((reward: any, index: any) => {
                                                        const role = guild.roles?.find((a: any) => reward.roleId === a.id);
                                                        return (
                                                            <div key={index} className="flex items-center background-05 pl-2 pr-3 py-2 rounded-md gap-2">
                                                                <div className={`w-[12px] h-[12px] rounded-[10px]`} style={{ backgroundColor: !role || role.color === 0 ? "#99AAB5" : `#${role.color.toString(16)?.padStart(6, "0")}` }} />
                                                                <h1 className="text text-[14px] leading-[10px]">{role ? role.name : utils.translation.words.unknown_role}</h1>
                                                            </div>
                                                        );
                                                    })}
                                                    {rewardGroup.value.length > 3 && (
                                                        <div key={index} className="flex items-center background-05 pl-2 pr-3 py-2 rounded-md gap-2">
                                                            <h1 className="text text-[14px] leading-[10px]">+{rewardGroup.value.length - 3}</h1>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="button flex gap-3 justify-end">
                                                <button
                                                    className="hover02 color-blurple"
                                                    onClick={() => {
                                                        getRewardRolePopup[1](true);
                                                        isEditingReward[1](true);
                                                        rewardRoles[1](sectionData.data.roleReward.filter((a: any) => !rewardGroup.value.find((b: any) => a.roleId === b.roleId)));
                                                        rewardRoleData[1]({ level: rewardGroup.value[0]?.level ?? 0, rewards: rewardGroup.value });
                                                    }}>
                                                    <span className="button-icon">
                                                        <BiEdit />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="button w-full mt-1">
                                        <button
                                            className={`hover03 ${sectionData.data.roleReward.length >= 25 && !config.data.previous.settings.premium.status ? "color-gold" : "color-success"} full ${sectionData.data.roleReward.length >= 250 ? "disabled" : ""}`}
                                            onClick={() => {
                                                if (sectionData.data.roleReward.length >= 250) return;
                                                if (!config.data.previous.settings.premium.status && sectionData.data.roleReward.length >= 25) {
                                                    getPremiumPopup[1](true);
                                                    getPremiumPopupElement[1](<Translate content="routes.dashboard.leveling.max_rewards_premium" className="text subtitle-3 center white-lock" />);
                                                    return;
                                                }
                                                getRewardRolePopup[1](true);
                                                isEditingReward[1](false);
                                                rewardRoles[1](sectionData.data.roleReward);
                                                rewardRoleData[1]({ level: 0, rewards: [] });
                                                // sectionData.data.roleReward.push({ level: 0, roleId: "", keep: true });
                                                // config.dispatch({ type: "update", path: "modules.leveling.data.roleReward", data: sectionData.data.roleReward });
                                            }}>
                                            <Translate content="words.add_new" className="button-text both" />
                                            <span className="button-icon">{sectionData.data.roleReward.length >= 25 && !config.data.previous.settings.premium.status ? <BiCrown /> : <BiPlus />}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            {rewardRoleData[0] && (
                <AppComponent.PopUp id="RewardRolePopup" trigger={getRewardRolePopup} closeFull close utils={utils}>
                    <div className="form">
                        <AppComponent.FormInput
                            type="number"
                            label={utils.translation.words.level}
                            htmlFor="level"
                            value={rewardRoleData[0].level}
                            onChange={(value: any) => {
                                const level = value.target.value > sectionData.settings.maxLevel ? sectionData.settings.maxLevel : value.target.value < 0 ? 0 : !value.target.value ? 0 : parseInt(value.target.value);
                                rewardRoleData[1]({ level, rewards: rewardRoleData[0].rewards.map((reward: any) => ({ level, roleId: reward.roleId, keep: reward.keep })) });
                            }}
                            utils={utils}
                        />
                        <h1 className="text text-[14px]">{Utility.FormatDuration(Utility.CalculateExpectedTimeToReachXP(Utility.GetServerLevelXPRequirement(rewardRoleData[0].level), 5, 25, sectionData.settings.xpRate), 2)} ~</h1>
                        <div className="button w-full mt-3">
                            <button
                                className={`hover03 ${rewardRoles[0].concat(rewardRoleData[0].rewards).length >= 25 && !config.data.previous.settings.premium.status ? "color-gold" : "color-success"} full ${rewardRoles[0].concat(rewardRoleData[0].rewards).length >= 250 ? "disabled" : ""}`}
                                onClick={() => {
                                    const rewards = rewardRoles[0].concat(rewardRoleData[0].rewards);
                                    console.log(rewards.length);
                                    if (rewards.length >= 250) return;
                                    if (!config.data.previous.settings.premium.status && rewards.length >= 25) {
                                        getPremiumPopup[1](true);
                                        getPremiumPopupElement[1](<Translate content="routes.dashboard.leveling.max_rewards_premium" className="text subtitle-3 center white-lock" />);
                                        return;
                                    }
                                    rewardRoleData[1]({ level: rewardRoleData[0].level, rewards: rewardRoleData[0].rewards.concat({ level: rewardRoleData[0].level, roleId: "", keep: false }) });
                                }}>
                                <Translate content="words.add" className="button-text both" />
                                <span className="button-icon">{rewardRoles[0].concat(rewardRoleData[0].rewards).length >= 25 && !config.data.previous.settings.premium.status ? <BiCrown /> : <BiPlus />}</span>
                            </button>
                        </div>
                        <div className="grid gap-2 mt-3">
                            {rewardRoleData[0].rewards.map((reward: any, index: any) => (
                                <div key={index} className="form-inputs background-01 p-2 rounded-md">
                                    <div className="flex gap-2 items-end">
                                        <AppComponent.FormInput
                                            className="w-full"
                                            type="select"
                                            placeholder={utils.translation.words.select_role}
                                            value={Utility.DiscordRolesToDropdownOptions(Utility.GuildDataFilterIgnore([reward.roleId], guild.roles, true))}
                                            options={Utility.DiscordRolesToDropdownOptions(
                                                extra.roleOptions(
                                                    Utility.RoleOptionsFilter(
                                                        ["NO_EVERYONE", "NO_BOTS", "NO_ADMIN", "NO_MANAGER", "NO_KICK_BAN"],
                                                        Utility.RemoveDuplicates(rewardRoles[0].concat(rewardRoleData[0].rewards), "roleId").map((a: any) => ({ id: a.roleId }))
                                                    )
                                                )
                                            )}
                                            onChange={(value: any) => {
                                                let data = JSON.parse(JSON.stringify(rewardRoleData[0]));
                                                data.rewards[index].roleId = value.value;
                                                rewardRoleData[1](data);
                                            }}
                                            utils={utils}
                                        />
                                        <div className="button flex gap-3">
                                            <button
                                                className="hover02 color-danger h-[48px]"
                                                onClick={() => {
                                                    let data = JSON.parse(JSON.stringify(rewardRoleData[0]));
                                                    data.rewards = data.rewards.filter((a: any, indexA: any) => indexA !== index);
                                                    rewardRoleData[1](data);
                                                }}>
                                                <span className="button-icon">
                                                    <BiTrash />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <AppComponent.FormInput
                                        type="toggle-checkbox"
                                        label={utils.translation.words.keep}
                                        tooltip={utils.translation.routes.dashboard.leveling.reward_role_keep_i}
                                        value={reward.keep}
                                        onChange={(value: any) => {
                                            let data = JSON.parse(JSON.stringify(rewardRoleData[0]));
                                            data.rewards[index].keep = value.target.checked;
                                            rewardRoleData[1](data);
                                        }}
                                        additionalProps={{ togglePlacement: "left" }}
                                        utils={utils}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="button flex justify-between mt-6">
                        <button className="hover02 color-secondary" onClick={() => getRewardRolePopup[1](false)}>
                            <Translate content="words.cancel" className="button-text" />
                        </button>
                        <button
                            className="hover02 color-success"
                            onClick={() => {
                                const rewardsToAdd = rewardRoleData[0].rewards.filter((a: any) => a.roleId);
                                const rewards = rewardRoles[0].concat(rewardsToAdd);
                                config.dispatch({ type: "update", path: "modules.leveling.data.roleReward", data: rewards });
                                getRewardRolePopup[1](false);
                            }}>
                            {isEditingReward[0] ? (
                                <>
                                    <Translate content="words.edit" className="button-text both" />
                                    <span className="button-icon">
                                        <BiEdit />
                                    </span>
                                </>
                            ) : (
                                <>
                                    <Translate content="words.add" className="button-text both" />
                                    <span className="button-icon">
                                        <BiPlus />
                                    </span>
                                </>
                            )}
                        </button>
                    </div>
                </AppComponent.PopUp>
            )}
            <AppComponent.PopUp background="premium-bg" id="MaxLevelPremiumPopup" trigger={getPremiumPopup} closeFull close utils={utils}>
                <span className="noselect w-full">
                    <Translate content="words.server_premium_feature" component="h1" className="text title-6 text-center white-lock" />
                    {getPremiumPopupElement[0]}
                    <div className="button flex justify-center mt-6">
                        <button className="hover02 color-secondary shadow bg-gradient-to-r from-[#d397fa] to-[#8364e8] disabled" onClick={() => {}}>
                            <Translate content="routes.profile.subscription.get_server_premium" className="button-text" />
                        </button>
                    </div>
                </span>
            </AppComponent.PopUp>
        </>
    );
};

export default Section;
